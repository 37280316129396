import { makeObservable, computed } from 'mobx';

import ValueStore from './ValueStore';
import CheckboxListStore from '../mapped/CheckboxListStore';
import PaginationStore from './PaginationStore';

// Types
interface ITransferStore {
  [x: string]: { [x: string]: any } | any;
}

class TransferListStore implements ITransferStore {
  leftList: ValueStore<Array<any>>;
  rightList: ValueStore<Array<any>>;
  leftPagination: PaginationStore;
  rightPagination: PaginationStore;

  leftCheckboxList: CheckboxListStore;
  rightCheckboxList: CheckboxListStore;
  constructor({ leftList = [], rightList = [], leftPaginDef = {}, rightPaginDef = {}, perPage = 50 } = {}) {
    this.leftList = new ValueStore<Array<any>>(leftList);
    this.rightList = this.rightListProxy(new ValueStore<Array<any>>(rightList));

    this.leftPagination = new PaginationStore({ ...leftPaginDef, perPage });
    this.rightPagination = new PaginationStore({ ...rightPaginDef, perPage });
    this.leftCheckboxList = new CheckboxListStore();
    this.rightCheckboxList = new CheckboxListStore();

    makeObservable(this, {
      value: computed,
    });
  }

  // Для пересчета и записи количества строк в списке в другой стор "rightPagination".
  rightListProxy = (target: ValueStore<Array<any>>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply: (target, thisArg, args) => {
        target.apply(thisArg, args);

        const [value] = args;

        context.rightPagination.rowsCount.updateValue(value.length);
      },
    });

    return target;
  };

  sortElements = (elements: any) =>
    elements.sort((a: any, b: any) => {
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      return 0;
    });

  getItems = (ignoreList: Array<string | number>, list: Array<any>) =>
    list.reduce(
      (result, item) => {
        const [checked, unchecked] = result;

        if (ignoreList.includes(item.id)) checked.push(item);
        else unchecked.push(item);

        return result;
      },
      [[], []]
    );

  moveToRigth = () => {
    const [checked] = this.getItems(this.leftCheckboxList.checked, this.leftList.value);
    const [, notInRight] = this.getItems(this.rightCheckboxList.allList, checked);

    this.rightList.updateValue(this.sortElements(this.rightList.value.concat(notInRight)));
  };

  delFromRight = () => {
    const [, uncheked] = this.getItems(this.rightCheckboxList.checked, this.rightList.value);
    this.rightList.updateValue(this.sortElements(uncheked));
  };

  get value() {
    return this.rightList.value.map((item: any) => item.id);
  }

  updateValue = (value: any[]) => this.rightList.updateValue(value);

  // Пока существует для унификации элементов формы
  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default TransferListStore;
