import { makeObservable } from 'mobx';

import ValueStore from '../components/ValueStore';
import MapStore from './MapStore';
import { FieldStore } from '../triggers';

// Types
import { ReactText } from 'react';
import { TFormValues, TFormElementValue } from '../../types';

class CheckboxListStore extends MapStore<FieldStore<boolean>> {
  constructor() {
    super();
    makeObservable(this, {});
  }

  get formValues(): TFormValues {
    const values: TFormValues = {};
    const checked: Array<ReactText> = [];
    for (const key in Object.fromEntries(this.value)) {
      const { value } = this.get(key) || {};
      if (value !== undefined) values[key] = value as TFormElementValue;
      if (value) checked.push(key);
    }

    return { ...values, checked };
  }

  set = (name: ReactText, preset?: any) =>
    super.set(name, 'FieldStore', {
      ...preset,
      type: 'checked',
    });

  private setCheckAllState = (value: boolean) => {
    this.value.forEach((checkbox: FieldStore<boolean>) => {
      if (!checkbox.isDisabled.value) checkbox.updateValue(value);
    });
  };

  toggleCheckAllState = () => {
    if (this.isCheckedAll) this.setCheckAllState(false);
    else this.setCheckAllState(true);
  };

  removeCheck = () => {
    this.checked.forEach((id: ReactText) => this.get(id)?.updateValue(false));
  };

  get isCheckedAll() {
    // Переменная all нужна на случай если checkboxList окажется объектом без ключей,
    // тогда геттер вернет false.
    let all = false;
    // @ts-ignore
    for (const { value, isDisabled } of this.value.values()) {
      all = true;
      if (!value && !isDisabled.value) return false;
    }

    return all;
  }

  get isIndeterminate() {
    let hasChecked = false;
    let hasUnchecked = false;
    // @ts-ignore
    for (const { value, isDisabled } of this.value.values()) {
      if (!hasChecked && !isDisabled.value) hasChecked = value;
      if (!hasUnchecked && !isDisabled.value) hasUnchecked = !value;
      if (hasChecked && hasUnchecked) return true;
    }
    return false;
  }

  get checked() {
    const checked: Array<ReactText> = [];
    // @ts-ignore
    for (const [key, value] of this.value.entries()) {
      if (value) checked.push(key);
    }
    return checked;
  }

  get allList() {
    const all: Array<ReactText> = [];
    // @ts-ignore
    for (const key of this.value.keys()) {
      all.push(key);
    }
    return all;
  }

  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default CheckboxListStore;
