import React from 'react';

// Components
import { Typography, Grid, Button, Container, Paper, Divider, IconButton, TextField } from '@mui/material';
import DrawedContent from './DrawedContent';
import ExportCSV from './ExportCSV';
import Legend from './Legend';
import AlertPanel from './AlertPanel';
import Pagination from '../Pagination/Pagination';
import PaginationRowsLabel from '../Pagination/PaginationRowsLabel';

// Icons & Images
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

// Hooks
import { useTheme } from '@mui/material/styles';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ITableUIProps } from './types';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    headTop: {
      padding: theme.spacing(2, 2, 1),
    },
    widthTr: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    settingsHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      height: 112,
      justifyContent: 'flex-start',
    },
    settingsPanel: {
      overflow: 'hidden',
    },
    settingsContent: {
      boxSizing: 'border-box',
      borderLeft: '1px solid',
      borderColor: theme.palette.grey[300],
      height: '100%',
    },
    settingsValues: {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 5,
        height: 0,
      },
      '&::-webkit-scrollbar-thumb': {
        height: '10px',
        background: theme.palette.primary.light,
        outline: '1px solid slategrey',
        borderRadius: '2px',
      },
    },
    settingsBlock: {
      padding: theme.spacing(2),
      textAlign: 'left',
    },
    settingsElem: {
      marginTop: theme.spacing(1),
    },
  })
);

const TableUI: FC<ITableUIProps> = ({
  tableStore,
  title,
  rows,
  head,
  filters,
  sort,
  // Тотал далее должен считаться на сервере в зависимости от того, в настройках таблицы стоит
  // считать на каждой странице, или на последней, сейчас считается в родителе для каждой страницы
  total,
  totalRowsCount,
  saveCsvDataHandler,
  options: {
    totalCountColumns,
    columnsOrder = Object.keys(head).sort((a, b) => {
      if (a === 'main') return -1;
      if (b === 'main') return 1;
      return 0;
    }),
    legendElements,
    isBeSaved = true,
    actionPanel,
    headActionPanel,
    ...options
  } = {},
  maxWidth,
}) => {
  const { root, headTop, widthTr, settingsHeader, settingsPanel, settingsContent, settingsValues, settingsBlock, settingsElem } =
    useStyles();

  const { settingsIsOpen, fileName, totalOnEachPage, legendList, pagination } = tableStore;

  const { page, countPages, rowsCount, rowStart, rowEnd } = pagination;

  // Панель настроек
  const theme = useTheme();
  const width = 300;
  const widthPanel = settingsIsOpen.value ? width : 0;

  const isBeCustomized = isBeSaved || totalCountColumns;

  const ActionPanel = actionPanel || null;
  const HeadActionPanel = headActionPanel || null;

  return (
    <Container maxWidth={maxWidth || false} className={root}>
      <Paper>
        <Grid container style={{ borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}>
          <main style={{ width: `calc(100% - ${widthPanel}px)` }} className={widthTr}>
            <Grid container className={headTop}>
              {/* <Grid
                container
                style={{ paddingTop: theme.spacing(1), flexGrow: 1, width: '100%', height: 34 }}
                justify="space-between"
                alignItems="center"
              >
                {isBeSaved ? <Typography variant="caption">Сохранить</Typography> : <div />}
                {isBeCustomized && !settingsIsOpen.value && (
                  <div>
                    <Typography variant="caption">Настройки</Typography>
                    <IconButton
                      size="small"
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={settingsIsOpen.onOpen}
                    >
                      <TuneIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </Grid> */}
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', width: '100%' }}>
                <div></div>
                {title ? (
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {title}
                  </Typography>
                ) : (
                  <div />
                )}
                <div style={{ textAlign: 'right' }}>
                  {isBeSaved && (
                    <ExportCSV fileName={title || fileName.value} onClick={saveCsvDataHandler}>
                      {({ onClick }) => (
                        <Button
                          variant="outlined"
                          aria-label="open drawer"
                          size="small"
                          color="primary"
                          style={{
                            ...(headActionPanel ? { borderRadius: '4px 0 0 4px' } : {}),
                          }}
                          onClick={onClick}
                        >
                          Сохранить отчет
                        </Button>
                      )}
                    </ExportCSV>
                  )}
                  {HeadActionPanel && <HeadActionPanel />}
                </div>
              </div>
            </Grid>

            <PaginationRowsLabel {...{ rowStart, rowEnd, rowsCount: rowsCount.value }} />
            <DrawedContent
              tableStore={tableStore}
              head={head}
              filters={filters}
              sort={sort}
              rows={rows}
              isLastPage={page.value === countPages}
              totalOnEachPage={totalOnEachPage.value}
              columnsOrder={columnsOrder}
              total={total}
              {...options}
            />
          </main>
          <div style={{ width: widthPanel }} className={`${widthTr} ${settingsPanel}`}>
            <div style={{ width }} className={settingsContent}>
              <div className={settingsHeader}>
                <IconButton onClick={settingsIsOpen.onClose}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <Divider />
              <div className={settingsValues}>
                {/* ВРЕМЕННО ЗАКОМЕНЧЕНО */}
                {/* {totalCountColumns && (
                  <>
                    <div className={settingsBlock}>
                      <Typography>Итог для стр.:</Typography>
                      <Typography component="div" className={settingsElem}>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          justify="flex-end"
                          spacing={1}
                        >
                          <Typography variant="body2">Каждой</Typography>
                          <Grid item>
                            <Switch
                              size="small"
                              color="primary"
                              checked={totalOnEachPage.value}
                              onChange={totalOnEachPage.onChangeHandler}
                              name="checked"
                            />
                          </Grid>
                        </Grid>
                        <Typography variant="caption">
                          По умолчанию итог считается для всех страниц и отображается на последней
                          странице.
                        </Typography>
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )} */}
                {isBeSaved && (
                  <>
                    <div className={settingsBlock}>
                      <Typography>Сохранять в Excel:</Typography>
                      {/* ВРЕМЕННО ЗАКОМЕНЧЕНО */}
                      {/* <Typography component="div" className={settingsElem}>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          justify="flex-end"
                          spacing={1}
                        >
                          <Typography variant="body2">Текущую стр.</Typography>
                          <Grid item>
                            <Switch
                              size="small"
                              color="primary"
                              checked={saveCurrentPage.value}
                              onChange={saveCurrentPage.onChangeHandler}
                              name="checked"
                            />
                          </Grid>
                        </Grid>
                        <Typography variant="caption">
                          По умолчанию сохраняются все страницы.
                        </Typography>
                      </Typography> */}
                      <Typography component="div" variant="body2" className={settingsElem}>
                        Имя файла:
                        <Grid component="label" container alignItems="center" spacing={1} style={{ margin: theme.spacing(1, 0) }}>
                          <TextField size="small" variant="outlined" value={fileName.value} onChange={fileName.onChangeHandler} />
                          <Typography variant="body1">.xlsx</Typography>
                        </Grid>
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <AlertPanel tableStore={tableStore} />
        {legendElements && !!legendList.length && <Legend tableStore={tableStore} legendElements={legendElements} />}
        {actionPanel}
      </Paper>
      <Pagination
        paginationStore={pagination}
        totalRowsCount={totalRowsCount}
        perPegeSelectorVariants={[5, 10, 25, 50, 100]}
        rowsLabel={false}
      />
    </Container>
  );
};

export default observer(TableUI);
