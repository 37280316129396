import React from 'react';

// Components
import { Tooltip } from '@mui/material';

// Icons & Images
import { HelpOutline } from '@mui/icons-material';

// Utils
import { makeStyles, withStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { THelpIconProps } from './types';

// Styles
const TooltipBigFSize = withStyles({
  tooltip: {
    fontSize: 14,
  },
})(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.grey[400],
    fontSize: 17,
  },
}));

const HelpIcon: FC<THelpIconProps> = ({ title }) => {
  const { icon } = useStyles();
  return (
    <TooltipBigFSize title={title}>
      <HelpOutline className={icon} fontSize="small" />
    </TooltipBigFSize>
  );
};

export default HelpIcon;
