import React from 'react';

// Components
import Query from '../../../client/Query';
import MenuBar from './MenuBar';

// Types
import { FC } from 'react';

// Queries
import { loader } from 'graphql.macro';
const getUser = loader('./gqlQueries/getUserRole.gql');

const MenuBarQuery: FC = () => {
  return <Query query={getUser} children={MenuBar} />;
};

export default MenuBarQuery;
