import React from 'react';

// Components
import { Checkbox } from '@mui/material';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { TCheckboxToggleProps } from './types';
import FieldStore from '../../../stores/classes/triggers/FieldStore';

const CheckboxToggle: FC<TCheckboxToggleProps> = ({ formStore, name, preset, selfRemoved = false, disabled, ...props }) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const { value, onChangeHandler, isDisabled } = formStore.set(name, 'FieldStore', {
    def: false,
    ...preset,
    type: 'checked',
  }) as FieldStore<boolean>;

  if (disabled) isDisabled.updateValue(disabled);

  return <Checkbox checked={value} onChange={onChangeHandler} disabled={isDisabled.value} {...props} />;
};

export default observer(CheckboxToggle);
