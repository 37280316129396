import { makeObservable } from 'mobx';

import FieldStore from './FieldStore';

// Types
import { ReactText } from 'react';
import { TFieldStorePreset } from '../../types';

class SelectStore extends FieldStore<ReactText | ReactText[]> {
  constructor(preset: TFieldStorePreset) {
    super(preset);
    makeObservable(this, {});
  }
}

export default SelectStore;
