import React from 'react';

// Components
import Field from '../../FormElements/Field';
import SelectField from '../../FormElements/SelectField';

// Utils
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IContentProps } from '../types';

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  field: { margin: theme.spacing(1, 0) },
}));

const AddFeedbackDialog: FC<IContentProps> = ({ modalStore, values }) => {
  const { field } = useStyles();

  return (
    <>
      <SelectField
        formStore={modalStore.formElementsList}
        name="type_id"
        values={values}
        selfRemoved={true}
        label="Выберите причину обращения"
        variant="outlined"
        className={field}
      />
      <Field
        formStore={modalStore.formElementsList}
        name="subject"
        selfRemoved={true}
        label="Укажите тему обращения"
        variant="outlined"
        className={field}
      />
      <Field
        formStore={modalStore.formElementsList}
        name="text"
        selfRemoved={true}
        label="Заполните текст обращения"
        variant="outlined"
        rows={5}
        className={field}
      />
    </>
  );
};

export default AddFeedbackDialog;
