import AutocompleteFilter from '../../FormElements/AutocompleteField';
import DateFilter from '../../FormElements/DateField';
import DatePeriodPickerField from '../../FormElements/DatePeriodPickerField';
import SelectFilter from '../../FormElements/SelectField';
import SelectMultipleField from '../../FormElements/SelectMultipleField';
import PeriodDateFilter from '../../FormElements/DatePeriodField';

const Filters: { [x: string]: any } = {
  AutocompleteFilter,
  DateFilter,
  SelectFilter,
  SelectMultipleField,
  PeriodDateFilter,
  DatePeriodPickerField,
};

export default Filters;
