import React from 'react';

// Components
import AutocompleteField from '../../FormElements/AutocompleteField';
import RadioSelect from '../../FormElements/RadioSelect';

// Hooks
import { useRef } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const IssueAnOrderDialog: FC<IContentProps> = ({ modalStore, values }) => {
  const inputEl = useRef<any>(null);
  const { formElementsList } = modalStore;

  return (
    <>
      <RadioSelect
        formStore={formElementsList}
        name="searchType"
        values={[
          {
            value: 'id',
            label: 'Номер заказа',
          },
          {
            value: 'phone',
            label: 'Номер телефона',
          },
        ]}
        preset={{ def: 'id' }}
        onClick={() => inputEl.current.focus()}
      />
      <AutocompleteField
        formStore={formElementsList}
        name="searchValue"
        values={values[formElementsList.formValues.searchType as string]}
        selfRemoved={true}
        variant="outlined"
        size="small"
        autoFocus={true}
        inputRef={inputEl}
      />
    </>
  );
};

export default observer(IssueAnOrderDialog);
