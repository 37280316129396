import React from 'react';

// Components
import { Box, CircularProgress } from '@mui/material';

// Utils
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const LoadingBlock: FC = () => {
  const { root } = useStyles();

  return (
    <Box className={root}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingBlock;
