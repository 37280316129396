import { ChangeEvent } from 'react';
import { makeObservable } from 'mobx';

import FieldStore from './FieldStore';

// Types
import { IRadioSelectStorePreset } from '../../types';

class RadioSelectStore extends FieldStore<string | number | boolean> {
  private isNum: boolean;
  private isBool: boolean;
  constructor({ isNum = false, isBool = false, ...preset }: IRadioSelectStorePreset = {}) {
    super(preset);
    this.isNum = isNum;
    this.isBool = isBool;

    makeObservable(this, {});
  }

  onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let value: string | number | boolean = e.target.value;
    if (this.isNum) value = Number(value);
    else if (this.isBool) value = value === 'true';
    this.updateValue(value);
  };
}

export default RadioSelectStore;
