import React from 'react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const AddPharmacistAuthDataDialog: FC<IContentProps> = () => {
  return (
    <>
      Для активации аптек(и) в Программе лояльности необходимо заполнить обязательные поля (авторизационные данные, название аптеки) в
      карточке аптеки.
    </>
  );
};

export default AddPharmacistAuthDataDialog;
