import React from 'react';

// Components
import { List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Divider } from '@mui/material';
import ToggleCheckbox from '../ToggleCheckbox';
import Pagination from '../../Pagination/PaginationActions';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ICustomListProps } from '../types';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    wing: {
      width: 'calc((100% - 96px)/2)',
    },
    list: {
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 10,
        height: 0,
      },
      '&::-webkit-scrollbar-thumb': {
        width: '10px',
        background: theme.palette.secondary.main,
        outline: '1px solid slategrey',
        borderRadius: '2px',
      },
    },
    button: {
      margin: theme.spacing(2),
    },
  })
);

const CustomList: FC<ICustomListProps> = ({ title, items, checkboxListStore, paginationStore }) => {
  const { cardHeader, list } = useStyles();

  const { isCheckedAll, isIndeterminate, toggleCheckAllState, checked } = checkboxListStore;

  const { page, countPages, firstButtonClickHandler, backButtonClickHandler, nextButtonClickHandler, lastButtonClickHandler } =
    paginationStore;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          className={cardHeader}
          avatar={
            <Checkbox
              onClick={toggleCheckAllState}
              checked={isCheckedAll}
              indeterminate={isIndeterminate}
              disabled={items?.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${checked?.length}/${items?.length} отмечено`}
        />
        <Divider />
        <List className={list} dense component="div" role="list">
          {items?.length
            ? items.map((item) => {
                const { id, name, address } = item;
                const labelId = `transfer-list-all-item-${id}-label`;

                return (
                  <ListItem key={id} role="listitem" button>
                    <ListItemIcon>
                      <ToggleCheckbox formStore={checkboxListStore} name={id} selfRemoved={true} />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${name}, ${address}`} />
                  </ListItem>
                );
              })
            : null}
          <ListItem />
        </List>
      </Card>
      <Pagination
        page={page.value}
        {...{
          countPages,
          firstButtonClickHandler,
          backButtonClickHandler,
          nextButtonClickHandler,
          lastButtonClickHandler,
        }}
      />
    </>
  );
};

export default observer(CustomList);
