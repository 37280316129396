import { makeObservable } from 'mobx';
import ValueStore from '../components/ValueStore';

class TriggerStore<T> extends ValueStore<T> {
  constructor(def: T) {
    super(def);

    makeObservable(this, {});
  }

  isDisabled: ValueStore<boolean> = new ValueStore<boolean>(false);

  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default TriggerStore;
