import React from 'react';

// Components
import { Container, Card, CardActionArea, CardContent, Slider, Typography, IconButton } from '@mui/material';
import ReactPlayer from 'react-player';

// Icons & Images
import {
  PlayArrow as PlayArrowIcon,
  PauseOutlined as PauseOutlinedIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
} from '@mui/icons-material';

// Hooks
import { useState, useEffect } from 'react';

// Utils
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import screenfull from 'screenfull';
import { getTime } from '../../../utils/dateTimeUtils';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IVideoPlayerCardProps } from './types';

const useStyle = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: 0,
      width: 'auto',
    },
    card: {
      display: 'grid',
      position: 'relative',
    },
    playerWrapper: {
      position: 'relative',
      paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    },
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    panelWrapper: {
      display: 'flex',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
    },
    timeSliderWrapper: {
      display: 'grid',
    },
    buttonsWrapper: {
      display: 'grid',
    },
    volumeSliderWrapper: {
      display: 'grid',
    },
    rightBottom: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  };
});

const VideoPlayerCard: FC<IVideoPlayerCardProps> = ({ playerStore, url, cover = false }) => {
  const [isRightPanel, setIsRightPanel] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (screenfull.isEnabled) setIsFullScreen(screenfull.isFullscreen);
      });
    }
  }, [screenfull]);

  const [showPanel, setShowPanel] = useState(false);

  const { root, card, playerWrapper, player, panelWrapper, timeSliderWrapper, buttonsWrapper, volumeSliderWrapper, rightBottom } =
    useStyle();

  const {
    volume,
    volumeMuteHandler,
    playing,
    played,
    duration,
    playedSeconds,
    setPlayerRef,
    setReactPlayerRef,
    onEnded,
    onDuration,
    onProgress,
    playPauseHandler,
    fullscreenHandler,
  } = playerStore;

  return (
    <Container className={root} ref={setPlayerRef}>
      <Card
        className={card}
        style={{
          [`gridTemplate${isRightPanel && !isFullScreen ? 'Columns' : 'Rows'}`]: '1fr auto',
        }}
      >
        <CardActionArea className={playerWrapper} onClick={playPauseHandler}>
          <ReactPlayer
            ref={setReactPlayerRef}
            className={player}
            volume={volume.value as number}
            playing={playing.value}
            {...{ url, onEnded, onDuration, onProgress }}
            light={cover}
            playIcon={<></>}
            width="100%"
            height="100%"
          />
        </CardActionArea>
        <CardContent
          className={panelWrapper}
          {...(isFullScreen && {
            onMouseEnter: () => setShowPanel(true),
            onMouseLeave: () => setShowPanel(false),
          })}
          style={{
            [`max${isRightPanel && !isFullScreen ? 'Width' : 'Height'}`]: 136,
            [isRightPanel && !isFullScreen ? 'height' : 'width']: '100%',
            flexDirection: isRightPanel && !isFullScreen ? 'row' : 'column',
            ...(isFullScreen && { position: 'absolute', bottom: 0, opacity: showPanel ? 1 : 0 }),
          }}
        >
          <div
            className={timeSliderWrapper}
            style={{
              [`gridTemplate${isRightPanel && !isFullScreen ? 'Rows' : 'Columns'}`]: 'auto 1fr auto',
              [`${isRightPanel && !isFullScreen ? 'row' : 'column'}Gap`]: '24px',
              [`${isRightPanel && !isFullScreen ? 'justify' : 'align'}Items`]: 'center',
            }}
          >
            <Typography variant="subtitle2">{getTime(duration.value * 1000)}</Typography>
            <Slider
              min={0}
              max={1}
              step={0.01}
              orientation={isRightPanel && !isFullScreen ? 'vertical' : 'horizontal'}
              value={played.value}
              onChange={played.onChangeHandler}
              valueLabelDisplay="auto"
              valueLabelFormat={(value: number) => getTime(duration.value * 1000 * value)}
              color="secondary"
              aria-labelledby="played-progress"
            />
            <Typography variant="subtitle2">{getTime(playedSeconds.value * 1000)}</Typography>
          </div>
          <div
            className={buttonsWrapper}
            style={{
              [`gridTemplate${isRightPanel && !isFullScreen ? 'Rows' : 'Columns'}`]: '1fr auto 1fr',
              [`${isRightPanel && !isFullScreen ? 'row' : 'column'}Gap`]: '24px',
              [`${isRightPanel && !isFullScreen ? 'justify' : 'align'}Items`]: 'center',
            }}
          >
            <div className={rightBottom}>
              <IconButton size="small" onClick={fullscreenHandler}>
                {isFullScreen ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
              </IconButton>
            </div>
            <IconButton onClick={playPauseHandler}>{playing.value ? <PauseOutlinedIcon /> : <PlayArrowIcon />}</IconButton>
            <div
              className={volumeSliderWrapper}
              style={{
                [`gridTemplate${isRightPanel && !isFullScreen ? 'Rows' : 'Columns'}`]: '1fr auto',
                [`${isRightPanel && !isFullScreen ? 'row' : 'column'}Gap`]: '24px',
              }}
            >
              <Slider
                min={0}
                max={1}
                step={0.01}
                orientation={isRightPanel && !isFullScreen ? 'vertical' : 'horizontal'}
                value={volume.value}
                onChange={volume.onChangeHandler}
                valueLabelDisplay="auto"
                valueLabelFormat={(value: number) => `${value * 100}%`}
                color="secondary"
              />
              <IconButton size="small" onClick={volumeMuteHandler}>
                {volume.value ? <VolumeUpIcon fontSize="small" /> : <VolumeOffIcon fontSize="small" />}
              </IconButton>
            </div>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default observer(VideoPlayerCard);
