import { makeObservable } from 'mobx';
import TableStore from './components/TableStore/TableStore';
import ToggleStore from './components/ToggleStore';

class OrderListTableStore extends TableStore {
  unpackOrder: ToggleStore = new ToggleStore();

  constructor() {
    super();
    makeObservable(this, {});
  }
}

export default OrderListTableStore;
