import React from 'react';

// Components
import RadioSelect from '../../FormElements/RadioSelect';
import Field from '../../FormElements/Field';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const OrderRefuseDialog: FC<IContentProps> = ({ modalStore, values }) => {
  const reason_id = modalStore.formElementsList.get('reason_id')?.value;

  return (
    <>
      <RadioSelect formStore={modalStore.formElementsList} name="reason_id" title="Выберите причину" values={values} selfRemoved={true} />
      {reason_id === 4 && (
        <Field formStore={modalStore.formElementsList} name="comment" placeholder="Укажите причину" rows={2} variant="outlined" />
      )}
    </>
  );
};

export default observer(OrderRefuseDialog);
