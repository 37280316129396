import React from 'react';

// Components
import { IconButton, Typography } from '@mui/material';

// Icons & Images
import { FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon } from '@mui/icons-material';

// Utils
import { makeStyles, createStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IPaginationActionsProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
    },
  })
);

const PaginationActions: FC<IPaginationActionsProps> = ({
  page,
  countPages,
  firstButtonClickHandler,
  backButtonClickHandler,
  nextButtonClickHandler,
  lastButtonClickHandler,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton onClick={firstButtonClickHandler} disabled={page <= 1} aria-label="Первая страница">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={backButtonClickHandler} disabled={page <= 1} aria-label="Предыдущая страница">
        <KeyboardArrowLeft />
      </IconButton>

      <Typography variant="body2" style={{ display: 'inline-block' }}>{`${page} / ${countPages}`}</Typography>

      <IconButton onClick={nextButtonClickHandler} disabled={page >= countPages} aria-label="Следующая страница">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={lastButtonClickHandler} disabled={page >= countPages} aria-label="Последняя страница">
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default PaginationActions;
