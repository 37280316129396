import { ReactText } from 'react';
import { makeObservable } from 'mobx';

import MapStore from './MapStore';
import ValueStore from '../components/ValueStore';
import {
  FieldStore,
  AutocompleteFieldStore,
  RadioSelectStore,
  DateFieldStore,
  DatePeriodFieldStore,
  DatePeriodPickerFieldStore,
} from '../triggers';
import { CheckboxListStore, SortStoreList } from '.';
import TransferListStore from '../components/TransferListStore';

import { TFormValues, TFormElementValue } from '../../types';

type M =
  | FieldStore<ReactText | ReactText[]>
  | FieldStore<boolean>
  | RadioSelectStore
  | DateFieldStore
  | DatePeriodFieldStore
  | DatePeriodPickerFieldStore
  | TransferListStore
  | AutocompleteFieldStore
  | FormElementsListStore
  | CheckboxListStore
  | SortStoreList;

class FormElementsListStore extends MapStore<M> {
  constructor() {
    super();
    makeObservable(this, {});
  }

  get formValues(): TFormValues {
    const values: TFormValues = {};
    for (const key in Object.fromEntries(this.value)) {
      const formElem = this.get(key);
      console.log(key, formElem?.value);
      if (formElem instanceof FormElementsListStore || formElem instanceof CheckboxListStore) values[key] = formElem?.formValues;
      else values[key] = formElem?.value as TFormElementValue;
    }

    return values;
  }

  get withErrors() {
    for (const key in Object.fromEntries(this.value)) {
      const formElem = this.get(key);
      let value: boolean | string | undefined;

      if (formElem instanceof FormElementsListStore) value = formElem.withErrors;
      else value = formElem?.isError.value;

      if (value) {
        return true;
      }
    }

    return false;
  }

  get withAllErrors() {
    for (const key in Object.fromEntries(this.value)) {
      const formElem = this.get(key);
      let value: boolean | string | undefined;

      if (formElem instanceof FormElementsListStore) value = formElem.withErrors;
      else value = formElem?.isError.value;

      if (!value) {
        return false;
      }
    }

    return true;
  }
}

export default FormElementsListStore;
