import React from 'react';

// Components
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import ToggleCheckbox from '../../../FormElements/ToggleCheckbox';
import LoadingBlock from '../../../LoadingBlock';

// Hooks
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

// Types
import { FC } from 'react';
import { IContentProps } from '../../types';
import CheckboxListStore from '../../../../../stores/classes/mapped/CheckboxListStore';

const AddAptekaSelect: FC<IContentProps> = ({ modalStore, values, yarnValues: variables }) => {
  const { findListOfSyncedNewPharmacies } = values;
  const { data: { findListOfSyncedNewPharmacies: rows = [] } = {}, loading } = useQuery(findListOfSyncedNewPharmacies, {
    variables,
    fetchPolicy: 'network-only',
  });

  const { formElementsList, setModalParam } = modalStore;

  useEffect(() => {
    if (loading) {
      setModalParam('variant', 'info');
      setModalParam('title', 'Получение списка');
    } else if (rows?.length) {
      setModalParam('variant', 'info');
      setModalParam('title', 'Добавить аптеку');
    } else {
      setModalParam('variant', 'error');
      setModalParam('title', 'Ошибка');
    }
  }, [rows]);

  if (loading) return <LoadingBlock />;

  const add_pharmacy_list = formElementsList.set('add_pharmacy_list', CheckboxListStore) as CheckboxListStore;

  return (
    <>
      {rows.length ? (
        <Table>
          <TableBody>
            {rows.map((item: any) => {
              const { id, location, inn } = item;
              return (
                <TableRow key={id}>
                  <TableCell>
                    <ToggleCheckbox formStore={add_pharmacy_list} name={String(id)} selfRemoved />
                  </TableCell>
                  <TableCell>г. {location.city.name}</TableCell>
                  <TableCell>{location.address}</TableCell>
                  <TableCell>ИНН {inn}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        'Данная аптека не найдена, просьба повторно ввести данные или обратиться к сотруднику Renewal'
      )}
    </>
  );
};

export default AddAptekaSelect;
