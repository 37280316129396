import React from 'react';

// Components
import { Card, CardActions, CardContent, Paper, Typography, Icon } from '@mui/material';
import * as ContentVariant from './content';

// Hooks
import { useTheme } from '@mui/material/styles';

// Types
import { FC } from 'react';
import { TCustomSnackbarLayoutProps } from '../../types';

const AlertCard: FC<TCustomSnackbarLayoutProps> = ({
  id,
  title,
  action,
  type = 'info',
  iconVariant,
  hideIconVariant,
  message,
  layoutContent,
  ...other
}) => {
  const theme = useTheme();

  const contrastColor = theme.palette.getContrastText(theme.palette[type].main);

  const { Component, additional }: any = layoutContent?.contentComponent
    ? {
        Component: ContentVariant[layoutContent.contentComponent],
        additional: layoutContent.additionalContentProps,
      }
    : {};

  return (
    <Card
      style={{
        backgroundColor: theme.palette[type].main,
        minWidth: '100%',
        textAlign: 'left',
      }}
    >
      <CardActions style={{ display: 'flex' }}>
        {!hideIconVariant && <Icon style={{ color: contrastColor }}>{iconVariant[type]}</Icon>}
        <Typography
          variant="subtitle2"
          style={{
            flexGrow: 1,
            color: contrastColor,
          }}
        >
          {title}
        </Typography>
        <div>{typeof action === 'function' ? action(id) : action}</div>
      </CardActions>
      <CardContent component={Paper} style={{ margin: '1px' }}>
        {Component && additional ? <Component {...{ message, additional }} /> : message}
      </CardContent>
    </Card>
  );
};

export default AlertCard;
