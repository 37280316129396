import React from 'react';

// Components
import TableController from '../../common/components/tables/TableController';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';
import { sortBy } from '../../utils/other';

// Types
import { FC } from 'react';
import { IFeedbackListDataFiltersProps } from './types';

const FeedbackListDataFilters: FC<IFeedbackListDataFiltersProps> = ({ tableStore, rows, tablePreset }) => {
  const { sortList, useTableAlert, clearAlerts } = tableStore;

  let resultArray: any[] = [];

  if (rows.length) {
    let ordersList: any[] = rows;

    // Пока нет фильтров

    const sortUpdateAtFn =
      (reverse: boolean = false) =>
      ({ updated_at: a }: any, { updated_at: b }: any) =>
        (new Date(a).getTime() - new Date(b).getTime()) * (reverse ? -1 : 1);

    ordersList = sortBy(sortList.values['updated_at'], ordersList, sortUpdateAtFn);

    const sortIdFn =
      (reverse: boolean = false) =>
      ({ id: a }: any, { id: b }: any) =>
        (a - b) * (reverse ? -1 : 1);

    ordersList = sortBy(sortList.values['id'], ordersList, sortIdFn);

    resultArray = ordersList;
  }

  const [showEmptyTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: 'Список обращений пуст. Создайте первое обращение в службу поддержки.',
  });

  useEffect(() => {
    if (!resultArray.length) showEmptyTableAlert(true);
    else clearAlerts();
  }, [resultArray]);

  return <TableController tableStore={tableStore} rows={[...resultArray]} {...tablePreset} />;
};

export default observer(FeedbackListDataFilters);
