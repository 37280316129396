import React from 'react';

// Components
import TrainingQuery from './TrainingQueryContainer';
import LoadingBlock from '../../common/components/LoadingBlock';

// Types
import { FC } from 'react';
import { ITrainingProps } from './types';

const Training: FC<ITrainingProps> = ({ authStore }) => {
  const { role } = authStore;
  if (!role.value) return <LoadingBlock />;

  return <TrainingQuery />;
};

export default Training;
