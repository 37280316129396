import React from 'react';

// Components
import { IconButton } from '@mui/material';

// Icons & Images
import { Close as CloseIcon } from '@mui/icons-material';

// Types
import { FC } from 'react';
interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <IconButton aria-label="delete" color="default" size="small" onClick={onClick} type="button">
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseButton;
