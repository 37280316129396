import React from 'react';

// Components
import { Redirect } from 'react-router-dom';
import AptekaListQuery from './AptekaListQuery';
import LoadingBlock from '../../common/components/LoadingBlock';

// Types
import { FC } from 'react';
import { IAptekaListProps } from './types';

const AptekaList: FC<IAptekaListProps> = ({ authStore }) => {
  // const history = useHistory();
  // const { page, filters } = useParams();

  // // useEffect(() => {
  // //   aptekaListTableStore.setHistory(history, '/main/apteka_list/');
  // //   aptekaListTableStore.setFiltersValueFromURI(page, filters);
  // //   // Сброс значений при уходе со страницы.
  // //   return aptekaListTableStore.resetFilters;
  // // }, [aptekaListTableStore, history, page, filters]);

  // См. MainRoot
  if (!authStore.role.value) return <LoadingBlock />;
  if (!authStore.getAccess('aptekaListPg')) return <Redirect to="/main/order_list/1" />;

  return <AptekaListQuery />;
};

export default AptekaList;
