import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import FeedbackQueryDataHandler from './FeedbackQueryDataHandler';

// Types
import { FC } from 'react';

// Queries
import { loader } from 'graphql.macro';
const loadFeedbackData = loader('./gqlQueries/loadFeedbackData.gql');

const FeedbackQuery: FC = () => {
  return <Query query={loadFeedbackData} children={LoginErrorRedirector(FeedbackQueryDataHandler)} />;
};

export default FeedbackQuery;
