import React from 'react';

// Components
import FeedbackDetails from './FeedbackDetailsUIContainer';

// Types
import { FC } from 'react';
import { TFeedbackQueryDataHandlerProps } from './types';

const FeedbackQueryDataHandler: FC<TFeedbackQueryDataHandlerProps> = ({ data, loading, refetch }) => {
  const feedback = data?.pharmacyFeedback;
  const initMessage = feedback?.feedback_messages?.[0].text;
  const messages = feedback?.feedback_messages;

  return <FeedbackDetails {...{ feedback, initMessage, messages }} {...{ loading, refetch }} />;
};

export default FeedbackQueryDataHandler;
