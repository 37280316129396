import React from 'react';

// Components
import TableController from '../../common/components/tables/TableController';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';
import { stringVerification, steriliseNumber } from '../../utils/stringParse';
import { sortBy } from '../../utils/other';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

// Types
import { FC } from 'react';
import { IOrderListDataFiltersProps } from './types';

const OrderListDataFilters: FC<IOrderListDataFiltersProps> = ({ tableStore, authStore, rows, tablePreset }) => {
  const { filtersList, unpackOrder, removeElementFromLegendList, sortList, useTableAlert, clearAlerts } = tableStore;

  let resultArray: any[] = [];

  const { id, phone, status, date, apteka, city } = filtersList.formValues;

  if (rows.length) {
    let ordersList: any[] = rows;

    if (unpackOrder.value) {
      ordersList = ordersList.filter(({ optionValues }) => !optionValues.is_storable);
    } else {
      // Фильтр по id
      if (id) {
        let filterRegexp = new RegExp(id as string);
        ordersList = ordersList.filter(({ id }) => filterRegexp.test(id.toString()));
      }

      // фильтр по телефону
      if (phone) {
        let filterRegexp = new RegExp(steriliseNumber(String(phone)));
        ordersList = ordersList.filter(({ phone }) => filterRegexp.test(steriliseNumber(phone)));
      }

      // фильтр по статусам
      if (status) {
        ordersList = ordersList.filter(({ status: compared }) => {
          return (
            // @ts-ignore
            status.includes(compared) ||
            // @ts-ignore
            (['accepted', 'readyToIssue'].includes(compared) && status.includes('preset'))
          );
        });
      }

      // фильтр по дате
      // @ts-ignore
      if (date[0] && date[1]) {
        // @ts-ignore
        const [startDate, endDate] = date;
        ordersList = ordersList.filter(
          ({ date: compared }: { date: any }) =>
            differenceInCalendarDays(new Date(compared!), new Date(startDate!)) >= 0 &&
            differenceInCalendarDays(new Date(compared!), new Date(endDate!)) <= 0
        );
      }

      // фильтр по названию аптеки
      if (apteka) {
        const search = String(apteka).replace(/\\n/g, ' ').toLowerCase();
        ordersList = ordersList.filter(({ apteka }) => {
          const str = JSON.stringify(apteka).replace(/\\n/g, ' ').toLowerCase();
          return stringVerification(search, str);
        });
      }

      // фильтр по городу
      if (city) {
        const search = String(city).replace(/\\n/g, ' ').toLowerCase();
        ordersList = ordersList.filter(({ city }) => {
          const str = JSON.stringify(city).replace(/\\n/g, ' ').toLowerCase();
          return stringVerification(search, str);
        });
      }
    }

    const sortUpdateAtFn =
      (reverse: boolean = false) =>
      ({ date: a }: any, { date: b }: any) =>
        (new Date(a).getTime() - new Date(b).getTime()) * (reverse ? -1 : 1);

    ordersList = sortBy(sortList.values['date'], ordersList, sortUpdateAtFn);

    const sortIdFn =
      (reverse: boolean = false) =>
      ({ id: a }: any, { id: b }: any) =>
        (a - b) * (reverse ? -1 : 1);

    ordersList = sortBy(sortList.values['id'], ordersList, sortIdFn);

    resultArray = ordersList;

    if (!ordersList.filter(({ optionValues }) => !optionValues.is_storable).length) removeElementFromLegendList('isNotStorable');
  }

  const [showEmptyTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: authStore.isRole('ROLE_PHARMACIST')
      ? 'Список заказов пуст. Все зарегистрированные заказы в программе лояльности будут отображаться списом в таблице.'
      : 'Список заказов пуст, в системе не зарегистрированы заказы.',
  });

  const [showEmptyInWorkTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: authStore.isRole('ROLE_PHARMACIST')
      ? 'Сейчас нет новых заказов, чтобы посмотреть уже обработанные заказы, воспользуйтесь фильтрацией в поле статус заказа.'
      : 'Список заказов пуст, в системе не зарегистрированы новые заказы.',
  });

  const [showEmptyIsStorableTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: 'В списке нет заказов с истекшим сроком хранения.',
  });

  const [showEmptyWithFiltersTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: 'Список заказов пуст. Воспользуйтесь фильтрацией для поиска других заказов.',
  });

  useEffect(() => {
    if (!resultArray.length) {
      if (unpackOrder.value) showEmptyIsStorableTableAlert(true);
      //@ts-ignore
      else if (status?.length === 1 && status.includes('preset')) showEmptyInWorkTableAlert(true);
      //@ts-ignore
      else if (!id && phone?.length < 3 && !(date[0] && date[1]) && !apteka && !city && status?.length === 7) showEmptyTableAlert(true);
      else showEmptyWithFiltersTableAlert(true);
    } else clearAlerts();
  }, [resultArray, id, phone, status, date, apteka, city, unpackOrder.value]);

  return <TableController tableStore={tableStore} rows={resultArray} {...tablePreset} />;
};

export default observer(OrderListDataFilters);
