import React, { FC } from 'react';

// Components
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Card, CardContent, ButtonGroup } from '@mui/material';
import ButtonBack from '../../common/components/ButtonBack';
import GiveOutOrderCard from './components/GiveOutOrderCard';
import LoadingBlock from '../../common/components/LoadingBlock';

// Hooks
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { useAlert } from '../../Notifier';

// Utils
import { makeStyles } from '@mui/styles';
import { getDateString } from '../../utils/dateTimeUtils';

// Types
import { styled } from '@mui/material/styles';
import { Theme, TableHeadProps, TableCellProps } from '@mui/material';
import { TOrderDetailsUIProps } from './types';
import { ResultStatus } from '../../stores/classes/AuthStore';

// Запросы
import { loader } from 'graphql.macro';
const accept = loader('./gqlQueries/accept.gql');
const cancelOrder = loader('./gqlQueries/cancelOrder.gql');
const cancelOrderWithComment = loader('./gqlQueries/cancelOrderWithComment.gql');
const cancelOrderWithProducts = loader('./gqlQueries/cancelOrderWithProducts.gql');
const unpacked = loader('./gqlQueries/unpackedOrder.gql');

const StyledTableHead = styled(TableHead)<TableHeadProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));

const StyledTableHeadCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontSize: '1rem',
}));

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    maxHeight: 100,
  },
  rightButton: {
    marginLeft: 20,
  },
  tableCellTitle: {
    color: 'grey',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '32px',
    padding: '16px',
    alignItems: 'start',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '400px 400px auto',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '16px',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'end',
    },
  },
}));

const OrderDetailsUI: FC<TOrderDetailsUIProps> = ({ authStore, modalStore, order, loading, refetch }) => {
  const history = useHistory();
  const alert = useAlert();
  const classes = useStyles();
  const theme = useTheme();

  // Обработка ошибок запроса
  const onError = (error: any) => {
    if (error)
      if (error.networkError && JSON.parse(JSON.stringify(error.networkError)).statusCode === 401) {
        localStorage.setItem('token', '');
        history.push('/login');
        alert.error('Ошибка', 'Ошибка авторизации');
      } else alert.error('Ошибка', error.message);
    else alert.error('Ошибка', error.message);
  };

  // уведомить о готовности
  const [acceptOrder] = useMutation(accept, {
    onCompleted: () => {
      alert.success('Заказ готов к выдаче', 'Уведомление отправлено пользователю.');
      refetch();
    },
    onError,
  });

  // Запросы на отмену заказа.
  const [cancel] = useMutation(cancelOrder, {
    onCompleted: () => {
      refetch();
      alert.success('Заказ отменен.', 'Уведомление отправлено пользователю.');
    },
    onError,
    errorPolicy: 'all',
  });

  const [cancelWithComment] = useMutation(cancelOrderWithComment, {
    onCompleted: () => {
      refetch();
      alert.success('Заказ отменен.', 'Уведомление отправлено пользователю.');
    },
    onError,
    errorPolicy: 'all',
  });

  const [cancelWithProductList] = useMutation(cancelOrderWithProducts, {
    onCompleted: () => {
      refetch();
      alert.success('Заказ отменен.', 'Уведомление отправлено пользователю.');
    },
    onError,
    errorPolicy: 'all',
  });

  // Запрос на разбор заказа
  const [unpackedOrder] = useMutation(unpacked, {
    onCompleted: () => {
      alert.success('Статус заказа изменен.', 'Вы можете разобрать заказ.');
      refetch();
    },
    onError,
  });

  if (loading) {
    return <LoadingBlock />;
  }

  let status: ResultStatus | null = null;
  if (order && order.status) {
    status = authStore.getStatus(order.status);
  } else
    return (
      <Card>
        <CardContent>Произошла ошибка</CardContent>
      </Card>
    );

  return (
    <>
      <div style={{ display: 'flex', padding: '16px' }}>
        <ButtonBack />
      </div>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
          padding: '16px',
        }}
      >
        Номер заказа № {order.id}
      </Typography>

      {authStore.isRole('ROLE_PHARMACIST') && (
        <div className={classes.buttons}>
          <ButtonGroup color="primary">
            <Button
              disabled={status!.innerName !== 'accepted'}
              onClick={modalStore.openHandler([
                {
                  type: 'NotifyReadyConfirmationDialog',
                  title: 'Уведомление',
                  actionButtons: [
                    {
                      text: 'Отправить',
                      action: (values, modal, { close }) => {
                        acceptOrder({ variables: { order_id: order.id } });
                        close();
                      },
                    },
                  ],
                },
              ])}
            >
              Уведомить о готовности
            </Button>
            <Button
              disabled={status!.innerName !== 'accepted'}
              // () => orderDetailsStore.orderRefuseDialogOpen.updateValue(true)
              onClick={modalStore.openHandler([
                {
                  isForm: true,
                  type: 'OrderRefuseDialog',
                  title: 'Отказ от заказа',
                  values: authStore.refuseStatusesList?.map((el: any) => ({
                    value: el.id,
                    label: el.name,
                  })),
                  actionButtons: [
                    {
                      text: 'Подтвердить',
                      disabled: () => {
                        const value = modalStore.formElementsList.get('reason_id')?.value;
                        if (value === undefined || value === '') return true;
                        else if (value === 4 && !modalStore.formElementsList.get('comment')?.value) return true;
                        else return false;
                      },
                      action: (values, modal, { close, setModal }) => {
                        switch (modalStore.formElementsList.get('reason_id')?.value) {
                          case 0:
                            setModal(1);
                            break;

                          case 4:
                            cancelWithComment({
                              variables: { order_id: order.id, ...values },
                            });
                            close();
                            break;

                          default:
                            cancel({
                              variables: { order_id: order.id, ...values },
                            });
                            close();
                            break;
                        }
                      },
                    },
                  ],
                },
                {
                  isForm: true,
                  type: 'SelectEpsondProductsDialog',
                  title: 'Выберите отсутствующие товары',
                  values: order.order_data,
                  maxWidth: false,
                  actionButtons: [
                    {
                      text: 'Подтвердить',
                      action: (values, modal, { close }) => {
                        const { inputs }: any = values;
                        order.order_data.forEach(({ product, quantity }) => {
                          if (inputs[String(product.id)] >= quantity)
                            modalStore.formElementsList
                              .get('inputs')
                              // Далее игнор, т.к. typescript не видит, что в данном случае возвражается
                              // FormElementsListStore, имеющий метод get
                              // @ts-ignore
                              ?.get(String(product.id))
                              ?.isError.updateValue(true);
                          else
                            modalStore.formElementsList
                              .get('inputs')
                              // Далее игнор, т.к. typescript не видит, что в данном случае возвражается
                              // FormElementsListStore, имеющий метод get
                              // @ts-ignore
                              ?.get(String(product.id))
                              ?.isError.updateValue(false);
                        });
                        // @ts-ignore
                        if (!modalStore.formElementsList.get('inputs')?.withAllErrors) {
                          cancelWithProductList({
                            variables: {
                              order_id: order.id,
                              reason_id: 0,
                              products_list: order.order_data.map(({ product }: any) => ({
                                product_id: product.id,
                                quantity: inputs[String(product.id)],
                              })),
                            },
                          });
                          close();
                        }
                      },
                    },
                  ],
                },
              ])}
            >
              Отказаться от заказа
            </Button>
            <Button
              disabled={!(['accepted', 'readyToIssue'].includes(status!.innerName) && !order.is_storable)}
              onClick={modalStore.openHandler([
                {
                  type: 'MarkAsUnpackedDialog',
                  title: 'Разобрать заказ?',
                  values: [order.id],
                  actionButtons: [
                    {
                      text: 'Подтвердить',
                      action: (values, modal, { close }) => {
                        unpackedOrder({ variables: { order_id: order.id } });
                        close();
                      },
                    },
                  ],
                },
              ])}
            >
              Разобрать заказ
            </Button>
          </ButtonGroup>
        </div>
      )}

      <div className={classes.main}>
        {authStore.isRole('ROLE_PHARMACIST') && (
          <GiveOutOrderCard id={order.id} status={status} disabled={status!.innerName !== 'readyToIssue'} refetch={refetch} />
        )}

        <Paper style={{ width: '100%' }} component={Table}>
          <StyledTableHead>
            <TableRow>
              <StyledTableHeadCell colSpan={2}>Данные о заказе</StyledTableHeadCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>Заказчик</TableCell>
              <TableCell>{order.user.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>Дата заказа</TableCell>
              <TableCell>{getDateString(order.created_at)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>Статус</TableCell>
              <TableCell style={{ color: authStore.getStatus(order.status).color, fontWeight: 900 }}>{status!.name}</TableCell>
            </TableRow>
          </TableBody>
        </Paper>

        <Paper component={Table}>
          <StyledTableHead>
            <TableRow>
              <StyledTableHeadCell>Товар</StyledTableHeadCell>
              <StyledTableHeadCell sx={{ width: '150px' }}>Количество товара</StyledTableHeadCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {order &&
              order.order_data &&
              order.order_data.map(
                (
                  item: {
                    product: {
                      name: string;
                    };
                    quantity: number;
                  },
                  i: number
                ) => (
                  <TableRow key={i} style={i % 2 ? { backgroundColor: theme.palette.grey[100] } : {}}>
                    <TableCell>{item.product && item.product.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Paper>
      </div>
    </>
  );
};

export default OrderDetailsUI;
