import React, { createContext, FC } from 'react';
import aptekas from './data/aptekas.json';
import orders from './data/orders.json';
import coupons from './data/coupons.json';
import products from './data/products.json';
import cities from './data/cities.json';
import entities from './data/etities.json';
import orderStatuses from './data/orderStatuses.json';

import { IDataBase } from './type';

const DataBase: IDataBase = {
  aptekas,
  // @ts-ignore
  orders,
  coupons,
  products,
  cities,
  entities,
  orderStatuses,
};

export const DB = createContext(DataBase);

const DBProvider: FC = ({ children }: { [x: string]: any }) => {
  return <DB.Provider value={DataBase}>{children}</DB.Provider>;
};

export default DBProvider;
