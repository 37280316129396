import React from 'react';

// Components
import { Paper, Typography, Chip, Grid } from '@mui/material';
import ButtonBack from '../../common/components/ButtonBack';
import TrainingCard from './components/TrainingCard';

// Hooks
import { useParams, useHistory } from 'react-router';

// Utils
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { getObjFromURI, getURIFromObj } from '../../utils/uriUtils';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ITrainingUIProps } from './types';

// Styles
const useStyle = makeStyles((theme: Theme) => ({
  headDescript: {
    textAlign: 'left',
    padding: theme.spacing(0, 2),
    fontSize: 18,
  },
  headDescriptText: {
    marginRight: theme.spacing(1),
  },
  headDescriptTheme: {
    fontWeight: 300,
  },
  container: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'left',
  },
  cardList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const TrainingUI: FC<ITrainingUIProps> = ({ instructions_list, tags_list }) => {
  const history = useHistory();
  let { tags = '[]' } = useParams();
  tags = getObjFromURI(tags);

  const { headDescript, headDescriptText, headDescriptTheme, container, cardList } = useStyle();

  const checkTagHandler = (tag: number, tags: number[]) => () => {
    if (tags.includes(tag)) tags = tags.filter((name) => name !== tag);
    else tags.push(tag);
    history.push(`/main/training/${getURIFromObj(tags)}`);
  };

  return (
    <>
      <div style={{ margin: '0 16px', display: 'flex', justifyContent: 'left' }}>
        <ButtonBack route="/main/" />
      </div>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        База знаний
      </Typography>

      <Paper className={container}>
        {tags_list.map(({ id, name }: any) => (
          <Chip
            key={id}
            style={{ margin: 8 }}
            label={name}
            onClick={checkTagHandler(id, tags as any)}
            variant={tags?.includes(id as never) ? 'filled' : 'outlined'}
            color={tags?.includes(id as never) ? 'primary' : 'default'}
          />
        ))}
      </Paper>

      <Paper className={container}>
        {!!tags?.length && (
          <Grid container className={headDescript}>
            <Typography className={headDescriptText}>Информация по программе лояльности:</Typography>
            {tags_list
              .reduce((result: any[], { id, name }: any) => {
                if (tags?.includes(id as never)) {
                  result.push(name);
                }
                return result;
              }, [])
              .map((name: string, i: number) => {
                if (i !== tags?.length - 1) name += ',';
                return (
                  <Typography key={name + i} className={clsx(headDescriptText, headDescriptTheme)}>
                    {name}
                  </Typography>
                );
              })}
          </Grid>
        )}
        <section className={cardList}>
          {instructions_list.map((topic: any) => {
            const { id, content_types = [] } = topic;

            return content_types.map((type: string) => <TrainingCard key={`${id}-${type}`} {...topic} contentType={type} />);
          })}
        </section>
      </Paper>
    </>
  );
};

export default TrainingUI;
