import { makeObservable, observable, action } from 'mobx';

import * as StoreClasses from '../../triggers';
import ValueStore from '../ValueStore';
import ToggleStore from '../ToggleStore';
import FieldStore from '../../triggers/FieldStore';
import CheckboxListStore from '../../mapped/CheckboxListStore';
import PaginationStore from '../PaginationStore';
import { FormElementsListStore } from '../../mapped';
import SortStoreList from '../../mapped/SortStoreList';

// Utils
import { compareTwoObjects } from '../../../../utils/other';

// Types
import { ITableStore, TCreateParam, TAlerts, THasAlertFunc, TUseAlertHook } from './types';

class TableStore implements ITableStore {
  createParam: TCreateParam = (param, name, type, preset) => {
    // @ts-ignore
    if (!this[param].value[name]) this[param].value[name] = new StoreClasses[type](preset);
    // @ts-ignore
    return this[param].value[name];
  };

  // Чекбоксы
  checkboxList: CheckboxListStore = new CheckboxListStore();
  visibilityCheckbox: ToggleStore = new ToggleStore(false);

  // НИЖНЯЯ ПАНЕЛЬ ДЕЙСТВИЙ
  // Текущая активная кнопка панели действий
  // Проксимизатор текущей кнопки панели действий
  _currentButtonProxy = (target: ValueStore<number | null>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply: (target, thisArg, args) => {
        target.apply(thisArg, args);

        const [value] = args;
        if (typeof value === 'number') context.visibilityCheckbox.onOpen();
        else {
          context.visibilityCheckbox.onClose();
          context.checkboxList.clear();
        }
      },
    });
    return target;
  };
  // Значение текущей кнопки панели действий
  currentButton: ValueStore<number | null> = this._currentButtonProxy(new ValueStore<number | null>(null));

  // Спойлеры для строк
  spoilerList: ValueStore<{ [x: string]: ToggleStore }> = new ValueStore({});
  visibilitySpoiler: ToggleStore = new ToggleStore(false);

  // Фильтры
  filtersList: FormElementsListStore = new FormElementsListStore();

  // Сотрировка
  sortList: SortStoreList = new SortStoreList();

  // Пагинация
  _paginationProxy = (target: PaginationStore) => {
    const context = this;
    const fProxy = (f: any) =>
      new Proxy(f, {
        apply: (target, thisArg, args) => {
          target.apply(thisArg, args);

          // Если нужно очищать выбор через чекбоксы в таблице при пагинации - разкоментировать.
          // context.checkboxList.clear();

          context.clearLegendList();
        },
      });

    target.page.updateValue = fProxy(target.page.updateValue);
    target.perPage.updateValue = fProxy(target.perPage.updateValue);

    return target;
  };

  pagination: PaginationStore = this._paginationProxy(new PaginationStore());

  // Панель предупреждений
  private _alerts: ValueStore<TAlerts> = new ValueStore<TAlerts>([]);

  getAlertList = () => this._alerts.value;

  hasAlert: THasAlertFunc = (al) => !!this._alerts.value.find((el) => compareTwoObjects(al, el));

  clearAlerts = () => this._alerts.updateValue([]);

  useTableAlert: TUseAlertHook = (al) => [
    (clearOther) => {
      if (clearOther) this._alerts.updateValue([al]);
      else if (!this.hasAlert(al)) this._alerts.value.push(al);
    },
    () => {
      this._alerts.updateValue(this._alerts.value.filter((el) => !compareTwoObjects(al, el)));
    },
    al,
  ];

  // Легенда
  legendList: string[] = [];
  addElementToLegendList = (value: string) => {
    if (!this.legendList.includes(value)) this.legendList.push(value);
  };

  removeElementFromLegendList = (value: string) => {
    if (this.legendList.includes(value)) this.legendList = this.legendList.filter((legendName) => legendName !== value);
  };

  clearLegendList = () => (this.legendList = []);

  // Панель настроек
  settingsIsOpen: ToggleStore = new ToggleStore();
  // Настройки
  totalOnEachPage: FieldStore<boolean> = new FieldStore({ def: true, type: 'checked' });
  saveCurrentPage: FieldStore<boolean> = new FieldStore({ def: false, type: 'checked' });
  fileName: FieldStore<string> = new FieldStore({ def: '' });

  constructor() {
    makeObservable(this, {
      legendList: observable,
      addElementToLegendList: action,
      clearLegendList: action,
    });
  }
}

export default TableStore;
