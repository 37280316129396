import React from 'react';

// Components
import { IconButton } from '@mui/material';

// Icons & Images
import { Edit as EditIcon } from '@mui/icons-material';

// Types
import { FC } from 'react';
interface EditButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const EditButton: FC<EditButtonProps> = ({ onClick, disabled }) => {
  return (
    <IconButton aria-label="delete" color="default" size="small" {...{ onClick, disabled }}>
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

export default EditButton;
