import { makeObservable } from 'mobx';
// @ts-ignore
import Favico from 'favico.js';

class FavicoStore {
  private count: number = 0;
  private favicon = new Favico({
    animation: 'popFade',
  });
  private isBlur: boolean = true;

  constructor() {
    makeObservable(this, {});
  }

  addMessCount = () => {
    if (this.isBlur) {
      this.count++;
      this.favicon.badge(this.count);
    }
  };

  resetMessCount = () => {
    this.favicon.reset();
    this.count = 0;
  };

  onBlur = () => (this.isBlur = true);
  onFocus = () => {
    this.isBlur = false;
    this.resetMessCount();
  };
}

export default FavicoStore;
