import React from 'react';

// Components
import { Tabs, Tab } from '@mui/material';

// Hooks
import { useHistory, useParams } from 'react-router';

// Types
import { FC } from 'react';
import { IFilterMenuProps } from '../types';

const Menu: FC<IFilterMenuProps> = ({ isShownTable }) => {
  const history = useHistory();
  const { type = 'medicine' } = useParams();

  const buttons = {
    medicine: {
      name: 'Отчет по препаратам',
      onClick: () => {
        history.push('/main/statistics/medicine');
        isShownTable.updateValue(false);
      },
    },
    coupon: {
      name: 'Отчет по промокодам',
      onClick: () => {
        history.push('/main/statistics/coupon');
        isShownTable.updateValue(false);
      },
    },
    orders: {
      name: 'Отчет по заказам',
      onClick: () => {
        history.push('/main/statistics/orders');
        isShownTable.updateValue(false);
      },
    },
  };

  return (
    <Tabs value={Object.keys(buttons).indexOf(type)} indicatorColor="primary" textColor="primary" centered>
      {Object.values(buttons).map((button: any) => {
        const { name, onClick } = button;
        return <Tab key={name} label={name} onClick={onClick} />;
      })}
    </Tabs>
  );
};

export default Menu;
