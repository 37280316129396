import React from 'react';

// Hooks
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

// Utils
import { uriCore } from '../../../../client/Client';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const getHref = (attachment: any, nesting: string[] = []) => {
  for (let i = 0; i < nesting.length; i++) {
    const attachmentName = nesting[i];
    if (typeof attachment === 'object') attachment = attachment[attachmentName];
    else return null;
  }
  return attachment;
};

const SaveFileDialog: FC<IContentProps> = ({ modalStore, values, yarnValues }) => {
  const link = useRef<HTMLAnchorElement>(null);
  const [err, setErr] = useState<string | null>(null);
  //   const [sec, setSec] = useState(0);
  //   const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { setModalParam } = modalStore;
  const { query, nesting } = values || {};
  const { variables, fileName = '' } = yarnValues || {};

  const [queryFn, { loading }] = useMutation(query, {
    onCompleted: (data) => {
      const href = getHref(data, nesting);
      const a = link?.current;
      if (a && href) {
        !err && setErr(null);
        a.target = '_blank';
        a.download = fileName;
        a.href = uriCore + href;
        a.click();
      }
    },
    onError: () => setErr('Произошла ошибка при формировании отчета! Попробуйте еще раз.'),
  });

  //   useEffect(() => {
  //     if (loading && !timer) setTimer(setInterval(() => setSec(sec + 1), 1000));
  //     else if (timer) {
  //       clearInterval(timer);
  //       setTimer(null);
  //     }
  //   }, [loading]);

  useEffect(() => {
    queryFn && queryFn({ variables });
  }, [variables]);

  useEffect(() => {
    if (err) setModalParam('variant', 'error');
    else setModalParam('variant', 'info');
  }, [err]);

  return (
    <>
      <div>{err || (loading ? 'Подождите...' : 'Успешно! Отчет сохранен в загрузках.')}</div>
      <a ref={link} />
    </>
  );
};

export default SaveFileDialog;
