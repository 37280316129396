import { makeObservable, observable, action, computed } from 'mobx';

import FieldStore from './triggers/FieldStore';

class LoginStore {
  phone: FieldStore<string> = new FieldStore();
  password: FieldStore<string> = new FieldStore();

  constructor() {
    makeObservable(this, {
      fieldsValue: computed,
      showPassword: observable,
      toggleShowPassword: action,
    });
  }

  get fieldsValue() {
    return {
      login: this.phone.value,
      password: this.password.value,
    };
  }

  showPassword = false;
  toggleShowPassword = () => (this.showPassword = !this.showPassword);
}

export default LoginStore;
