import React from 'react';

// Components
import { Grid, Typography, Button } from '@mui/material';
import OrderListDataFilters from './OrderListDataFilters';
import ReportIcon from './components/icons/ReportIcon';
import Status from '../../common/components/Status';
import Footer from '../../common/components/Footer';
import ButtonBack from '../../common/components/ButtonBack';

// Icons & Images
import { WorkOff as UnpackedIcon } from '@mui/icons-material';

// Hooks
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { useAlert } from '../../Notifier';
import { useTheme } from '@mui/material/styles';
import { useActionPanel } from '../../common/components/tables';
import useOutsideScrollbar from '../../common/hooks/useOutsideScrollbar';

// Utils
import { getDateString } from '../../utils/dateTimeUtils';
import { onErrorHandler } from '../../utils/responseHandler';
import { steriliseNumber } from '../../utils/stringParse';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IOrderListTableProps } from './types';
import { ITablePreset, TStrStr } from '../../common/components/tables/types';

// Queries
import { loader } from 'graphql.macro';
const unpacked = loader('./gqlQueries/unpackedOrders.gql');

const OrderListTable: FC<IOrderListTableProps> = ({ orderListTableStore, modalStore, authStore, rows, refetch }) => {
  const history = useHistory();
  const alert = useAlert();
  const theme: Theme = useTheme();

  const [scrolledContent, handleContentScroll, OutsideScrollbar] = useOutsideScrollbar();

  const { addElementToLegendList, unpackOrder } = orderListTableStore;

  const [unpackedOrders] = useMutation(unpacked, {
    onCompleted: ({ sortOutOrders }) => {
      const { success, fail } = sortOutOrders;
      alert.groupOperations('Разобраны заказы', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/order_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const { isRole } = authStore;

  const ResultStatusName: TStrStr = {
    accepted: 'Не обработан аптекой',
    cancelledByUser: 'Отменён пользователем',
    cancelledByApteka: 'Отменён аптекой',
    readyToIssue: 'Готов к выдаче',
    issued: 'Выдан',
    dontKnow: 'Неизвестно',
    unpacked: 'Разобран аптекой',
  };

  const ResultStatusColor: TStrStr = {
    accepted: theme.palette.error.main,
    readyToIssue: theme.palette.secondary.main,
    issued: '#006C84',
    cancelledByUser: '#7D4427',
    cancelledByApteka: '#4B7447',
    unpacked: theme.palette.grey[600],
    dontKnow: 'grey',
  };

  const getUniqueValuesFromRows = (rows: any[], key: string) =>
    rows.reduce((result: string[], row: any) => {
      let value = row[key];
      if (typeof value === 'number') value = String(value);
      if (!result.includes(value)) result.push(value);
      return result;
    }, []);

  const phones = getUniqueValuesFromRows(rows, 'phone').map((phone: string) => {
    return {
      value: steriliseNumber(phone),
      label: phone,
    };
  });
  const ordersId = getUniqueValuesFromRows(rows, 'id');

  const [, secondaryActionPanel, primaryButtons] = useActionPanel(
    orderListTableStore,
    isRole('ROLE_PHARMACIST')
      ? [
          {
            icon: UnpackedIcon,
            text: 'Разобрать заказы',
            variant: 'contained',
            color: 'error',
            sx: { marginLeft: theme.spacing(1), fontSize: '0.875rem', fontWeight: 500, padding: theme.spacing(0.75, 2) },
            done: () => unpackOrder.onOpen(),
            close: () => unpackOrder.onClose(),
            action: [
              {
                icon: UnpackedIcon,
                text: 'Разобрать',
                action: modalStore.openHandler([
                  {
                    type: 'MarkAsUnpackedDialog',
                    title: 'Разобрать заказ',
                    values: orderListTableStore.checkboxList.checked,
                    actionButtons: [
                      {
                        text: 'Подтвердить',
                        disabled: () => !orderListTableStore.checkboxList.checked.length,
                        action: (values, modalNum, { close }) => {
                          unpackedOrders({ variables: { ids: orderListTableStore.checkboxList.checked.map((id) => Number(id)) } });
                          close();
                          orderListTableStore.checkboxList.removeCheck();
                        },
                      },
                    ],
                  },
                ]),
              },
            ],
          },
        ]
      : []
  );

  const orders: ITablePreset = {
    head: {
      id: 'Номер заказа',
      phone: 'Телефон заказчика',
      date: 'Дата заказа',
      status: 'Статус заказа',
      ...(isRole('ROLE_NETWORK_MANAGER') ? { apteka: 'Название аптеки', city: 'Город' } : {}),
    },
    filters: {
      id: {
        type: 'AutocompleteFilter',
        values: ordersId,
        size: 'small',
        placeholder: 'Введите номер заказа',
      },
      phone: {
        type: 'AutocompleteFilter',
        values: getUniqueValuesFromRows(rows, 'phone'),
        preset: {
          mask: '+7 ___ ___-__-__',
        },
        size: 'small',
      },
      date: {
        type: 'DatePeriodPickerField',
        size: 'small',
      },
      status: {
        type: 'SelectMultipleField',
        values: [
          { value: 'preset', label: 'Заказы в работе' },
          { value: 'accepted', label: 'Не обработан аптекой' },
          { value: 'readyToIssue', label: 'Готов к выдаче' },
          { value: 'issued', label: 'Выдан' },
          { value: 'cancelledByUser', label: 'Отменён пользователем' },
          { value: 'cancelledByApteka', label: 'Отменён аптекой' },
          { value: 'unpacked', label: 'Разобран аптекой' },
        ],
        preset: {
          def: isRole('ROLE_NETWORK_MANAGER')
            ? ['preset', 'accepted', 'readyToIssue', 'issued', 'cancelledByUser', 'cancelledByApteka', 'unpacked']
            : [
                'preset',
                // 'accepted',
                // 'readyToIssue',
              ],
        },
        size: 'small',
      },
      ...(isRole('ROLE_NETWORK_MANAGER')
        ? {
            apteka: {
              type: 'AutocompleteFilter',
              values: getUniqueValuesFromRows(rows, 'apteka'),
              size: 'small',
              placeholder: 'Введите название аптеки',
            },
            city: {
              type: 'AutocompleteFilter',
              values: getUniqueValuesFromRows(rows, 'city'),
              size: 'small',
              placeholder: 'Введите город',
            },
          }
        : {}),
    },
    sort: {
      id: 'desc',
      date: false,
    },
    options: {
      tableRef: scrolledContent,
      tableScrollHandler: handleContentScroll,
      isBeSaved: false,
      columnsWidth: {
        id: 180,
        phone: 270,
        date: 300,
        status: 250,
        apteka: 200,
        city: 200,
      },
      clickOnRowHandler: (e, { id } = {}) => history.push(`/main/order_details/${id}`),
      cellsRender: {
        row: {
          date: (key, value, optionValues) => {
            const { is_storable } = optionValues || {};
            const darwLabel = !is_storable && isRole('ROLE_PHARMACIST');
            if (darwLabel) addElementToLegendList('isNotStorable');
            return (
              <>
                {getDateString(value)}
                {darwLabel && <ReportIcon />}
              </>
            );
          },
          status: (key, value) => {
            return <Status color={ResultStatusColor[value]} text={ResultStatusName[value]} />;
          },
        },
      },
      legendElements: {
        isNotStorable: () => (
          <Typography>
            <ReportIcon /> - Срок хранения заказа истёк, Вы можете разобрать заказ.
          </Typography>
        ),
      },
      actionPanel: secondaryActionPanel,
      headActionPanel: () => {
        if (isRole('ROLE_NETWORK_MANAGER')) return null;
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={modalStore.openHandler([
                {
                  isForm: true,
                  type: 'IssueAnOrderDialog',
                  title: 'Выдача заказа',
                  values: { phone: phones, id: ordersId },
                  actionButtons: [
                    {
                      text: 'Перейти',
                      action: (values, modal, { close, setModal }) => {
                        const { searchType, searchValue } = values as { [x: string]: string };

                        let filterRegexp = new RegExp(steriliseNumber(searchValue));
                        let listItems = rows.filter((row: any) => filterRegexp.test(steriliseNumber(String(row[searchType]))));

                        if (!listItems.length) setModal(3, values);
                        else if (listItems.length === 1) {
                          if (listItems[0].status === 'readyToIssue') {
                            history.push(`/main/order_details/${listItems[0].id}`);
                            close();
                          } else
                            setModal(2, {
                              id: listItems[0].id,
                              phone: listItems[0].phone,
                              status: listItems[0].status,
                              searchValue,
                              searchType,
                            });
                        } else {
                          listItems = listItems.filter((row: any) => row.status === 'readyToIssue');
                          if (!listItems.length) setModal(4, { searchType, searchValue });
                          else if (listItems.length === 1) {
                            history.push(`/main/order_details/${listItems[0].id}`);
                            close();
                          } else setModal(1, listItems);
                        }
                      },
                    },
                  ],
                },
                {
                  type: 'OrderSelectDialog',
                  title: 'Выберите заказ',
                  values: history,
                  maxWidth: 'md',
                  fullWidth: true,
                  actionButtons: [
                    {
                      text: 'Вернуться к поиску',
                      action: (values, modal, { setModal }) => {
                        setModal(0);
                      },
                    },
                  ],
                },
                {
                  type: 'OrderNotReadyToIssueDialog',
                  title: 'Ошибка',
                  variant: 'error',
                  actionButtons: [
                    {
                      text: 'Вернуться к поиску',
                      action: (values, modal, { setModal }) => {
                        setModal(0);
                      },
                    },
                  ],
                },
                {
                  type: 'OrderNotFoundDialog',
                  title: 'Ошибка',
                  variant: 'error',
                  actionButtons: [
                    {
                      text: 'Вернуться к поиску',
                      action: (values, modal, { setModal }) => {
                        setModal(0);
                      },
                    },
                  ],
                },
                {
                  type: 'OrdersFoundButNotReady',
                  title: 'Ошибка',
                  variant: 'error',
                  actionButtons: [
                    {
                      text: 'Вернуться к поиску',
                      action: (values, modal, { setModal }) => {
                        setModal(0);
                      },
                    },
                  ],
                },
              ])}
            >
              Выдать заказ
            </Button>
            {primaryButtons}
          </>
        );
      },
    },
  };

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ padding: '0 16px' }}>
        {isRole('ROLE_NETWORK_MANAGER') && <ButtonBack route="/main/" />}
      </Grid>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        Список заказов
      </Typography>

      <OrderListDataFilters tableStore={orderListTableStore} authStore={authStore} rows={rows} tablePreset={orders} />

      <Footer children={OutsideScrollbar} />
    </>
  );
};

export default OrderListTable;
