import { makeObservable } from 'mobx';

import TriggerStore from './TriggerStore';

// Types
import { SortDirection } from '@mui/material';

class SortStore extends TriggerStore<SortDirection> {
  constructor({ def }: { def: SortDirection }) {
    super(def);
    makeObservable(this, {});
  }
}

export default SortStore;
