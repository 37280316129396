import React from 'react';

// Components
import { IconButton } from '@mui/material';

// Icons & Images
import { Close as CloseIcon } from '@mui/icons-material';

// Hooks
import { useSnackbar } from 'notistack';

// Types
import { FC } from 'react';
import { ICloseSnackbarButtonProps } from './types';

const CloseSnackbarButton: FC<ICloseSnackbarButtonProps> = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="Close"
      size="small"
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseSnackbarButton;
