import { grey } from '@mui/material/colors';

export const main_blue = '#49BAD6';
export const main_red = '#FF7C7C';
export const main_green = '#B5D380';

export const light_grey = grey[50];
export const medium_grey = grey[100];
export const dark_grey = grey[200];
export const grey_300 = grey[300];
export const grey_400 = grey[400];
