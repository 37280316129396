import React from 'react';

// Components
import { Grid } from '@mui/material';
import { PaginationActions, PaginationRowsLabel, PaginationPerPageSelector } from '.';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { IPaginationProps } from './types';

const Pagination: FC<IPaginationProps> = ({ paginationStore, perPegeSelectorVariants, rowsLabel, totalRowsCount }) => {
  const {
    page,
    countPages,
    firstButtonClickHandler,
    backButtonClickHandler,
    nextButtonClickHandler,
    lastButtonClickHandler,

    perPage,
    perPageSelectHandler,

    rowsCount,
    rowStart,
    rowEnd,
  } = paginationStore;

  useEffect(() => {
    rowsCount.updateValue(totalRowsCount);
  }, [totalRowsCount]);

  return (
    <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
      {rowsLabel === false ? null : <PaginationRowsLabel {...{ rowsCount: rowsCount.value, rowStart, rowEnd }} />}
      <PaginationPerPageSelector variants={perPegeSelectorVariants} {...{ perPage: perPage.value, perPageSelectHandler }} />
      <PaginationActions
        {...{
          page: page.value,
          countPages,
          firstButtonClickHandler,
          backButtonClickHandler,
          nextButtonClickHandler,
          lastButtonClickHandler,
        }}
      />
    </Grid>
  );
};

export default observer(Pagination);
