import React from 'react';

// Hooks
import { useEffect } from 'react';

// Types
import { ComponentType } from 'react';
import { TriggerStoreClasses, TriggerStoreClassNames } from '../../../stores/classes/triggers';
import { MappedStoreClasses } from '../../../stores/classes/mapped';

interface IBaseFormElementProps<T = any> {
  formStore: MappedStoreClasses;
  name: string | number;
  selfRemoved?: boolean;
  preset?: T;
}

type FormElementTypes = TriggerStoreClasses | TriggerStoreClassNames;

const WithFormElementsStore = <P, T>(
  Component: ComponentType<P>,
  type: FormElementTypes
): ComponentType<IBaseFormElementProps<T> & Omit<P, 'store'>> => {
  return ({ formStore, name, selfRemoved = true, preset, ...props }) => {
    // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
    useEffect(() => {
      if (selfRemoved)
        return () => {
          formStore.delete(name);
        };
    }, [selfRemoved, formStore, name]);

    const store = formStore.set(name, type, preset);

    return <Component {...(props as P)} store={store} name={name} />;
  };
};

export default WithFormElementsStore;
