import React from 'react';

// Components
import { Box, Menu, MenuItem, Button, Link } from '@mui/material';
import RenewalLogo from '../RenewalLogoHead';

// Icons & Images
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

// Hooks
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { usePush } from '../../../Notifier';

// Utils
import { styled } from '@mui/material/styles';
import { centrifugeAuth, centrifugeSubscribe } from '../../../client/Centrifuge';
import StoreInjector from '../../../stores/StoreInjector';

// Types
import { FC } from 'react';
import { BoxProps, ButtonProps } from '@mui/material';
import { TRole } from '../../../stores/classes/AuthStore';
import { IMenuBarProps, IMenuBtn } from './types';
import { TSnackbarType } from '../../../Notifier/types';

const StyledMenuWrapper = styled(Box, {
  name: 'MuiMenuBar',
  slot: 'wrapper',
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 100,
  minHeight: 100,
  backgroundColor: '#444455',
}));

const StyledLogoWrapper = styled('div', {
  name: 'MuiMenuBar',
  slot: 'logoWrapper',
})(() => ({
  width: 140,
}));

const StyledButtonsWrapper = styled('div', {
  name: 'MuiMenuBar',
  slot: 'buttonsWrapper',
})(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: '100%',
  paddingRight: '32px',
}));

const StyledButton = styled(Button, {
  name: 'MuiMenuBar',
  slot: 'button',
})<ButtonProps>(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'normal',
  color: theme.palette.common.white,
  ':disabled': {
    color: theme.palette.grey[500],
  },
}));

const MenuBar: FC<IMenuBarProps> = ({ authStore, orderListTableStore, favicoStore, data }) => {
  const client = useApolloClient();
  const history = useHistory();
  const push = usePush();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const { isNotAccessGranted, role } = authStore;

  if (data) {
    if (data.contractor_role && !role.value) authStore.role.updateValue(data.contractor_role.role);
    if (!authStore.orderStatusesList) authStore.setOrderStatusList(data.order_statuses_list);
    if (!authStore.refuseStatusesList) authStore.setRefuseStatusList(data.order_status_reasons_cancel_list);
  }

  const access_granted = localStorage.getItem('access_granted');
  if (access_granted) authStore.isNotAccessGranted.updateValue(access_granted !== 'true');

  const { addMessCount, resetMessCount, onFocus, onBlur } = favicoStore;

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
  }, []);

  const centSubscribe = async () => {
    const websocket_data = await localStorage.getItem('websocket_data');
    const { websocket_token, websocket_channel, websocket_channel_token } = JSON.parse(websocket_data || '{}');
    if (websocket_channel && websocket_token) {
      centrifugeAuth(websocket_token);
      centrifugeSubscribe(
        websocket_channel_token,
        websocket_channel,
        ({ data }: { data: { type: TSnackbarType; header: string; message: string; orderId: number } }) => {
          const { type = 'success', header, message, orderId } = data || {};
          if (message) {
            client.refetchQueries({ include: ['getOrderList'] });
            addMessCount();
            window.addEventListener('focus', resetMessCount, { once: true });

            push[type](
              header,
              <Link href="#" onClick={() => history.push(`/main/order_details/${orderId}`)}>
                {message}
              </Link>
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    centSubscribe();
  }, []);

  const isFirstAuth = localStorage.getItem(role.value as TRole);

  if (isFirstAuth === 'false') authStore.isFirstAuth.updateValue(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const logout = () => {
    localStorage.setItem('token', '');
    localStorage.setItem('websocket_data', '');
    authStore?.role.updateValue(null);
    orderListTableStore.filtersList.clear();
    client.clearStore();
    history.push('/login');
    setMenuAnchor(null);
  };

  // Порядок кнопок меню
  const { page = 'main' } = useParams<{ page: string }>();
  const onClick = (path: string) => () => {
    history.push(path);
    setMenuAnchor(null);
  };
  const main: IMenuBtn = {
    id: 'main',
    title: 'Главная',
    onClick: onClick('/main'),
    disabled: isNotAccessGranted.value,
  };
  const apteka_list: IMenuBtn = {
    id: 'apteka_list',
    title: 'Список аптек',
    onClick: onClick('/main/apteka_list/1'),
  };
  const order_list: IMenuBtn = {
    id: 'order_list',
    title: 'Список заказов',
    onClick: onClick('/main/order_list/1'),
    disabled: isNotAccessGranted.value,
  };
  const feedback_list: IMenuBtn = {
    id: 'feedback_list',
    title: 'Обратная связь',
    onClick: onClick('/main/feedback_list/1'),
  };
  const statistics: IMenuBtn = {
    id: 'statistics',
    title: 'Взаиморасчеты',
    onClick: onClick('/main/statistics'),
    disabled: isNotAccessGranted.value,
  };
  const training: IMenuBtn = {
    id: 'training',
    title: 'База знаний',
    onClick: onClick('/main/training'),
  };
  const zero_test: IMenuBtn = {
    id: 'zero_test',
    title: 'Тест',
    onClick: onClick('/main/zero_test'),
  };

  const menuBtns: { [x: string]: IMenuBtn[] } = {
    ROLE_NETWORK_MANAGER: [apteka_list, statistics, order_list, training, feedback_list],
    ROLE_PHARMACIST: [order_list, feedback_list, training],
  };

  const sortMenuBtns = (menuBtns: IMenuBtn[], lineCount: number = 4) => {
    menuBtns = menuBtns.filter(({ id }) => id !== page);
    return [menuBtns.slice(0, lineCount), menuBtns.slice(lineCount)];
  };

  const [inLineBtns, kebabBtns] = sortMenuBtns(menuBtns[role.value as TRole] || []);

  return (
    <StyledMenuWrapper>
      <StyledLogoWrapper>
        <RenewalLogo />
      </StyledLogoWrapper>

      <StyledButtonsWrapper>
        {!!inLineBtns.length &&
          inLineBtns.map(({ title, onClick, disabled }) => (
            <StyledButton key={title} variant="text" {...{ onClick, disabled }}>
              {title}
            </StyledButton>
          ))}

        {!!kebabBtns.length && (
          <>
            <StyledButton variant="text" onClick={handleClick}>
              <MoreVertIcon />
            </StyledButton>
            <Menu
              open={Boolean(menuAnchor)}
              anchorEl={menuAnchor}
              role={undefined}
              style={{ zIndex: 1000 }}
              disablePortal
              onClose={handleCloseMenu}
            >
              {kebabBtns.map(({ title, onClick, disabled }) => (
                <MenuItem key={title} {...{ onClick, disabled }}>
                  {title}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        <StyledButton variant="text" onClick={logout}>
          Выход
        </StyledButton>
      </StyledButtonsWrapper>
    </StyledMenuWrapper>
  );
};

export default StoreInjector(MenuBar, ['authStore', 'orderListTableStore', 'favicoStore']);
