import React from 'react';

// Components
import { List, ListItem, ListItemText, Grid, Chip, Divider } from '@mui/material';

// Hooks
import { useTheme } from '@mui/material/styles';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { getDateString } from '../../../../utils/dateTimeUtils';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IContentProps } from '../types';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      padding: theme.spacing(1, 2),
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const OrderSelectDialog: FC<IContentProps<any>> = ({ modalStore, values: history, yarnValues }) => {
  const theme = useTheme();
  const { cell } = useStyles();

  const ResultStatusName: { [x: string]: string } = {
    accepted: 'Не обработан аптекой',
    cancelledByUser: 'Отменён пользователем',
    cancelledByApteka: 'Отменён аптекой',
    readyToIssue: 'Готов к выдаче',
    issued: 'Выдан',
    dontKnow: 'Неизвестно',
    unpacked: 'Разобран аптекой',
  };

  const ResultStatusColor: { [x: string]: string } = {
    accepted: theme.palette.secondary.main,
    readyToIssue: theme.palette.secondary.main,
    issued: theme.palette.grey[300],
    cancelledByUser: theme.palette.grey[400],
    cancelledByApteka: theme.palette.grey[400],
    unpacked: theme.palette.grey[600],
    dontKnow: 'grey',
  };

  return (
    <List>
      {yarnValues.map(({ id, phone, date, status }) => {
        return (
          <ListItem
            key={id}
            button
            onClick={() => {
              history.push(`/main/order_details/${id}`);
              modalStore.onClose();
            }}
          >
            <ListItemText>
              <Grid container>
                <Grid item className={cell} style={{ flexGrow: 1 }}>
                  № {id}
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item className={cell}>
                  {phone}
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item className={cell}>
                  {getDateString(date)}
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item className={cell}>
                  <Chip
                    style={{
                      backgroundColor: ResultStatusColor[status],
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                    label={ResultStatusName[status]}
                  />
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default OrderSelectDialog;
