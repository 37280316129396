import { isWithinInterval, parseJSON } from 'date-fns';
import aptekas from './data/aptekas.json';
// import orders from './data/orders.json';
import products from './data/products.json';
// import coupons from './data/coupons.json';
import orderStatuses from './data/orderStatuses.json';
import { stringVerification } from '../../../utils/stringParse';
import { arrToArrObjConverter } from '../../../utils/other';

// Types
import {
  IProductStatisticInput,
  IOrderStatisticInput,
  ICouponStatisticInput,
  IGetAptekasInput,
  IGetAptekasDB,
  IGetAptekasNameOrAddressListInput,
  IGetAptekasNameOrAddressListDB,
  IDataBase,
} from './type';

const statType: { [x: number]: string } = {
  0: 'Аптека',
  1: 'Месяц',
};

const arrToObjConverter = (arr: string[], exception?: number[] | undefined) =>
  arr.reduce((result: { [x: number]: string }, el, i) => {
    if (!exception || exception.includes(i)) {
      result[i] = el;
    }
    return result;
  }, {});

export const productStatistic = (
  {
    page = 1,
    per_page = 10,
    reverse = false,
    cities,
    entities,
    apteka_id_list,
    period,
    type = 0,
    orderStatus = [6],
  }: IProductStatisticInput = {},
  { products, orders, aptekas }: IDataBase
) => {
  const defProdStat = products.reduce((result, name, id) => {
    // @ts-ignore
    result[id] = 0;
    return result;
  }, {});

  if (!apteka_id_list && (cities || entities))
    apteka_id_list = getAptekaList({ all: true, cities, entities }, { aptekas }).apteka_list.map(
      ({ id }) => id
    );

  const resultObj = orders.reduce((result: { [x: number]: any }, order: any) => {
    const {
      pharmacy: { id: pharId },
      created_at,
      status,
      order_data,
    } = order;

    if ((!apteka_id_list && !(cities || entities)) || apteka_id_list?.includes(pharId)) {
      const createAt = parseJSON(created_at);
      if (
        !period ||
        isWithinInterval(createAt, {
          start: period.from ? parseJSON(period.from) : new Date(0),
          end: period.to ? parseJSON(period.to) : new Date(),
        })
      ) {
        if (!orderStatus || orderStatus.includes(status)) {
          // @ts-ignore
          order_data.forEach((data) => {
            const {
              quantity,
              product: { id },
            } = data;
            switch (type) {
              case 0:
                const pharmacyStat = result[pharId];
                if (pharmacyStat) {
                  const prodCount = pharmacyStat[id];
                  pharmacyStat[id] = prodCount + quantity;
                  result[pharId] = pharmacyStat;
                } else result[pharId] = { ...defProdStat, [id]: quantity };
                break;

              case 1:
                const dateStatKey = new Date(createAt.getFullYear(), createAt.getMonth()).getTime();
                const dateStat = result[dateStatKey];
                if (dateStat) {
                  const prodCount = dateStat[id];
                  dateStat[id] = prodCount + quantity;
                  result[dateStatKey] = dateStat;
                } else result[dateStatKey] = { ...defProdStat, [id]: quantity };
                break;

              default:
                break;
            }
          });
        }
      }
    }

    return result;
  }, {});

  let resultArr = [];
  for (const key in resultObj) {
    if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
      const element = resultObj[key];
      element['main'] = typeof key === 'string' ? Number(key) : key;
      resultArr.push(element);
    }
  }

  resultArr = resultArr.sort((a, b) => {
    if (Number(a.main) < Number(b.main)) {
      return reverse ? 1 : -1;
    }
    if (Number(a.main) > Number(b.main)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

  const total = resultArr.length;

  const countPages = Math.ceil(resultArr.length / per_page);
  const currentPage = countPages ? (page > countPages ? countPages : page) - 1 : 0;
  resultArr = resultArr.slice(currentPage * per_page, currentPage * per_page + per_page);

  // КАРТЫ названий для строк и столбцов
  // Карта для шапки таблицы
  const columnsMap = {
    main: statType[type],
    ...arrToObjConverter(products),
  };

  // Карта для наваний строк, если тип отчета по аптекам (первая колонка)
  let rowsMap = undefined;
  if (type === 0)
    rowsMap = resultArr.map(({ main }) => {
      const { id, name } = aptekas.find(({ id }) => id === main) || {};
      return { id, name };
    });

  return {
    product_statistic: resultArr,
    total,
    columnsMap,
    rowsMap,
  };
};

export const orderStatistic = (
  {
    page = 1,
    per_page = 10,
    reverse = false,
    cities,
    entities,
    apteka_id_list,
    period,
    type = 0,
    orderStatus,
  }: IOrderStatisticInput = {},
  { orders, aptekas }: IDataBase
) => {
  if (!orderStatus?.length) orderStatus = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  const defStatCount = orderStatus.reduce((result, id) => {
    // @ts-ignore
    result[id] = 0;
    return result;
  }, {});

  if (!apteka_id_list && (cities || entities))
    apteka_id_list = getAptekaList({ all: true, cities, entities }, { aptekas }).apteka_list.map(
      ({ id }) => id
    );

  const resultObj = orders.reduce((result: { [x: number]: any }, order: any) => {
    const {
      pharmacy: { id: pharId },
      created_at,
      status,
      order_data,
    } = order;

    if ((!apteka_id_list && !(cities || entities)) || apteka_id_list?.includes(pharId)) {
      const createAt = parseJSON(created_at);
      if (
        !period ||
        isWithinInterval(createAt, {
          start: period.from ? parseJSON(period.from) : new Date(0),
          end: period.to ? parseJSON(period.to) : new Date(),
        })
      ) {
        if (!orderStatus || orderStatus.includes(status)) {
          // @ts-ignore
          order_data.forEach(() => {
            switch (type) {
              case 0:
                const pharmacyStat = result[pharId];
                if (pharmacyStat) {
                  const prodCount = pharmacyStat[status];
                  pharmacyStat[status] = prodCount + 1;
                  result[pharId] = pharmacyStat;
                } else result[pharId] = { ...defStatCount, [status]: 1 };
                break;

              case 1:
                const dateStatKey = new Date(createAt.getFullYear(), createAt.getMonth()).getTime();
                const dateStat = result[dateStatKey];
                if (dateStat) {
                  const prodCount = dateStat[status];
                  dateStat[status] = prodCount + 1;
                  result[dateStatKey] = dateStat;
                } else result[dateStatKey] = { ...defStatCount, [status]: 1 };
                break;

              default:
                break;
            }
          });
        }
      }
    }

    return result;
  }, {});

  let resultArr = [];
  for (const key in resultObj) {
    if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
      const element = resultObj[key];
      element['main'] = typeof key === 'string' ? Number(key) : key;
      resultArr.push(element);
    }
  }

  resultArr = resultArr.sort((a, b) => {
    if (Number(a.main) < Number(b.main)) {
      return reverse ? 1 : -1;
    }
    if (Number(a.main) > Number(b.main)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

  const total = resultArr.length;

  const countPages = Math.ceil(resultArr.length / per_page);
  const currentPage = countPages ? (page > countPages ? countPages : page) - 1 : 0;
  resultArr = resultArr.slice(currentPage * per_page, currentPage * per_page + per_page);

  // КАРТЫ названий для строк и столбцов
  // Карта для шапки таблицы
  const columnsMap = {
    main: statType[type],
    ...arrToObjConverter(orderStatuses, orderStatus),
  };

  // Карта для наваний строк, если тип отчета по аптекам (первая колонка)
  let rowsMap = undefined;
  if (type === 0)
    rowsMap = resultArr.map((el) => {
      const { main } = el;
      const { id, name } = aptekas.find(({ id }) => id === main) || {};
      return { id, name };
    });

  return {
    order_statistic: resultArr,
    total,
    columnsMap,
    rowsMap,
  };
};

export const couponStatistic = (
  {
    page = 1,
    per_page = 10,
    reverse = false,
    cities,
    entities,
    apteka_id_list,
    period,
    type = 0,
  }: ICouponStatisticInput = {},
  { coupons, aptekas }: IDataBase
) => {
  // @ts-ignore
  const resultObj = coupons.reduce((result: { [x: number]: any }, coupon: any) => {
    const {
      pharmacy: { id: pharId },
      start_at,
    } = coupon;

    if (!apteka_id_list && (cities || entities))
      apteka_id_list = getAptekaList({ all: true, cities, entities }, { aptekas }).apteka_list.map(
        ({ id }) => id
      );

    if ((!apteka_id_list && !(cities || entities)) || apteka_id_list?.includes(pharId)) {
      const createAt = parseJSON(start_at);
      if (
        !period ||
        isWithinInterval(createAt, {
          start: period.from ? parseJSON(period.from) : new Date(0),
          end: period.to ? parseJSON(period.to) : new Date(),
        })
      ) {
        switch (type) {
          case 0:
            const pharmacyStat = result[pharId];
            if (pharmacyStat) {
              const couponStat = pharmacyStat['count'];
              pharmacyStat['count'] = couponStat + 1;
              result[pharId] = pharmacyStat;
            } else result[pharId] = { ['count']: 1 };
            break;

          case 1:
            const dateStatKey = new Date(createAt.getFullYear(), createAt.getMonth()).getTime();
            const dateStat = result[dateStatKey];
            if (dateStat) {
              const couponStat = dateStat['count'];
              dateStat['count'] = couponStat + 1;
              result[dateStatKey] = dateStat;
            } else result[dateStatKey] = { ['count']: 1 };
            break;

          default:
            break;
        }
      }
    }

    return result;
  }, {});

  let resultArr = [];
  for (const key in resultObj) {
    if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
      const element = resultObj[key];
      element['main'] = typeof key === 'string' ? Number(key) : key;
      resultArr.push(element);
    }
  }

  resultArr = resultArr.sort((a, b) => {
    if (Number(a.main) < Number(b.main)) {
      return reverse ? 1 : -1;
    }
    if (Number(a.main) > Number(b.main)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

  const total = resultArr.length;

  const countPages = Math.ceil(resultArr.length / per_page);
  const currentPage = countPages ? (page > countPages ? countPages : page) - 1 : 0;
  resultArr = resultArr.slice(currentPage * per_page, currentPage * per_page + per_page);

  // КАРТЫ названий для строк и столбцов
  // Карта для шапки таблицы
  const columnsMap = {
    main: statType[type],
    count: 'Количество промокодов',
  };

  // Карта для наваний строк, если тип отчета по аптекам (первая колонка)
  let rowsMap = undefined;
  if (type === 0)
    rowsMap = resultArr.map((el) => {
      const { main } = el;
      const { id, name } = aptekas.find(({ id }) => id === main) || {};
      return { id, name };
    });

  return {
    coupon_statistic: resultArr,
    total,
    columnsMap,
    rowsMap,
  };
};

export const getAptekaList = (
  {
    page = 1,
    per_page = 25,
    all = false,
    reverse = false,
    cities,
    entities,
    name_part,
    address_part,
    apteka_id_list,
  }: IGetAptekasInput,
  { aptekas }: IGetAptekasDB
) => {
  let resultArr = aptekas.filter((apteka) => {
    const { id, city, entity, name, address } = apteka;

    if (apteka_id_list?.length && apteka_id_list.includes(id)) return false;
    if (cities && !cities.includes(city.id)) return false;
    if (entities && !entities.includes(entity.id)) return false;
    if (name_part && !stringVerification(name_part.toLowerCase(), name.toLowerCase())) return false;
    if (address_part && !stringVerification(address_part.toLowerCase(), address.toLowerCase()))
      return false;
    return true;
  });

  resultArr = resultArr.sort((a, b) => {
    if (Number(a.id) < Number(b.id)) {
      return reverse ? 1 : -1;
    }
    if (Number(a.id) > Number(b.id)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

  const total = resultArr.length;

  const getCurrentPage = () => {
    const countPages = Math.ceil(resultArr.length / per_page);
    const currentPage = countPages ? (page > countPages ? countPages : page) - 1 : 0;
    return resultArr.slice(currentPage * per_page, currentPage * per_page + per_page);
  };

  if (!all) resultArr = getCurrentPage();

  return {
    apteka_list: resultArr,
    total,
  };
};

export const getAptekasNameOrAddressList = (
  { count = 10, str_part, type = 'name' }: IGetAptekasNameOrAddressListInput,
  { aptekas }: IGetAptekasNameOrAddressListDB
) => {
  let result = [];
  for (let i = 0, len = aptekas.length; i < len; i++) {
    if (result.length > count) break;
    const { [type]: field } = aptekas[i];
    if (stringVerification(str_part.toLowerCase(), field.toLowerCase())) result.push(field);
  }

  return {
    [`${type}_list`]: result,
  };
};

export const getFilterData = ({ cities, entities, orderStatuses }: IDataBase) => {
  return {
    cities_list: arrToArrObjConverter(cities),
    entities_list: arrToArrObjConverter(entities),
    order_statuses_list: arrToArrObjConverter(orderStatuses),
  };
};
