import React from 'react';

// Components
import AutocompleteField from './AutocompleteField';

// Hooks
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { TAutocompleteQueryFieldProps } from './types';

const returnArrayFromObj = (obj: { [x: string]: any }) => {
  const values = Object.values(obj);
  const leng = values.length;
  let result = null;
  for (let i = 0; i < leng; i++) {
    let el = values[i];
    if (typeof el === 'object' && !Array.isArray(el)) el = returnArrayFromObj(el);
    if (Array.isArray(el)) result = el;
  }
  return result;
};

const AutocompleteQueryField: FC<TAutocompleteQueryFieldProps> = ({ query, numLetterForQuery = 1, ...props }) => {
  const { formStore, name } = props;
  const [values, setValues] = useState<string[]>([]);

  const [request, { data }] = useLazyQuery(query);

  useEffect(() => {
    if (data) setValues(returnArrayFromObj(data) || []);
  }, [data]);

  const { formValues } = formStore;

  useEffect(() => {
    const value = formValues[name];
    if (!!value && String(value).length >= numLetterForQuery) request({ variables: formValues });
  }, [formValues[name]]);

  return <AutocompleteField {...props} values={values} />;
};

export default observer(AutocompleteQueryField);
