import React from 'react';

// Components
import { Grid, Button } from '@mui/material';
import CustomList from './CustomList';

// Icons & Images
import { Close as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';

// Hooks
import { useEffect } from 'react';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import clsx from 'clsx';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ITransferListProps } from '../types';
import TransferListStore from '../../../../stores/classes/components/TransferListStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0, 0),
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    wing: {
      width: 'calc((100% - 96px)/2)',
    },
    list: {
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(2),
    },
    dellButton: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255,124,124,.1)',
        boxShadow: 'none',
      },
      '&:active': {
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255,124,124,.5)',
        boxShadow: 'none',
      },
    },
  })
);

const TransferList: FC<ITransferListProps> = ({ formStore, name, items, selfRemoved, preset }) => {
  useEffect(() => {
    if (selfRemoved)
      // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const transferListStore = formStore.set(name, TransferListStore, preset) as TransferListStore;

  const { leftList, rightList, leftCheckboxList, rightCheckboxList, leftPagination, rightPagination, moveToRigth, delFromRight } =
    transferListStore;

  const classes = useStyles();

  // Список элементов
  // для левого листа
  // (с сервера должен приходить актуальный список с учетом страницы, и наличием уже отдельного
  // элемента в правом списке.)
  useEffect(() => {
    leftList.updateValue(items);

    // Обновление количества строк в левом списке на основании ответа с сервера
    const rowsCount = preset.leftPaginDef.rowsCount;
    if (rowsCount !== leftPagination.rowsCount.value) leftPagination.rowsCount.updateValue(rowsCount);
  }, [items]);
  // для правого листа
  const rightCurrentPageItems = rightList.value.slice(
    (rightPagination.page.value - 1) * rightPagination.perPage.value,
    rightPagination.page.value * rightPagination.perPage.value
  );

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item className={classes.wing}>
        <CustomList checkboxListStore={leftCheckboxList} paginationStore={leftPagination} title="Выбор" items={leftList.value} />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveToRigth}
            disabled={leftCheckboxList.checked.length === 0}
            aria-label="move selected right"
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={clsx(classes.button, classes.dellButton)}
            onClick={delFromRight}
            disabled={rightCheckboxList.checked.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.wing}>
        <CustomList checkboxListStore={rightCheckboxList} paginationStore={rightPagination} title="Выбрано" items={rightCurrentPageItems} />
      </Grid>
    </Grid>
  );
};

export default observer(TransferList);
