import React, { FC } from 'react';
// ReactRouter
import { useParams } from 'react-router';

import TrainingDetailsUI from './TrainingDetailsUIContainer';

// Утилиты
import { sortObjs } from '../../utils/other';

// Константы
import { uriCore } from '../../client/Client';

// ТИПИЗАЦИЯ
import { ITrainingDetailsQueryDataHandlerProps } from './types';

const TrainingDetailsQueryDataHandler: FC<ITrainingDetailsQueryDataHandlerProps> = ({
  data,
  loading,
}) => {
  const { content_type = 'content' } = useParams();

  let { instruction } = data || {};
  if (
    instruction &&
    typeof instruction.presentation_images[0] === 'object' &&
    content_type === 'slides'
  ) {
    let { presentation_images } = instruction || {};
    presentation_images = sortObjs(presentation_images, {
      fieldName: 'ordinal_number',
    }).map(({ path }) => uriCore + path);
    instruction = { ...instruction, presentation_images };
  }

  return <TrainingDetailsUI {...{ instruction, content_type, loading }} />;
};

export default TrainingDetailsQueryDataHandler;
