import React from 'react';

// Hooks
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';

// Utils
import { makeStyles, createStyles } from '@mui/styles';

// Components
import { Divider, Button, Grid, Typography, Switch, Paper } from '@mui/material';
import Menu from './Menu';
import { SelectField, Field, TransferList, DatePeriodPickerField } from '../../../common/components/FormElements';

// Icons & Images
import { ExpandMore as ExpandMoreIcon, Update as UpdateIcon } from '@mui/icons-material';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { TFilterProps } from '../types';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
    },
    topFiltersContainer: {
      padding: theme.spacing(2, 0),
      overflow: 'hidden',
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    filtersWrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, calc((100% - 32px)/2))',
      gridTemplateRows: 'repeat(2, 56px)',
      gridColumnGap: theme.spacing(4),
      gridRowGap: theme.spacing(2),
      minHeight: 116,
    },
    formControl: {
      textAlign: 'left',
    },
    dateRoot: {
      gridColumn: '1 / 3',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, calc((100% - 64px)/3))',
      gridTemplateRows: '56px',
      gridColumnGap: theme.spacing(4),
      gridRowGap: theme.spacing(2),
      minHeight: 116,
    },
    periodDate: {
      textAlign: 'left',
    },
    dateForm: {
      margin: theme.spacing(0),
    },
    btn: {
      width: '100%',
    },
  })
);

const Filter: FC<TFilterProps> = ({ statisticsStore, apteka_list, apteka_list_total, cities_list, entities_list, order_statuses_list }) => {
  const { root, paper, topFiltersContainer, btn, filtersWrap, formControl, dateRoot, periodDate, dateForm } = useStyles();
  const { type = 'medicine' } = useParams();
  const theme: Theme = useTheme();

  const { filtersPanelList: filtersList, isExtendedSelection, isShownTable, push } = statisticsStore;

  return (
    <div className={root}>
      <Paper className={paper}>
        <Menu isShownTable={isShownTable} />

        <div className={topFiltersContainer} style={{ height: isExtendedSelection.value ? 500 : 56 }}>
          <div className={filtersWrap}>
            <SelectField
              formStore={filtersList}
              name="cities"
              label="Город:"
              values={cities_list}
              valuesMap={{
                value: 'id',
                label: 'name',
              }}
              defaultSelector="Все"
              preset={{ def: -1 }}
              variant="outlined"
              clName={{ formControl }}
            />
            <SelectField
              formStore={filtersList}
              name="entities"
              label="Юр.лицо:"
              values={entities_list}
              valuesMap={{
                value: 'id',
                label: 'name',
              }}
              defaultSelector="Все"
              preset={{ def: -1 }}
              variant="outlined"
              clName={{ formControl }}
            />
            <>
              <Field formStore={filtersList} name="name_part" label="Название аптеки:" variant="outlined" clName={{ formControl }} />
              <Field formStore={filtersList} name="address_part" label="Адрес аптеки:" variant="outlined" clName={{ formControl }} />
            </>
          </div>
          <TransferList
            formStore={filtersList}
            name="apteka_id_list"
            items={apteka_list}
            preset={{ leftPaginDef: { rowsCount: apteka_list_total } }}
          />
        </div>
        <Grid container>
          <Grid item style={{ flexGrow: 1, display: 'flex', alignItems: 'center', marginRight: 16 }}>
            <Divider style={{ flexGrow: 1 }} />
          </Grid>
          <Grid item>
            <Grid component="label" container alignItems="center" justifyContent="flex-end" spacing={1}>
              <Typography variant="body2">Составить список аптек:</Typography>
              <Grid item>
                <Switch
                  size="small"
                  color="primary"
                  checked={isExtendedSelection.value}
                  onChange={isExtendedSelection.onToggle}
                  name="checked"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={filtersWrap} style={{ margin: theme.spacing(2, 0) }}>
          <DatePeriodPickerField
            formStore={filtersList}
            name="period"
            variant="outlined"
            // clName={{
            //   root: dateRoot,
            //   periodCN: periodDate,
            //   toCN: dateForm,
            //   fromCN: dateForm,
            // }}
          />
          <SelectField
            formStore={filtersList}
            name="type"
            label="Тип отчета:"
            values={[
              {
                value: 0,
                label: 'По аптекам',
              },
              {
                value: 1,
                label: 'По месяцам',
              },
            ]}
            preset={{ def: 0 }}
            variant="outlined"
            clName={{ formControl }}
          />
          {type === 'orders' && (
            <SelectField
              formStore={filtersList}
              name="orderStatus"
              label="Статус заказа:"
              values={order_statuses_list}
              valuesMap={{
                value: 'id',
                label: 'name',
              }}
              selfRemoved
              variant="outlined"
              multiple
              clName={{ formControl }}
            />
          )}
        </div>
        <Button className={btn} variant="text" onClick={push}>
          {isShownTable.value ? 'Обновить' : 'Сформировать'}
          {isShownTable.value ? <UpdateIcon /> : <ExpandMoreIcon />}
        </Button>
      </Paper>
    </div>
  );
};

export default Filter;
