import React from 'react';

// Components
import TrainingUI from './TrainingUI';

// Types
import { FC } from 'react';
import { ITrainingQueryDataHandlerProps } from './types';

const TrainingQueryDataHandler: FC<ITrainingQueryDataHandlerProps> = ({ data, loading }) => {
  const { instructions_list, tags_list } = data;

  return <TrainingUI {...{ instructions_list, tags_list, loading }} />;
};

export default TrainingQueryDataHandler;
