import React from 'react';

// Components
import { Grid } from '@mui/material';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

// Icons & Images
import { Close as CloseIcon } from '@mui/icons-material';

// Types
import { FC } from 'react';
import { IActionButtonsWrapperProps } from './types';
import { IActionPrimaryButton } from '../types';
import TableStore from '../../../../stores/classes/components/TableStore';

const ActionButtonsWrapper: FC<IActionButtonsWrapperProps> = ({ children, childrensLength }) => {
  return (
    // @ts-ignore
    <Grid container justifyContent={childrensLength > 1 ? 'center' : 'flex-start'} gap={2} {...(children.length && { padding: 2 })}>
      {children}
    </Grid>
  );
};

// const ActionButtonsWrapper = styled(Grid, {
//   shouldForwardProp: (prop) => prop !== 'childrensLength'
// })<IActionButtonsWrapperProps>(({theme, childrensLength}) => ({
//   overflowY: 'auto',
//   '&::-webkit-scrollbar': {
//     width: 0,
//     height: 5,
//   },
//   '&::-webkit-scrollbar-thumb': {
//     height: '10px',
//     background: theme.palette.primary.light,
//     outline: '1px solid slategrey',
//     borderRadius: '2px',
//   },
// }))

// const ActionButtonsWrapper = (buttons: ReactNode[]) => () => {
//   const { root } = useStyles();

//   return (
//     <div
//       className={root}
//       style={{
//         justifyContent: buttons.length > 1 ? 'center' : 'flex-start',
//       }}
//     >
//       {buttons}
//     </div>
//   );
// };

export const useActionPanel = (tableStore: TableStore, actionButtons: IActionPrimaryButton[]) => {
  const { currentButton } = tableStore;

  const onClickHandler = (num: number) => {
    currentButton.updateValue(num);
  };

  const primaryButtons = actionButtons.map(({ action, done, ...props }, i) => {
    if (typeof action !== 'function')
      action = () => {
        if (done && typeof done === 'function') done();
        onClickHandler(i);
      };
    return <PrimaryButton key={i} {...props} action={action} />;
  });

  const { action: forkButtons, close } = actionButtons[currentButton.value !== null ? currentButton.value : -1] || {};

  const closeHandler = () => {
    if (close && typeof close === 'function') close();
    currentButton.updateValue(null);
  };

  const secondaryButtons =
    (Array.isArray(forkButtons) && [
      ...forkButtons.map((props, i) => {
        return <SecondaryButton key={i} {...props} />;
      }),
      <SecondaryButton
        {...{
          icon: CloseIcon,
          text: 'Закрыть',
          color: 'inherit',
          action: closeHandler,
        }}
      />,
    ]) ||
    [];

  return [
    <ActionButtonsWrapper children={primaryButtons} childrensLength={primaryButtons.length} />,
    <ActionButtonsWrapper children={secondaryButtons} childrensLength={secondaryButtons.length} />,
    primaryButtons,
    secondaryButtons,
  ] as [JSX.Element, JSX.Element, JSX.Element[], JSX.Element[]];
};
