import React from 'react';

// Components
import { Select, InputBase, MenuItem, Typography } from '@mui/material';

// Utils
import { createStyles, withStyles } from '@mui/styles';
import { sortElements } from '../../../utils/other';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IPaginationPerPageSelectorProps } from './types';

// Styles
const PerPageSelector = withStyles((theme: Theme) =>
  createStyles({
    root: { marginRight: theme.spacing(2) },
    input: {
      fontSize: 14,
      padding: theme.spacing(0.5, 3, 0.5, 1),
      minWidth: 24,
    },
  })
)(InputBase);

const PaginationPerPageSelector: FC<IPaginationPerPageSelectorProps> = ({ variants, perPage, perPageSelectHandler }) => {
  if (!variants.includes(perPage)) {
    variants.push(perPage);
    variants = sortElements(variants);
  }

  return (
    <>
      <Typography variant="body2">Кол-во строк на стр.:</Typography>
      <Select value={perPage} onChange={perPageSelectHandler} input={<PerPageSelector />}>
        {variants.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default PaginationPerPageSelector;
