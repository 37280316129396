import React from 'react';

// Hooks
import { useParams } from 'react-router';

// Components
import Query from '../../client/Query';
import TrainingDetailsQueryDataHandler from './TrainingDetailsQueryDataHandler';

// Types
import { FC } from 'react';

// Queries
import { loader } from 'graphql.macro';
const getInstruction = loader('./gqlQueries/getInstruction.gql');

const TrainingDetailsQuery: FC = () => {
  const { id } = useParams();

  return <Query query={getInstruction} variables={{ id: +id! }} children={TrainingDetailsQueryDataHandler} />;
};

export default TrainingDetailsQuery;
