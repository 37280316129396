import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import AptekaListQueryDataHandler from './AptekaListQueryDataHandler';

// Types
import { FC } from 'react';

// Queries
import { loader } from 'graphql.macro';
const getAptekaList = loader('./gqlQueries/getAptekaList.gql');

const OrderListQuery: FC = () => {
  return <Query query={getAptekaList} children={LoginErrorRedirector(AptekaListQueryDataHandler)} />;
};

export default OrderListQuery;
