import { styled } from '@mui/material/styles';

// Componets
import { Tooltip } from '@mui/material';

// Types
import { TooltipProps } from '@mui/material';

export const StyledTooltip = styled(Tooltip)<TooltipProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  maxWidth: 247,
  padding: theme.spacing(2),
}));
