import React from 'react';
import { Redirect } from 'react-router-dom';

type TProps = {
  error: any;
  options: any;
};

const LoginErrorRedirector = (Component: any) => ({ error, ...options }: TProps) => {
  if (error) {
    if (JSON.parse(JSON.stringify(error.networkError))?.statusCode === 401) {
      localStorage.setItem('token', '');
      return <Redirect to="/login" />;
    }
  }

  return <Component {...options} error={error} />;
};

export default LoginErrorRedirector;
