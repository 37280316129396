import React from 'react';
// MaterialUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';

// Pages
import MenuBar from './common/components/MenuBar/MenuBarQuery';
import Login from './pages/Login';
import Landing from './pages/Landing';
import MainRoot from './pages/MainRoot';
import FeedbackList from './pages/FeedbackList';
import FeedbackDetails from './pages/FeedbackDetails';
import OrderList from './pages/OrderList';
import OrderDetails from './pages/OrderDetails';
import AptekaList from './pages/AptekaList';
import AptekaDetails from './pages/AptekaDetails';
import Statistics from './pages/Statistics';
import Training from './pages/Training';
import TrainingDetails from './pages/TrainingDetails';
import ZeroTest from './pages/ZeroTest';
// Нотификация
import NotifierProvider from './Notifier';
// Modal
import ModalWindow from './common/components/Modal/ModalWindow';

import ClientProvider from './client/ClientProvider';

// ФЭЙКОВАЯ БАЗА ДАННЫХ <-Удалить начиная с этого коментария
import DBProvider from './pages/Statistics/fakeDB/DBProvider';
// КОНЕЦ <- Удалить до этого коментария

export const history = createBrowserHistory();

const mainTheme = createTheme({
  palette: {
    primary: {
      light: '#6dc7de',
      main: '#49BAD6',
      dark: '#338295',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e8ffb0',
      main: '#b5d380',
      dark: '#84a252',
      contrastText: '#fff',
    },
    error: {
      light: '#ff9696',
      main: '#FF7C7C',
      dark: '#b25656',
      contrastText: '#fff',
    },
    info: {
      light: '#6dc7de',
      main: '#49BAD6',
      dark: '#338295',
      contrastText: '#fff',
    },
  },
  // props: {
  //   MuiButton: {
  //     size: 'large',
  //     variant: 'contained',
  //     color: 'primary',
  //     style: {
  //       color: 'white',
  //       fontWeight: 'bold',
  //     },
  //   },
  // },
  // overrides: {
  //   MuiInputBase: {
  //     input: {
  //       '&::placeholder': {
  //         fontSize: 14,
  //       },
  //     },
  //   },
  // },
});

const App = () => (
  <div className="App">
    <DBProvider>
      <ThemeProvider theme={mainTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <ClientProvider>
            <NotifierProvider>
              <Router history={history}>
                <Route path="/" exact component={Landing} />

                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/main/:page?">
                  <MenuBar />
                  <main className="Main">
                    <Switch>
                      <Route path="/main/feedback_list/:page/:filters?" component={FeedbackList} />
                      <Route path="/main/feedback_details/:id" component={FeedbackDetails} />
                      <Route path="/main/order_list/:page/:filters?" component={OrderList} />
                      <Route path="/main/order_details/:id" component={OrderDetails} />
                      <Route path="/main/apteka_list/:page/:filters?" component={AptekaList} />
                      <Route path="/main/apteka_details/:id" component={AptekaDetails} />
                      <Route path="/main/statistics/:type?" component={Statistics} />
                      <Route path="/main/training/:tags?" component={Training} />
                      <Route path="/main/training_details/:id/:content_type?" component={TrainingDetails} />

                      <Route path="/main/zero_test" component={ZeroTest} />

                      <Route path="/main" component={MainRoot} />
                    </Switch>
                  </main>
                </Route>
              </Router>

              <ModalWindow />
            </NotifierProvider>
          </ClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </DBProvider>
  </div>
);

export default App;
