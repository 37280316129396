import LoginStore from './classes/LoginStore';
import AuthStore from './classes/AuthStore';
import OrderDetailsStore from './classes/OrderDetailsStore';
import AptekaDetailsStore from './classes/AptekaDetailsStore';
import StatisticsStore from './classes/StatisticsStore';
import ModalStore from './classes/components/ModalStore';
import TableStore from './classes/components/TableStore';
import OrderListTableStore from './classes/OrderListTableStore';
import FeedbackDetailsStore from './classes/FeedbackDetailsStore';
import TrainingDetailsStore from './classes/TrainingDetailsStore';
import FavicoStore from './classes/components/FavicoStore';

export const loginStore = new LoginStore();
export const authStore = new AuthStore();
export const aptekaDetailsStore = new AptekaDetailsStore();
export const orderDetailsStore = new OrderDetailsStore();
export const statisticsStore = new StatisticsStore();
export const modalStore = new ModalStore();
export const orderListTableStore = new OrderListTableStore();
export const aptekaListTableStore = new TableStore();
export const feedbackListTableStore = new TableStore();
export const feedbackDetailsStore = new FeedbackDetailsStore();
export const trainingDetailsStore = new TrainingDetailsStore();
export const favicoStore = new FavicoStore();

export type TStore = {
  loginStore: LoginStore;
  authStore: AuthStore;
  aptekaDetailsStore: AptekaDetailsStore;
  orderDetailsStore: OrderDetailsStore;
  statisticsStore: StatisticsStore;
  modalStore: ModalStore;
  orderListTableStore: TableStore;
  aptekaListTableStore: TableStore;
  feedbackListTableStore: TableStore;
  feedbackDetailsStore: FeedbackDetailsStore;
  trainingDetailsStore: TrainingDetailsStore;
  favicoStore: FavicoStore;
};

const Store: TStore = {
  loginStore,
  authStore,
  aptekaDetailsStore,
  orderDetailsStore,
  modalStore,
  statisticsStore,
  orderListTableStore,
  aptekaListTableStore,
  feedbackListTableStore,
  feedbackDetailsStore,
  trainingDetailsStore,
  favicoStore,
};

export default Store;
