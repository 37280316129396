import { Centrifuge } from 'centrifuge';

const centrifuge = new Centrifuge('wss://api.renewal.ru/connection/websocket');
// ws://192.168.123.204:8040/
centrifuge.on('connecting', (ctx) => console.info('connecting', ctx));

centrifuge.on('disconnected', (ctx) => console.error('disconnected', ctx));

export const centrifugeAuth = (token: string) => {
  centrifuge.setToken(token);
  centrifuge.connect();
};

export const centrifugeSubscribe = (
  websocket_channel_token: string,
  websocket_channel: string | number,
  subscribeFn: (...args: any[]) => void
) => {
  console.info(`Try subscribe to ${websocket_channel} channel`);
  const sub = centrifuge.newSubscription(String(websocket_channel), { token: websocket_channel_token });

  sub.on('publication', subscribeFn);

  sub.subscribe();
};

export default centrifuge;
