import React from 'react';

// Types
import { FC, ReactText } from 'react';
import { IContentProps } from '../types';

const AddPharmacistsAuthDataDialog: FC<IContentProps<ReactText[]>> = ({ values }) => {
  return (
    <>
      Для активации аптек(и) в Программе лояльности с № id аптеки {values.join(', ')} необходимо заполнить обязательные поля
      (авторизационные данные, название аптеки) в карточке аптеки.
    </>
  );
};

export default AddPharmacistsAuthDataDialog;
