import React from 'react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const OrderNotFoundDialog: FC<IContentProps<never, any>> = ({ yarnValues }) => {
  const { searchType, searchValue } = yarnValues;
  return (
    <>{`Заказ со значениями ${searchValue} в номере ${
      searchType === 'phones' ? 'телефона' : 'заказа'
    } не найден. Пожалуйста, проверьте правильно ли Вы указали номер ${searchType === 'phones' ? 'телефона' : 'заказа'}.`}</>
  );
};

export default OrderNotFoundDialog;
