import React from 'react';
import { useHistory } from 'react-router';

const RenewalLogoHead = () => {
  const history = useHistory();
  return (
    <a onClick={() => history.push('/')} href="#" title="RENEWAL" style={{ display: 'block', textAlign: 'center' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="6300 7200 4198.052 5940.776"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="84"
        height="60"
      >
        <defs>
          <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="6985.55" y1="8464.67" x2="6985.55" y2="10310.47">
            <stop offset="0" stopOpacity={1} stopColor="#D2ECFB" />
            <stop offset="0.239216" stopOpacity={1} stopColor="#69C6EF" />
            <stop offset="1" stopOpacity={1} stopColor="#00A0E3" />
          </linearGradient>
          <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1="9891.82" y1="8464.67" x2="9891.82" y2="10310.47">
            <stop offset="0" stopOpacity={1} stopColor="#E2ECC6" />
            <stop offset="0.239216" stopOpacity={1} stopColor="#BCD779" />
            <stop offset="1" stopOpacity={1} stopColor="#96C22B" />
          </linearGradient>
        </defs>
        <g id="Слой_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <g id="_802188565840">
            <polygon
              fill="#fff"
              points="7093.93,11148.95 7093.93,11009.97 6578.89,11009.97 6578.89,11849.36 7093.93,11849.36 7093.93,11710.38 6735.86,11710.38 6735.86,11490.56 7064.41,11490.56 7064.41,11351.66 6735.86,11351.66 6735.86,11148.95 "
            />
            <polygon
              fill="#fff"
              points="10905.02,11710.38 10905.02,11009.97 10748.2,11009.97 10748.2,11849.36 11235.93,11849.36 11235.93,11710.38 "
            />
            <polygon
              fill="#fff"
              points="8651.38,11148.95 8651.38,11009.97 8136.49,11009.97 8136.49,11849.36 8651.38,11849.36 8651.38,11710.38 8293.13,11710.38 8293.13,11490.56 8621.86,11490.56 8621.86,11351.66 8293.13,11351.66 8293.13,11148.95 "
            />
            <polygon
              fill="#fff"
              points="7793.01,11009.97 7793.01,11644.3 7473.09,11009.97 7263.16,11009.97 7263.16,11849.36 7419.82,11849.36 7419.82,11214.12 7742.42,11849.36 7949.8,11849.36 7949.8,11009.97 "
            />
            <path
              fill="#fff"
              d="M10479.98 11849.36l163.1 0 -263.91 -839.39 -220.37 0 -263.92 839.39 162.92 0 50.94 -171.12 320.46 0 50.78 171.12zm-329.83 -310.1l0 0 118.83 -400.21 119 400.21 -237.83 0z"
            />
            <polygon
              fill="#fff"
              points="9740.96,11009.97 9579.12,11669.96 9429.32,11009.97 9219.22,11009.97 9069.24,11669.96 8907.4,11009.97 8750.79,11009.97 8959.26,11849.36 9173.68,11849.36 9324.17,11170.82 9474.86,11849.36 9689.27,11849.36 9897.76,11009.97 "
            />
            <path
              fill="#fff"
              d="M6259.3 11849.36l194.46 0 -210.99 -297.58c66.94,-22.79 119.16,-80.03 126.17,-185.1l0 -134.01c-3.39,-158.79 -78.13,-220.74 -254.91,-222.43l0 -0.27 -197.66 0 -157.02 0 0 839.39 157.02 0 0 -276.42 152.48 0 190.45 276.42zm-342.93 -700.41l0 0 190.1 0c72.35,0 105.67,23.41 105.67,95.06l0 95.05c0,71.47 -33.32,94.88 -105.67,94.88l-190.1 0 0 -284.99z"
            />
            <path
              fill="url(#id0)"
              d="M8497.09 8466.84c-14.92,-2.17 -34.01,-2.17 -34.01,-2.17l-31.48 0 -2034.58 0c-510.02,0 -923.01,413.25 -923.01,922.84 0,509.96 412.99,922.95 923.01,922.95l1558.89 0 541.18 -1843.62z"
            />
            <path
              fill="url(#id1)"
              d="M10472.43 8464.67l-1542.17 0 -541.89 1845.79 2084.06 0c509.66,0 922.83,-412.99 922.83,-922.95 0,-509.59 -413.17,-922.84 -922.83,-922.84z"
            />
            <path
              fill="#F2F6E3"
              d="M10512.92 8673.52l-1643.85 0 -163.13 555.21 2018.52 0c22.13,0 43.39,0.55 64.08,1.44l5.06 0c181.45,0 343.47,53.3 474.38,141.69 -27.2,-373.38 -358.09,-698.34 -755.06,-698.34z"
            />
            <path
              fill="#EAF6FD"
              d="M8436.43 8673.52l-2054.86 0c-396.98,0 -728.06,324.96 -755.08,698.34 130.73,-88.39 292.56,-141.69 474.22,-141.69l5.03 0c20.88,-0.89 41.95,-1.44 64.29,-1.44l2103.48 0 162.92 -555.21z"
            />
            <path
              fill="#5FC6F1"
              d="M8059.98 9955.97l-1922.54 0c-126.57,0 -247.17,-24.12 -356.47,-66.42 114.5,148.78 335.42,296.59 613.18,296.59l1598.33 0 67.5 -230.17z"
            />
            <path
              fill="#C5D985"
              d="M10757.03 9955.97l-2264.61 0 -67.52 230.17 2075.4 0c277.62,0 498.68,-147.81 613.36,-296.59 -109.44,42.3 -230.43,66.42 -356.63,66.42z"
            />
          </g>
        </g>
      </svg>
    </a>
  );
};

export default RenewalLogoHead;
