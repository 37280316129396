import React, { Component, createRef } from 'react';

interface IImageSliderProps {
  images: string[];
  aspectRatio?: number;
}

interface IImageSliderState {
  currentSlide: number;
  _lastInd: number;
  _width: number;
}

export default class ImageSlider extends Component<IImageSliderProps, IImageSliderState> {
  constructor(props: IImageSliderProps) {
    super(props);
    this.state = {
      currentSlide: 0,
      _lastInd: props.images.length - 1,
      _width: 0,
    };
  }

  _setWripperSize = () => {
    const { _width } = this.state;
    const width = this.wrapper.current?.offsetWidth || 0;
    if (width !== _width) this.setState({ _width: width });
  };

  wrapper = createRef<HTMLDivElement>();

  componentDidMount() {
    this._setWripperSize();

    window.addEventListener('resize', this._setWripperSize);
  }

  componentDidUpdate() {
    this._setWripperSize();
  }

  get nexSlide() {
    const { currentSlide, _lastInd } = this.state;

    return currentSlide === _lastInd ? 0 : currentSlide + 1;
  }

  get previousSlide() {
    const { currentSlide, _lastInd } = this.state;

    return currentSlide === 0 ? _lastInd : currentSlide - 1;
  }

  private _setNextSlide = () => this.setState({ currentSlide: this.nexSlide });
  private _setPreviousSlide = () => this.setState({ currentSlide: this.previousSlide });

  toNextSlide = () => {
    const { _width } = this.state;
    const childrens = Array.from(this.wrapper.current?.children || []);
    childrens.forEach((image, i) => {
      // @ts-ignore
      image.style.transition = '1s ease';
      if (i === childrens.length - 1)
        image.addEventListener('transitionend', this._setNextSlide, { once: true });
      // @ts-ignore
      image.style.transform = `translateX(${(i - 2) * _width}px)`;
    });
  };

  toPreviousSlide = () => {
    const { _width } = this.state;
    const childrens = Array.from(this.wrapper.current?.children || []);
    childrens.forEach((image, i) => {
      // @ts-ignore
      image.style.transition = '1s ease';
      if (i === 0) image.addEventListener('transitionend', this._setPreviousSlide, { once: true });
      // @ts-ignore
      image.style.transform = `translateX(${i * _width}px)`;
    });
  };

  render() {
    const { images, aspectRatio = 4 / 3 } = this.props;
    const { currentSlide, _width } = this.state;

    const slides = [images[this.previousSlide], images[currentSlide], images[this.nexSlide]];

    return (
      <div
        ref={this.wrapper}
        style={{
          position: 'relative',
          overflow: 'hidden',
          paddingTop: `${100 / aspectRatio}%`,
          //   height: _width / aspectRatio,
        }}
      >
        {slides.map((src, i) => (
          <div
            key={src + i}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              transform: `translateX(${(i - 1) * _width}px)`,
            }}
          >
            <img
              src={src}
              style={{
                objectFit: 'contain',
                height: '100%',
              }}
            />
          </div>
        ))}
      </div>
    );
  }
}
