import React from 'react';

// Components
import AddFileButton from './AddFileButton';
import UploadFileList from './UploadFileList';

// Hooks
import { useState } from 'react';

// Utils
import filesize from 'filesize';
import { getType, extFromType, extFileValidateToArr } from './utils';

// Types
import { ChangeEvent } from 'react';
import { TReturn, TAddItemInListError } from './types';

const useUploadFile = (
  filesCountValidate = 10,
  extFileValidate = {
    video: ['mp4'],
    jmage: ['bmp', 'gif', 'png', 'jpeg'],
  },
  sizeFileValidate = { video: 20971520, image: 5242880 }
) => {
  const [itemList, setItemList] = useState<(File | never)[]>([]);
  const [addItemInListError, setAddItemInListError] = useState<TAddItemInListError | null>(null);

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setAddItemInListError(null);
    const { files } = target;

    if (itemList.length >= filesCountValidate) {
      setAddItemInListError({
        type: 'filesCount',
        message: `Возможно добавлять не более ${filesCountValidate} файла(ов) в одном сообщении.`,
      });
      return;
    }
    if (files) {
      const extFileValidateArr = extFileValidateToArr(extFileValidate);
      Array.from(files).forEach((file) => {
        // Валидация
        if (!extFileValidateArr.includes(extFromType(file.type) || '')) {
          setAddItemInListError({
            type: 'fileExt',
            message: `Возможно добавить файлы только следующих типов: ${extFileValidateArr.map((type) => '.' + type).join(', ')}`,
          });
          return;
        }

        // @ts-ignore
        if (file.size > sizeFileValidate[getType(file.type) || '']) {
          const size = filesize.partial({ base: 2, standard: 'jedec' });
          setAddItemInListError({
            type: 'fileSize',
            message: `Размер файла должен быть ${size(sizeFileValidate['video'])} для видео и  ${size(
              sizeFileValidate['image']
            )} для изображений.`,
          });

          return;
        }

        // Присвоение
        setItemList([...itemList, file]);
      });
      target.value = '';
    }
  };

  const clearItemList = () => setItemList([]);

  const removeItemFromList = (file: File) => setItemList(itemList.filter((item) => item != file));

  return [itemList, onChange, clearItemList, removeItemFromList, addItemInListError] as TReturn;
};

export { AddFileButton, UploadFileList, useUploadFile };
