import React from 'react';

// Components
import { Input, Button, Alert, Card, CardContent, CardActions, CardHeader, FormControl, FormHelperText } from '@mui/material';
import { Field } from '../../../common/components/FormElements';
import { TFieldProps } from '../../../common/components/FormElements/types';

// Hooks
import { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { useAlert } from '../../../Notifier';

// Utils
import { styled } from '@mui/material/styles';
import StoreInjector from '../../../stores/StoreInjector';

// Types
import { FC } from 'react';
import { ButtonProps, CardProps } from '@mui/material';
import { TGiveOutOrderCardProps } from '../types';

// Queries
import { loader } from 'graphql.macro';
const issueOrder = loader('../gqlQueries/issueOrder.gql');

const StyledGiveOutOrderCard = styled(Card)<CardProps & { disabled?: boolean }>(({ theme, disabled }) => ({
  width: '100%',
  textAlign: 'center',
  backgroundColor: disabled ? theme.palette.grey[400] : theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledField = styled(Field)<TFieldProps>(({ theme }) => ({
  textAlign: 'center',
  '& .MuiInput-underline:before': {
    borderBottomColor: theme.palette.common.white,
  },
  '& .MuiInput-root': {
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottomColor: theme.palette.common.white,
    },
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.common.white,
  },
  '& .MuiInput-input': {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
}));

const StyledIssueBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const GiveOutOrderCard: FC<TGiveOutOrderCardProps> = ({ orderDetailsStore, id, status, disabled, refetch }) => {
  const history = useHistory();
  const alert = useAlert();

  const { giveOutOrderCard } = orderDetailsStore;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current && inputRef.current.setSelectionRange(orderDetailsStore.code.value.length, orderDetailsStore.code.value.length);
  }, [orderDetailsStore.code.value]);

  // выдача заказа
  const [issueAnOrder] = useMutation(issueOrder, {
    onCompleted: () => {
      alert.success('Заказ выдан.', 'Уведомление отправлено пользователю.');
      refetch();
    },
    onError: (error) => {
      if (error) {
        if (error.networkError && JSON.parse(JSON.stringify(error.networkError)).statusCode === 401) {
          localStorage.setItem('token', '');
          history.push('/login');
          alert.error('Ошибка.', 'Ошибка авторизации.');
        } else orderDetailsStore.errorMessage.updateValue('Вы ввели неправильный код подтверждения. Пожалуйста, попробуйте еще раз');
      }
    },
  });

  const handleSetCode = (value: string) => {
    const val = Array.from(value)
      .filter((letter) => letter !== '*')
      .join('');

    if (val.length <= 4) orderDetailsStore.code.updateValue(val);
  };

  return (
    <StyledGiveOutOrderCard sx={{ justifySelf: 'right' }} disabled={false}>
      <CardHeader title="Введите код подтверждения" />
      <CardContent>
        {status?.innerName === 'accepted' && (
          <Alert variant="filled" severity="info" sx={{ color: 'inherit', backgroundColor: 'inherit', fontWeight: 400 }}>
            Для выдачи заказа отправьте уведомление о готовности или укажите причину, по которой вы не можете выполнить заказ
          </Alert>
        )}
        {/* <StyledField name="code" formStore={giveOutOrderCard} variant="standard" disabled={disabled} /> */}
        <FormControl fullWidth>
          <Input
            disabled={disabled}
            error={Boolean(orderDetailsStore.errorMessage.value)}
            sx={{
              marginTop: 1,
              marginBottom: 1,
              '&:before': {
                borderColor: 'common.white',
              },
              '&:after': {
                borderColor: 'common.white',
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottomColor: 'common.white',
              },
              '& .MuiInput-input': {
                textAlign: 'center',
                color: 'common.white',
              },
            }}
            autoFocus
            id="input"
            inputRef={inputRef}
            onChange={(e) => {
              console.log(e.target.value);
              if (!new RegExp(/[a-zA-Z]/).test(e.target.value) || e.target.value === '***') {
                if (orderDetailsStore.errorMessage.value) orderDetailsStore.errorMessage.updateValue('');
                handleSetCode(e.target.value);
              }
            }}
            value={orderDetailsStore.code.value + '*'.repeat(4 - orderDetailsStore.code.value.length)}
          />
          <FormHelperText sx={{ '&.Mui-error': { color: 'common.white' } }} error={Boolean(orderDetailsStore.errorMessage.value)}>
            {orderDetailsStore.errorMessage.value}
          </FormHelperText>
        </FormControl>
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'center', padding: '8px 8px 24px' }}>
        <StyledIssueBtn
          disabled={disabled}
          onClick={() => {
            if (orderDetailsStore.code.value.length < 4) {
              orderDetailsStore.errorMessage.updateValue('Введите 4х значный код подтверждения.');
            } else {
              issueAnOrder({
                variables: {
                  input: {
                    order_id: id,
                    code: orderDetailsStore.code.value.toString(),
                  },
                },
              });
            }
          }}
        >
          Выдать
        </StyledIssueBtn>
      </CardActions>
    </StyledGiveOutOrderCard>
  );
};

export default StoreInjector(GiveOutOrderCard, ['orderDetailsStore']);
