import React from 'react';

// Components
import StatisticsFilterQueryDataHandler from './StatisticsFilterQueryDataHandler';

// Hooks
import { useCallback } from 'react';

// Types
import { FC } from 'react';
import TransferListStore from '../../stores/classes/components/TransferListStore';
import { IStatisticsFilterQueryAdditionalProps } from './fakeDB/type';

// Queries
import { getAptekaList } from './fakeDB/queries';

const StatisticsFilterQueryAdditional: FC<IStatisticsFilterQueryAdditionalProps> = ({ statisticsStore, DB, data }) => {
  const { filtersPanelList: filtersList, isExtendedSelection } = statisticsStore;
  // ИМИТАЦИЯ ЗАПРОСА НА СЕРВЕР ДЛЯ ПОЛУЧЕНИЯ СПИСКА АПТЕК
  const { cities, entities, name_part, address_part, apteka_id_list } = filtersList.formValues as {
    cities: number;
    entities: number;
    name_part: string;
    address_part: string;
    apteka_id_list: Array<string | number>;
  };
  const {
    leftPagination: { page, perPage },
  } = (filtersList.get('apteka_id_list') as TransferListStore) || { leftPagination: {} };

  const query = useCallback(
    () =>
      getAptekaList(
        {
          cities: cities >= 0 ? [cities] : undefined,
          entities: entities >= 0 ? [entities] : undefined,
          name_part,
          address_part,
          apteka_id_list,
          page: page?.value,
          per_page: perPage?.value,
        },
        DB
      ),
    [page, perPage, cities, entities, name_part, address_part, apteka_id_list]
  );

  let aptekaListData = isExtendedSelection.value ? query() : undefined;
  // КОНЕЦ

  return <StatisticsFilterQueryDataHandler data={data} aptekaListData={aptekaListData} />;
};

export default StatisticsFilterQueryAdditional;
