import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import AptekaDetailsQueryDataHandler from './AptekaDetailsQueryDataHandler';

// Types
import { FC } from 'react';
import { TAptekaDetailsQueryProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const getApteka = loader('./gqlQueries/getApteka.gql');

const AptekaDetailsQuery: FC<TAptekaDetailsQueryProps> = ({ id }) => {
  return <Query query={getApteka} variables={{ id: +id! }} children={LoginErrorRedirector(AptekaDetailsQueryDataHandler)} />;
};

export default AptekaDetailsQuery;
