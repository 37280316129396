import React from 'react';

// Components
import { Box } from '@mui/material';

// Utils
import { makeStyles, createStyles } from '@mui/styles';

// Types
import { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      background: 'white',
      zIndex: 1600,
    },
  })
);

const Footer: FC = ({ children }) => {
  const { root } = useStyles();

  return <Box className={root}>{children}</Box>;
};

export default Footer;
