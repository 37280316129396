// Types
import { MouseEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';

// Utils
import { makeObservable } from 'mobx';

// Stores
import ValueStore from './ValueStore';

class PaginationStore {
  page: ValueStore<number>;
  perPage: ValueStore<number>;
  rowsCount: ValueStore<number>;
  constructor({ page = 1, perPage = 10, rowsCount = 0 } = {}) {
    this.page = this.pageProxy(new ValueStore(page));
    this.perPage = this.checkCurrentPageProxy(new ValueStore(perPage));
    this.rowsCount = this.checkCurrentPageProxy(new ValueStore(rowsCount));

    makeObservable(this, {});
  }

  // Прокси для ограничение значения текущей страницы при ее обновлении
  pageProxy = (target: ValueStore<number>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply: (target, thisArg, args) => {
        let [value, event] = args;
        if (value <= 0) value = 1;
        if (value > context.countPages) value = context.countPages;
        target.apply(thisArg, [value]);
      },
    });

    return target;
  };

  // Прокси на проверку и изменение текущей страницы в рамках возможных на основании количества строк и
  // количества строк на странице, при изменении общего количества строк
  checkCurrentPageProxy = (target: ValueStore<number>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply: (target, thisArg, args) => {
        target.apply(thisArg, args);

        const countPages = context.countPages;
        if (context.page.value > countPages) context.page.updateValue(countPages);
      },
    });

    return target;
  };

  get countPages() {
    return Math.ceil(this.rowsCount.value / this.perPage.value) || 1;
  }

  get rowStart() {
    return (this.page.value - 1) * this.perPage.value + 1;
  }

  get rowEnd() {
    const num = this.page.value * this.perPage.value;
    const rowsCount = this.rowsCount.value;
    return num <= rowsCount ? num : rowsCount;
  }

  firstButtonClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // Далее игнор, т.к. пока не знаю как провести типизацию, чтобы TS не ругался на второй
    // аргумент проксимизированной функции изначально принимающей один аргумент.
    // @ts-ignore
    this.page.updateValue(0, event);
  };

  backButtonClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // Далее игнор, т.к. пока не знаю как провести типизацию, чтобы TS не ругался на второй
    // аргумент проксимизированной функции изначально принимающей один аргумент.
    // @ts-ignore
    this.page.updateValue(this.page.value - 1, event);
  };

  nextButtonClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // Далее игнор, т.к. пока не знаю как провести типизацию, чтобы TS не ругался на второй
    // аргумент проксимизированной функции изначально принимающей один аргумент.
    // @ts-ignore
    this.page.updateValue(this.page.value + 1, event);
  };

  lastButtonClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // Далее игнор, т.к. пока не знаю как провести типизацию, чтобы TS не ругался на второй
    // аргумент проксимизированной функции изначально принимающей один аргумент.
    // @ts-ignore
    this.page.updateValue(Math.max(1, this.countPages), event);
  };

  perPageSelectHandler = (event: SelectChangeEvent<number>) => {
    this.perPage.updateValue(Number(event.target.value));
  };
}

export default PaginationStore;
