import ValueStore from '../components/ValueStore';

// Utils
import { makeObservable } from 'mobx';

// Types
import { IValueParam } from '../../../common/components/FormElements/types';
import { TAutocompleteFieldStorePreset } from '../../types';

class AutocompleteFieldStore extends ValueStore<string> {
  baseValue: ValueStore<IValueParam | string | null>;
  private mask: string | null;
  constructor(preset?: TAutocompleteFieldStorePreset) {
    super(
      preset?.def ? (typeof preset.def === 'string' ? preset.def : preset.def.value) : preset?.mask ? preset?.mask.replace(/\D/g, '') : ''
    );
    this.baseValue = new ValueStore(preset?.def || null);
    this.mask = preset?.mask || null;

    makeObservable(this, {});
  }

  onBaseChangeHandler = (event: any, value: IValueParam | string | null) => {
    this.baseValue.updateValue(value);
  };

  onChangeHandler = (event: any, value: string, reason: string) => {
    if (this.mask) {
      let i = 0;
      const def = this.mask.replace(/\D/g, '');
      let val = value.replace(/\D/g, '');
      if (def.length >= val.length) val = def;
      this.updateValue(
        this.mask.replace(/./g, (a) => {
          return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
        })
      );
    } else if (reason === 'reset' && !value) {
      // Никаких действий, чтобы работало дефолтное значение при первом рендере компонента. Иначе сбрасывается.
    } else this.updateValue(value);
  };

  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default AutocompleteFieldStore;
