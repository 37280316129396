import React from 'react';

// Components
import { Grid, Divider, Card, CardHeader, CardContent, Button, Typography } from '@mui/material';
import HelpIcon from '../../common/components/Icons/HelpIcon';
import Field from '../../common/components/FormElements/Field';
import RadioSelect from '../../common/components/FormElements/RadioSelect';
import DateField from '../../common/components/FormElements/DateField';
import EditButton from '../../common/components/Buttons/EditButton';
import CloseButton from '../../common/components/Buttons/CloseButton';
import ScheduleWork from '../../common/components/FormElements/ScheduleWork';
import LoadingBlock from '../../common/components/LoadingBlock';
import ButtonBack from '../../common/components/ButtonBack';

// Hooks
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { useAlert } from '../../Notifier';

// Utils
import { makeStyles } from '@mui/styles';
import { isValid } from 'date-fns';
import { getDateTime } from '../../utils/dateTimeUtils';
import { transliterate as tr } from 'transliteration';
import { passValidationError } from '../../utils/stringParse';
import { groupingScheduleIntervals } from '../../utils/other';
import { onErrorHandler } from '../../utils/responseHandler';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IAptekaDetailsUIProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const createPharmacistLogin = loader('./gqlQueries/createPharmacistLogin.gql');
const updatePharmacistLogin = loader('./gqlQueries/updatePharmacistLogin.gql');
const updatePharmacy = loader('./gqlQueries/updatePharmacy.gql');
const updatePharmacyAdditionalContact = loader('./gqlQueries/updatePharmacyAdditionalContact.gql');
const activateLoyaltyProgram = loader('./gqlQueries/activateLoyaltyProgram.gql');
const deactivateLoyaltyProgram = loader('./gqlQueries/deactivateLoyaltyProgram.gql');
const excludePharmacyFromNetwork = loader('./gqlQueries/excludePharmacyFromNetwork.gql');
const confirmPharmacyData = loader('./gqlQueries/confirmPharmacyData.gql');

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  topCard: {
    gridColumn: '1 / 3',
  },
  header: {
    backgroundColor: '#eeeeee',
    textAlign: 'center',
  },
  headerTitle: {
    padding: theme.spacing(2, 0),
  },
  container: {
    width: 1032,
    margin: '0 auto',
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '32px',
    gridRowGap: '32px',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationContainer: {
    padding: theme.spacing(0, 2),
    display: 'flex',
  },
  cell: {
    padding: theme.spacing(3, 2),
    textAlign: 'left',
  },
  btn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
}));

const AptekaDetails: FC<IAptekaDetailsUIProps> = ({
  aptekaDetailsStore,
  modalStore,
  isNewId,
  id,
  pharmacy_name,
  city,
  address,
  pharmacist: { id: pharmacist_id, login } = {},
  pharmacy_additional_contact: { name, surname, patronymic, phone: contact_phone, email } = {},
  contact: { phone } = {},
  is_consists_in_loyalty_program,
  is_belongs_to_network,
  is_checked_by_manager,
  inn,
  pharmacyScheduleIntervals = [],
  loading,
  refetch,
}) => {
  const dayName = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const history = useHistory();
  const alert = useAlert();
  const { navigationContainer, btn, header, headerTitle, ...classes } = useStyles();
  const theme = useTheme();

  const [isEditAptekaCard, setIsEditAptekaCard] = useState(false);
  const [isEditContactsCard, setIsEditContactsCard] = useState(false);
  const [isEditAuthData, setIsEditAuthData] = useState(false);
  const [isEditCheckedByManagerValue, setIsEditCheckedByManagerValue] = useState(false);
  const [isEditNetworkMemberValue, setIsEditNetworkMemberValue] = useState(false);
  const [isEditConsistsInLoyaltyProgramValue, setIsEditConsistsInLoyaltyProgramValue] = useState(false);

  const generLoginBtn = useRef<HTMLButtonElement>(null);
  const generPassBtn = useRef<HTMLButtonElement>(null);

  const [createPharmacistLoginFn] = useMutation(createPharmacistLogin, {
    onCompleted: () => {
      alert.success('Редактирование данных аптеки.', 'Логин и пароль успешно изменены.');
      refetch();
      setIsEditAuthData(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [updatePharmacistLoginFn] = useMutation(updatePharmacistLogin, {
    onCompleted: () => {
      alert.success('Редактирование данных аптеки.', 'Логин и пароль успешно изменены.');
      refetch();
      setIsEditAuthData(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [updatePharmacyFn] = useMutation(updatePharmacy, {
    onCompleted: () => {
      alert.success('Редактирование данных аптеки.', 'Данные успешно сохранены.');
      refetch();
      setIsEditAptekaCard(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [updatePharmacyAdditionalContactFn] = useMutation(updatePharmacyAdditionalContact, {
    onCompleted: () => {
      alert.success('Редактирование данных аптеки.', 'Контактные данные аптеки изменены.');
      refetch();
      setIsEditContactsCard(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [confirmPharmacyDataFn] = useMutation(confirmPharmacyData, {
    onCompleted: (data) => {
      const { id, management } = data?.confirmPharmacyData;
      const { is_checked_by_manager } = management;

      alert.success('Смена статуса аптеки.', `Данные аптеки № ${id} ${is_checked_by_manager ? '' : 'не '}подтверждены.`);

      refetch();
      setIsEditCheckedByManagerValue(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [excludePharmacyFromNetworkFn] = useMutation(excludePharmacyFromNetwork, {
    onCompleted: (data) => {
      const { id, management } = data?.excludePharmacyFromNetwork;
      const { is_belongs_to_network } = management;

      if (!is_belongs_to_network) {
        alert.success('Статус аптеки изменен.', `Аптека № ${id} исключена из аптечной сети.`);
      }
      refetch();
      setIsEditNetworkMemberValue(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [activateLoyaltyProgramFn] = useMutation(activateLoyaltyProgram, {
    onCompleted: (data) => {
      const { id, management } = data?.activateLoyaltyProgram;
      const { is_consists_in_loyalty_program } = management;
      if (is_consists_in_loyalty_program) {
        alert.success('Статус аптеки изменен.', `Аптека № ${id} включена в программу лояльности.`);
      }
      refetch();
      setIsEditConsistsInLoyaltyProgramValue(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const [deactivateLoyaltyProgramFn] = useMutation(deactivateLoyaltyProgram, {
    onCompleted: (data) => {
      const { id, management } = data?.deactivateLoyaltyProgram;

      if (!management.is_consists_in_loyalty_program) {
        alert.success('Статус аптеки изменен.', `Аптека № ${id} исключена из программы лояльности.`);
      }
      refetch();
      setIsEditConsistsInLoyaltyProgramValue(false);
    },
    onError: onErrorHandler(alert.error),
  });

  const { aptekaCardForm, accountSettingsCardForm, contactsCardForm } = aptekaDetailsStore;

  const isPharmacyDonotMeetLoyaltyProgram =
    (!pharmacist_id || !pharmacy_name) && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program && !!is_belongs_to_network;

  useEffect(() => {
    if (isPharmacyDonotMeetLoyaltyProgram) {
      modalStore.openHandler([
        {
          type: 'AddPharmacistAuthDataDialog',
          title: 'Внимание!',
          cancelButton: {
            text: 'Закрыть',
          },
        },
      ])();
    }
  }, [isPharmacyDonotMeetLoyaltyProgram]);

  const setColorErrStyle = (bool: boolean) => (bool ? { style: { color: theme.palette.error.main } } : {});

  useEffect(() => {
    const pass = accountSettingsCardForm.value.get('password');
    if (pass) {
      if (!pass.isError.value && !pharmacist_id && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program)
        pass.isError.updateValue(true);
      else pass.isError.updateValue(false);
    }
  }, [accountSettingsCardForm.value.get('password'), pharmacist_id, accountSettingsCardForm.formValues.is_consists_in_loyalty_program]);

  if (loading) return <LoadingBlock />;

  return (
    <>
      <div className={navigationContainer}>
        <ButtonBack />
      </div>
      <div className={classes.container}>
        <Card className={classes.topCard} style={{ overflow: 'unset' }}>
          <CardHeader
            title="Данные аптеки"
            className={header}
            classes={{
              title: headerTitle,
            }}
            action={
              isEditAptekaCard ? (
                <CloseButton onClick={() => setIsEditAptekaCard(false)} />
              ) : (
                <EditButton onClick={() => setIsEditAptekaCard(true)} disabled={!is_belongs_to_network} />
              )
            }
          />

          <CardContent>
            {/* <Grid container>
                  <Grid item xs={3} className={classes.cell}>
                    Юридическое лицо
                  </Grid>
                  <Grid item xs={9} className={classes.cell}>
                    <Typography></Typography>
                  </Grid>
                </Grid> */}
            <Grid container>
              <Grid
                item
                xs={3}
                className={classes.cell}
                {...setColorErrStyle(!pharmacy_name && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program)}
              >
                Название аптеки
                <HelpIcon title="Укажите название в соответствии с вывеской аптеки, его будет видеть покупатель в мобильном приложении при оформлении заказа." />
              </Grid>
              <Grid item xs={9} className={classes.cell}>
                {isEditAptekaCard ? (
                  <Field
                    formStore={aptekaCardForm}
                    name="pharmacy_name"
                    preset={{ def: pharmacy_name }}
                    placeholder="Название аптеки"
                    type="tel"
                    variant="outlined"
                    size="small"
                    selfRemoved
                  />
                ) : (
                  <Typography>{pharmacy_name}</Typography>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={3} className={classes.cell}>
                ИНН
              </Grid>
              <Grid item xs={9} className={classes.cell}>
                <Typography>{inn}</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={3} className={classes.cell}>
                Адрес аптеки
              </Grid>
              <Grid item xs={9} className={classes.cell}>
                <Typography>{`г. ${city.name}, ${address}`}</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={3} className={classes.cell}>
                Контактный телефон
              </Grid>
              <Grid item xs={9} className={classes.cell}>
                {isEditAptekaCard ? (
                  <Field
                    formStore={aptekaCardForm}
                    name="phone"
                    preset={{ def: phone }}
                    placeholder="Контактный телефон"
                    type="tel"
                    variant="outlined"
                    size="small"
                    selfRemoved
                  />
                ) : (
                  <Typography>{phone}</Typography>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={3} className={classes.cell}>
                Время работы
              </Grid>
              <Grid item xs={9} className={classes.cell}>
                {isEditAptekaCard ? (
                  <ScheduleWork formStore={aptekaCardForm} name="scheduleWork" preset={{ def: pharmacyScheduleIntervals }} />
                ) : (
                  !!pharmacyScheduleIntervals?.length && (
                    <div>
                      {groupingScheduleIntervals(pharmacyScheduleIntervals).map(({ period, is_24hours, opens_at, closes_at }, i) => (
                        <Grid key={i} container spacing={1}>
                          <Grid item style={{ width: '70px' }}>
                            <Typography>{dayName[period[0] - 1] + (period[1] ? ' - ' + dayName[period[1] - 1] : '') + ':'}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>{is_24hours ? 'Круглосуточно' : `c ${opens_at} до ${closes_at}`}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  )
                )}
              </Grid>
            </Grid>

            {isEditAptekaCard && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    const { scheduleWork, ...data } = aptekaCardForm.formValues;
                    updatePharmacyFn({ variables: { id, ...data, schedule_intervals: Object.values(scheduleWork) } });
                    console.log('scheduleWork', Object.values(scheduleWork));
                  }}
                >
                  Применить
                </Button>
              </Grid>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="Параметры учётной записи"
            className={header}
            classes={{
              title: headerTitle,
            }}
          />

          <CardContent>
            <Grid container>
              <Grid
                item
                xs={5}
                className={classes.cell}
                {...setColorErrStyle(!pharmacist_id && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program)}
              >
                {isNewId || isEditAuthData ? 'Логин' : 'Авторизационные данные'}
                <HelpIcon title="Администратор аптечной сети самостоятельно информирует сотрудников аптек о параметрах входа (логин, пароль) в систему лояльности." />
              </Grid>
              <Grid item xs={isNewId ? 7 : 6} className={classes.cell}>
                {isEditAuthData ? (
                  <Field
                    formStore={accountSettingsCardForm}
                    name="login"
                    preset={{ def: login }}
                    placeholder="Логин аптеки"
                    variant="outlined"
                    size="small"
                    selfRemoved
                    generator={(isError) => {
                      if (!pharmacy_name) {
                        isError.updateValue('Поле «Название аптеки» не заполнено, просьба внести данные');
                        return;
                      }
                      isError.updateValue(false);
                      return tr(pharmacy_name?.replace(/\s/g, '')) + id;
                    }}
                    keyBtnRef={generLoginBtn}
                  />
                ) : (
                  <Typography>{login}</Typography>
                )}
              </Grid>
              {!isNewId && (
                <Grid item xs={1} className={classes.cell}>
                  {isEditAuthData ? (
                    <CloseButton
                      onClick={() => {
                        const { login: newLogin, password } = accountSettingsCardForm.formValues;
                        if ((!!login && newLogin !== login) || !!password)
                          modalStore.openHandler([
                            {
                              title: 'Вы уверены?',
                              text: 'В форме есть несохраненные данные, вы действительно хотите выйти?',
                              actionButtons: [
                                {
                                  text: 'Да',
                                  action: (v, m, { close }) => {
                                    setIsEditAuthData(false);
                                    close();
                                  },
                                },
                              ],
                            },
                          ])();
                        else setIsEditAuthData(false);
                      }}
                    />
                  ) : (
                    <EditButton onClick={() => setIsEditAuthData(true)} disabled={!is_belongs_to_network} />
                  )}
                </Grid>
              )}
            </Grid>
            {(isNewId || isEditAuthData) && (
              <Grid container>
                <Grid
                  item
                  xs={5}
                  className={classes.cell}
                  {...setColorErrStyle(!pharmacist_id && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program)}
                >
                  Пароль
                  <HelpIcon title="Администратор аптечной сети самостоятельно информирует сотрудников аптек о параметрах входа (логин, пароль) в систему лояльности." />
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  <Field
                    formStore={accountSettingsCardForm}
                    name="password"
                    placeholder="Пароль"
                    variant="outlined"
                    size="small"
                    selfRemoved
                    type="password"
                    generator
                    keyBtnRef={generPassBtn}
                  />
                </Grid>
              </Grid>
            )}
            {isEditAuthData && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  onClick={() => {
                    generLoginBtn.current?.click();
                    generPassBtn.current?.click();
                  }}
                  style={{ color: theme.palette.primary.main }}
                >
                  Сгенерировать логин и пароль
                </Button>
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    const password = accountSettingsCardForm.value.get('password');
                    const passErr = passValidationError((password?.value as string) || '', {
                      upperCase: false,
                    });
                    if (passErr) password?.isError.updateValue(passErr);
                    if (!accountSettingsCardForm.withErrors) {
                      if (login)
                        updatePharmacistLoginFn({
                          variables: {
                            pharmacist_id,
                            ...accountSettingsCardForm.formValues,
                          },
                        });
                      else
                        createPharmacistLoginFn({
                          variables: { pharmacy_id: id, ...accountSettingsCardForm.formValues },
                        });
                    }
                  }}
                  disabled={!is_belongs_to_network}
                >
                  Применить
                </Button>
              </Grid>
            )}
            <Divider />
            <Grid container>
              <Grid
                item
                xs={5}
                className={classes.cell}
                {...setColorErrStyle(!is_checked_by_manager && !!accountSettingsCardForm.formValues.is_consists_in_loyalty_program)}
              >
                Статус проверки
                <HelpIcon title="Администратору аптечной сети необходимо проверить данные аптеки и установить статус 'Подтверждена', только после этого аптека будет активирована для участия в Программе лояльности." />
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                {isEditCheckedByManagerValue ? (
                  <RadioSelect
                    formStore={accountSettingsCardForm}
                    name="is_checked_by_manager"
                    values={[
                      {
                        value: 1,
                        label: 'Подтверждена',
                      },
                      {
                        value: 0,
                        label: 'Не подтверждена',
                      },
                    ]}
                    selfRemoved
                    preset={{ def: is_checked_by_manager }}
                  />
                ) : (
                  <Typography>{is_checked_by_manager ? 'Подтверждена' : 'Не подтверждена'}</Typography>
                )}
              </Grid>
              <Grid item xs={1} className={classes.cell}>
                {isEditCheckedByManagerValue ? (
                  <CloseButton onClick={() => setIsEditCheckedByManagerValue(false)} />
                ) : (
                  <EditButton onClick={() => setIsEditCheckedByManagerValue(true)} disabled={!is_belongs_to_network} />
                )}
              </Grid>
            </Grid>
            {isEditCheckedByManagerValue && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() =>
                    confirmPharmacyDataFn({
                      variables: {
                        id,
                        valid: Boolean(accountSettingsCardForm.formValues.is_checked_by_manager),
                      },
                    })
                  }
                >
                  Применить
                </Button>
              </Grid>
            )}
            <Divider />
            <Grid container>
              <Grid item xs={5} className={classes.cell}>
                Принадлежность к АС
                <HelpIcon title="Действующая - аптека входит в состав аптечной сети, недействующая - аптека не входит в состав аптечной сети." />
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                {isEditNetworkMemberValue ? (
                  <RadioSelect
                    formStore={accountSettingsCardForm}
                    name="is_belongs_to_network"
                    values={[
                      {
                        value: 1,
                        label: 'Действующая',
                      },
                      {
                        value: 0,
                        label: 'Не действующая',
                      },
                    ]}
                    selfRemoved
                    preset={{ def: is_belongs_to_network }}
                  />
                ) : (
                  <Typography>{is_belongs_to_network ? 'Действующая' : 'Не действующая'}</Typography>
                )}
              </Grid>
              <Grid item xs={1} className={classes.cell}>
                {isEditNetworkMemberValue ? (
                  <CloseButton onClick={() => setIsEditNetworkMemberValue(false)} />
                ) : (
                  <EditButton onClick={() => setIsEditNetworkMemberValue(true)} disabled={!is_belongs_to_network} />
                )}
              </Grid>
            </Grid>

            {isEditNetworkMemberValue && !accountSettingsCardForm.get('is_belongs_to_network')?.value && !isNewId && (
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  Дата выхода из АС
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  <DateField formStore={accountSettingsCardForm} name="exclusion_date" variant="outlined" size="small" selfRemoved />
                </Grid>
              </Grid>
            )}

            {isEditNetworkMemberValue && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    let { exclusion_date } = accountSettingsCardForm.formValues;

                    if (exclusion_date && exclusion_date instanceof Date && isValid(exclusion_date)) {
                      exclusion_date = getDateTime(exclusion_date);

                      modalStore.openHandler([
                        {
                          type: 'ExcludeFromLPDialog',
                          title: 'Подтверждение выхода',
                          actionButtons: [
                            {
                              text: 'Да',
                              action: (v, m, { close }) => {
                                excludePharmacyFromNetworkFn({
                                  variables: { id, exclusion_date },
                                });
                                close();
                              },
                            },
                          ],
                        },
                      ])();
                    }
                  }}
                >
                  Применить
                </Button>
              </Grid>
            )}
            <Divider />
            <Grid container>
              <Grid item xs={5} className={classes.cell}>
                Участие в ПЛ{' '}
                <HelpIcon title="Активна - аптека участвует в программе лояльности, неактивна - аптека не участвует в программе лояльности." />
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                {isEditConsistsInLoyaltyProgramValue ? (
                  <RadioSelect
                    formStore={accountSettingsCardForm}
                    name="is_consists_in_loyalty_program"
                    values={[
                      {
                        value: 1,
                        label: 'Активна',
                      },
                      {
                        value: 0,
                        label: 'Не активна',
                      },
                    ]}
                    selfRemoved
                    preset={{ def: is_consists_in_loyalty_program }}
                  />
                ) : (
                  <Typography>{is_consists_in_loyalty_program ? 'Активна' : 'Не активна'}</Typography>
                )}
              </Grid>
              <Grid item xs={1} className={classes.cell}>
                {isEditConsistsInLoyaltyProgramValue ? (
                  <CloseButton onClick={() => setIsEditConsistsInLoyaltyProgramValue(false)} />
                ) : (
                  <EditButton onClick={() => setIsEditConsistsInLoyaltyProgramValue(true)} disabled={!is_belongs_to_network} />
                )}
              </Grid>
            </Grid>

            {isEditConsistsInLoyaltyProgramValue && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    if (accountSettingsCardForm.formValues.is_consists_in_loyalty_program) activateLoyaltyProgramFn({ variables: { id } });
                    else
                      modalStore.openHandler([
                        {
                          type: 'ExcludeFromLPDialog',
                          title: 'Подтверждение выхода',
                          actionButtons: [
                            {
                              text: 'Да',
                              action: (v, m, { close }) => {
                                deactivateLoyaltyProgramFn({ variables: { id } });
                                close();
                              },
                            },
                          ],
                        },
                      ])();
                  }}
                  disabled={isPharmacyDonotMeetLoyaltyProgram}
                >
                  Применить
                </Button>
              </Grid>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="Контакты заведующего аптекой"
            className={header}
            classes={{
              title: headerTitle,
            }}
            action={
              isEditContactsCard ? (
                <CloseButton onClick={() => setIsEditContactsCard(false)} />
              ) : (
                <EditButton onClick={() => setIsEditContactsCard(true)} disabled={!is_belongs_to_network} />
              )
            }
          />

          <CardContent>
            <Grid>
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  Имя
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  {isEditContactsCard ? (
                    <Field
                      formStore={contactsCardForm}
                      name="name"
                      preset={{ def: name }}
                      placeholder="Имя"
                      variant="outlined"
                      size="small"
                      selfRemoved
                    />
                  ) : (
                    <Typography>{name}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  Отчество
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  {isEditContactsCard ? (
                    <Field
                      formStore={contactsCardForm}
                      name="patronymic"
                      preset={{ def: patronymic }}
                      placeholder="Отчество"
                      variant="outlined"
                      size="small"
                      selfRemoved
                    />
                  ) : (
                    <Typography>{patronymic}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  Фамилия
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  {isEditContactsCard ? (
                    <Field
                      formStore={contactsCardForm}
                      name="surname"
                      preset={{ def: surname }}
                      placeholder="Фамилия"
                      variant="outlined"
                      size="small"
                      selfRemoved
                    />
                  ) : (
                    <Typography>{surname}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  Контактный телефон
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  {isEditContactsCard ? (
                    <Field
                      formStore={contactsCardForm}
                      name="contact_phone"
                      preset={{ def: contact_phone }}
                      placeholder="Контактный телефон"
                      type="tel"
                      variant="outlined"
                      size="small"
                      selfRemoved
                    />
                  ) : (
                    <Typography>{contact_phone}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <Grid item xs={5} className={classes.cell}>
                  E-mail
                </Grid>
                <Grid item xs={7} className={classes.cell}>
                  {isEditContactsCard ? (
                    <Field
                      formStore={contactsCardForm}
                      name="email"
                      preset={{ def: email }}
                      placeholder="E-mail"
                      type="email"
                      variant="outlined"
                      size="small"
                      selfRemoved
                    />
                  ) : (
                    <Typography>{email}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {isEditContactsCard && (
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  className={btn}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    updatePharmacyAdditionalContactFn({
                      variables: { id, ...contactsCardForm.formValues },
                    });
                  }}
                >
                  Применить
                </Button>
              </Grid>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default AptekaDetails;
