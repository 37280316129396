import React from 'react';

// Components
import SelectField from './SelectField';
import DateField from './DateField';

// Hooks
import { useEffect } from 'react';

// Types
import { FC } from 'react';
import { IDatePeriodFieldProps } from './types';
import { DatePeriodFieldStore } from '../../../stores/classes/triggers';

const DatePeriodField: FC<IDatePeriodFieldProps> = ({
  formStore,
  name,
  preset,
  variant,
  selfRemoved = false,
  clName: { root, periodCN, toCN, fromCN } = {},
}) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const { fieldsList } = formStore.set(name, DatePeriodFieldStore, preset) as DatePeriodFieldStore;

  const periodProps = {
    formStore: fieldsList,
    name: 'periodType',
    label: 'Период:',
    values: [
      { value: 'select', label: 'Выбрать' },
      { value: 'day', label: 'День' },
      { value: 'week', label: 'Неделя' },
      { value: 'month', label: 'Месяц' },
      { value: '6month', label: 'Пол года' },
      { value: 'year', label: 'Год' },
    ],
    variant,
    clName: { formControl: periodCN },
    preset: { def: 'select' },
  };

  const fromProps = {
    formStore: fieldsList,
    name: 'from',
    label: 'От:',
    variant,
    clName: { formControl: fromCN },
    preset: { def: new Date() },
  };

  const toProps = {
    formStore: fieldsList,
    name: 'to',
    label: 'До:',
    variant,
    clName: { formControl: toCN },
    preset: { def: new Date() },
  };

  return (
    <div className={root}>
      <SelectField {...periodProps} />
      <DateField {...fromProps} />
      <DateField {...toProps} />
    </div>
  );
};

export default DatePeriodField;
