import React from 'react';

// Components
import { Typography } from '@mui/material';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const ExcludeFromLPDialog: FC<IContentProps> = () => {
  return (
    <>
      <Typography>
        В данной аптеке могут быть незавершенные заказы, при смене статуса аптека будет исключена из участия в Программе лояльности, все
        заказы будут отменены, пользователи не смогут оформить заказ в данной аптеке.
      </Typography>
      <Typography>Вы уверены, что хотите изменить статус выбранной аптеки?</Typography>
    </>
  );
};

export default ExcludeFromLPDialog;
