import React from 'react';

// Components
import { Typography, Grid, Divider, Button, Paper } from '@mui/material';
import Field from '../../common/components/FormElements/Field';
import FeedbackResponse from './components/FeedbackResponse';
import ButtonBack from '../../common/components/ButtonBack';
import { AddFileButton, UploadFileList } from '../../common/components/AddFile';
import Status from '../../common/components/Status';

// Hooks
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { useAlert } from '../../Notifier';
import { useUploadFile } from '../../common/components/AddFile';

// Utils
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { onErrorHandler } from '../../utils/responseHandler';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IFeedbackDetailsUIProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const addFeedbackMess = loader('./gqlQueries/addFeedbackMess.gql');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  backButtonContainer: {
    padding: '0 16px',
    textAlign: 'left',
  },
  bigCell: {
    verticalAlign: 'top',
  },
  answerTypography: {
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 10,
  },
  answerBlock: {
    padding: 10,
  },
  answerText: {
    textAlign: 'left',
  },
  //
  cell: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  messagesPanel: {
    flexGrow: 1,
    boxSizing: 'border-box',
    height: 0,
    // scroll
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      height: '10px',
      background: theme.palette.primary.light,
      outline: '1px solid slategrey',
      borderRadius: '2px',
    },
  },
  addMessagePanel: {
    background: theme.palette.grey[50],
    padding: theme.spacing(2),
  },
}));

const FeedbackDetails: FC<IFeedbackDetailsUIProps> = ({ feedback, messages, refetch, feedbackDetailsStore }) => {
  const [itemList, onChange, clearItemList, removeItemFromList, addItemInListError] = useUploadFile();
  const alert = useAlert();
  const { root, cell, messagesPanel, addMessagePanel, backButtonContainer } = useStyles();
  const theme = useTheme();

  const { addMessForm } = feedbackDetailsStore;

  const [addFeedbackMessFn] = useMutation(addFeedbackMess, {
    onCompleted: () => {
      alert.success('Изменения в обращении.', 'Комментарий добавлен пользователем.');
      clearItemList();
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const sendMess = () => {
    const { formValues } = addMessForm;
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        const value = formValues[key];
        addMessForm.get(key)?.isError.updateValue(value === 0 ? false : !value);
      }
    }
    if (!addMessForm.withErrors) {
      addFeedbackMessFn({
        variables: { ...formValues, feedback: feedback.id, files: itemList },
      });
    }
    addMessForm.get('text')?.updateValue('' as never);
  };

  const color = [theme.palette.grey[400], theme.palette.secondary.main, '#1995AD', '#FFC107', '#4A555A', '#EBCDD1', '#DC3545'];

  return (
    <>
      <div className={backButtonContainer}>
        <ButtonBack />
      </div>

      {feedback && (
        <>
          <Typography
            variant="h5"
            style={{
              textAlign: 'center',
            }}
          >
            Обращение №{feedback.id}
          </Typography>

          <Grid container className={root} spacing={3}>
            <Grid xs={6} item>
              <Paper style={{ padding: theme.spacing(2) }} onCompositionStart={(e) => console.log(e)}>
                <Grid container>
                  <Grid item xs={5} className={cell}>
                    Причина обращения
                  </Grid>
                  <Grid item xs={7} className={cell}>
                    {feedback.type_name}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container>
                  <Grid item xs={5} className={cell}>
                    Тема обращения
                  </Grid>
                  <Grid item xs={7} className={cell}>
                    {feedback.subject}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container>
                  <Grid item xs={5} className={cell}>
                    Статус
                  </Grid>
                  <Grid item xs={7} className={clsx(cell)}>
                    <Status color={color[feedback.status]} text={feedback.status_name} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid xs={6} item>
              <Paper style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className={messagesPanel}>
                  {messages?.length ? messages.map((mess, i) => <FeedbackResponse key={i} {...mess} />) : null}
                </div>
                <Grid container className={addMessagePanel}>
                  <Field formStore={addMessForm} name="text" rows={3} variant="outlined" label="Ваш комментарий" selfRemoved={true} />
                  <UploadFileList {...{ itemList, removeItemFromList, addItemInListError }} />
                  <Grid container justifyContent="space-between">
                    <AddFileButton {...{ onChange }} disabled={feedback.status === 6} />
                    <Button onClick={sendMess} disabled={feedback.status === 6}>
                      Отправить
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default FeedbackDetails;
