import React from 'react';

// Components
import { Button, Input, InputAdornment, IconButton, Typography, Card, CardContent, CardActions } from '@mui/material';

// Icons & Images
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo_1 from '../../static/images/logo_1.png';

// Hooks
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
// @ts-ignore
import { useHistory } from 'react-router-dom';

// Uyils
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { ILoginProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const userLogin = loader('./gqlQueries/userLogin.gql');

// Styles
const useStyles = makeStyles({
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '1fr auto 1fr',
    gridAutoFlow: 'column',
    justifyItems: 'center',
    height: '100%',
  },
  imgContainer: {
    margin: '50px',
    height: 'calc(100% - 100px)',
    maxWidth: 'calc(100% * 2 / 3)',
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
  },
  secondContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 400,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    margin: 20,
  },
  input: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Login: FC<ILoginProps> = ({ loginStore, authStore }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('token', '');
    authStore.role.updateValue(null);
  }, []);

  const [login, { loading, error }] = useMutation(userLogin, {
    onCompleted: ({ pharmacyLogin }) => {
      if (pharmacyLogin && pharmacyLogin.token) {
        const { token, role, access_granted, websocket_token, websocket_channel_token, websocket_channel } = pharmacyLogin;
        localStorage.setItem('token', token);
        localStorage.setItem('websocket_data', JSON.stringify({ websocket_token, websocket_channel_token, websocket_channel }));
        authStore.role.updateValue(role);
        authStore.isNotAccessGranted.updateValue(!access_granted);
        localStorage.setItem('access_granted', access_granted);

        history.push('/main');
      }
    },
    onError: (error) => {
      if (error) {
        localStorage.setItem('token', '');

        loginStore.phone.updateValue('');
        loginStore.password.updateValue('');
      }
    },
  });

  return (
    <div className={classes.mainContainer}>
      <div className={classes.imgContainer}>
        <img alt="logo" src={logo_1} className={classes.logo} />
      </div>
      <div className={classes.secondContainer}>
        <Card className={classes.card}>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              login({
                variables: loginStore.fieldsValue,
              });
            }}
          >
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.header}>
                Авторизация
              </Typography>
              {error && <Typography color="error">Неверный логин или пароль</Typography>}
              <Input
                autoFocus
                disabled={loading}
                value={loginStore.phone.value}
                onChange={loginStore.phone.onChangeHandler}
                placeholder="Логин"
                fullWidth
                className={classes.input}
              />
              <Input
                disabled={loading}
                value={loginStore.password.value}
                onChange={loginStore.password.onChangeHandler}
                placeholder="Пароль"
                type={loginStore.showPassword ? 'text' : 'password'}
                fullWidth
                className={classes.input}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={loginStore.toggleShowPassword}>
                      {loginStore.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </CardContent>
            <CardActions className={classes.buttonContainer}>
              <Button disabled={loading} type="submit">
                Войти
              </Button>
            </CardActions>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
