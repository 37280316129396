import React from 'react';

// Components
import DateField from '../../FormElements/DateField';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const AffiliationDialog: FC<IContentProps> = ({ modalStore }) => {
  return (
    <>
      Укажите дату выхода аптек(и) из аптечной сети.
      <DateField formStore={modalStore.formElementsList} name="exclusion_date" selfRemoved={true} variant="outlined" />
    </>
  );
};

export default AffiliationDialog;
