import React from 'react';

// Components
import { TextField, Autocomplete } from '@mui/material';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { TAutocompleteFieldProps } from './types';
import WithFormElementsStore from './WithFormElementsStore';

const AutocompleteField: FC<TAutocompleteFieldProps> = ({
  store,
  name,
  label,
  values,
  variant,
  autoFocus,
  inputRef,
  placeholder,
  ...props
}) => {
  const { value, onChangeHandler, baseValue, onBaseChangeHandler, isError } = store;

  // Очистка ошибки, если значение присутсвует
  useEffect(() => {
    if (value && isError.value) isError.updateValue(false);
  }, [value]);

  return (
    <Autocomplete
      id={String(name)}
      options={values}
      value={baseValue.value}
      onChange={onBaseChangeHandler}
      inputValue={value}
      onInputChange={onChangeHandler}
      // Чтобы текст не выделялся при фокусе
      freeSolo
      noOptionsText="Нет подходящих вариантов"
      {...props}
      renderInput={(params) => <TextField {...params} {...{ variant, label, autoFocus, inputRef, placeholder }} error={!!isError.value} />}
    />
  );
};

export default WithFormElementsStore(observer(AutocompleteField), 'AutocompleteFieldStore');
