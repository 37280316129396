import React from 'react';

// Components
import OrderListTable from './OrderListTableContainer';

// Types
import { FC } from 'react';
import { TOrderListQueryDataHandlerProps } from './types';

const OrderListQueryDataHandler: FC<TOrderListQueryDataHandlerProps> = ({ authStore, data, refetch }) => {
  const rows =
    data?.pharmacy_order_list?.map((order: any) => {
      const { id, user, status, created_at, is_storable, pharmacy } = order;

      const { location, name } = pharmacy;
      const { city: { name: city_name } = { name: '' } } = location || {};

      return {
        id,
        phone: user.phone,
        status: authStore.getStatus(status).innerName,
        date: created_at,
        city: city_name,
        apteka: name,
        optionValues: {
          is_storable,
          id,
        },
      };
    }) || [];

  return <OrderListTable rows={rows} refetch={refetch} />;
};

export default OrderListQueryDataHandler;
