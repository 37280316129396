import { makeObservable } from 'mobx';

import ValueStore from '../ValueStore';
import ToggleStore from '../ToggleStore';
import { FormElementsListStore } from '../../mapped';
// Типизация
import { TModalSequence, IModalSequenceElementParam } from './types';

class ModalStore extends ToggleStore {
  formElementsList: FormElementsListStore = new FormElementsListStore();
  modalSequence: ValueStore<TModalSequence> = new ValueStore([] as TModalSequence);
  currentModal: ValueStore<number> = new ValueStore(0);

  constructor() {
    super();
    makeObservable(this, {});
  }

  setModalParam = <T extends IModalSequenceElementParam, K extends keyof IModalSequenceElementParam>(key: K, value: T[K]) => {
    this.modalSequence.value[this.currentModal.value][key] = value;
  };

  updateInternalValues = (internalValues: IModalSequenceElementParam['_internalValues']) =>
    this.setModalParam('_internalValues', internalValues);

  nextModal = (yarnValues?: any) => {
    let modalNum: number = this.currentModal.value + 1;
    if (this.modalSequence.value.length === modalNum) modalNum = this.modalSequence.value.length - 1;

    if (yarnValues && this.modalSequence.value.length > modalNum) this.modalSequence.value[modalNum].yarnValues = yarnValues;

    this.currentModal.updateValue(modalNum);
  };

  previousModal = () => {
    let value: number = this.currentModal.value ? this.currentModal.value - 1 : 0;
    this.currentModal.updateValue(value);
  };

  setModal = (modalNum: number, yarnValues?: any) => {
    if (yarnValues && this.modalSequence.value.length > modalNum) this.modalSequence.value[modalNum].yarnValues = yarnValues;
    // Вперед присвоение yarnValues, чтобы к моменту срабатывания события изменения номера
    // текущей модалки данный параметр был присвоен секвенсору модального окна, который будет
    // передаваться рендерируемому модальному окну.
    this.currentModal.updateValue(modalNum);
  };

  openHandler = (modalSequence: TModalSequence) => () => {
    this.modalSequence.updateValue(modalSequence);
    this.onOpen();
  };

  onExited = () => {
    this.modalSequence.updateValue([]);
    this.currentModal.updateValue(0);
  };
}

export default ModalStore;
