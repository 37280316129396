import React from 'react';

// Components
import FeedbackQuery from './FeedbackQuery';

// Types
import { FC } from 'react';

const Feedback: FC = () => {
  return <FeedbackQuery />;
};

export default Feedback;
