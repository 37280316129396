import React from 'react';

// Components
import { Button, Typography, Alert } from '@mui/material';
import AptekasListDataFilters from './AptekaListDataFilters';
import TooltipIcon from '../../common/components/Icons/TooltipIcon';
import Footer from '../../common/components/Footer';

// Icons & Images
import { AssignmentTurnedIn as AssignmentTurnedInIcon, AssignmentLate as AssignmentLateIcon } from '@mui/icons-material';

// Hooks
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { useAlert } from '../../Notifier';
import { useActionPanel } from '../../common/components/tables';
import useOutsideScrollbar from '../../common/hooks/useOutsideScrollbar';

// Utils
import { isValid } from 'date-fns';
import { getDateStringWithTime, getDateTime } from '../../utils/dateTimeUtils';
import { onErrorHandler } from '../../utils/responseHandler';

// Types
import { FC, ReactText } from 'react';
import { Theme } from '@mui/material';
import { IAptekaListTableProps } from './types';
import { ITablePreset } from '../../common/components/tables/types';

// Queries
import { loader } from 'graphql.macro';
const confirm = loader('./gqlQueries/confirmPharmaciesList.gql');
const activateLoyalty = loader('./gqlQueries/activatePharmaciesLoyaltyProgram.gql');
const deactivateLoyalty = loader('./gqlQueries/deactivatePharmaciesLoyaltyProgram.gql');
const deletePharmacy = loader('./gqlQueries/excludePharmaciesFromNetwork.gql');
const confirmPharmacies = loader('./gqlQueries/confirmPharmaciesData.gql');
const findListOfAddressesSyncedNewPharmacies = loader('./gqlQueries/findListOfAddressesSyncedNewPharmacies.gql');
const findListOfCityNameSyncedNewPharmacies = loader('./gqlQueries/findListOfCityNameSyncedNewPharmacies.gql');
const findListOfInnSyncedNewPharmacies = loader('./gqlQueries/findListOfInnSyncedNewPharmacies.gql');
const findListOfSyncedNewPharmacies = loader('./gqlQueries/findListOfSyncedNewPharmacies.gql');
const createPharmacyFromSyncedData = loader('./gqlQueries/createPharmacyFromSyncedData.gql');
const listOfPharmaciesForManager = loader('./gqlQueries/listOfPharmaciesForManager.gql');

const AptekaListTable: FC<IAptekaListTableProps> = ({ authStore, aptekaListTableStore, modalStore, rows, refetch }) => {
  const history = useHistory();
  const alert = useAlert();
  const theme: Theme = useTheme();
  const [scrolledContent, handleContentScroll, OutsideScrollbar] = useOutsideScrollbar();

  const getUniqueValuesFromRows = (rows: any, key: string) =>
    rows?.reduce((result: any[], row: any) => {
      let value = row[key];
      if (typeof value === 'number') value = String(value);
      if (value && !result.includes(value)) {
        result.push(value);
      }
      return result;
    }, []);

  const { isNotAccessGranted, isAptekaListInWork, isFirstAuth, role } = authStore;
  const { useTableAlert, currentButton } = aptekaListTableStore;

  const [confirmPharmaciesList, { data }] = useMutation(confirm, {
    onError: onErrorHandler(alert.error),
  });

  useEffect(() => {
    const { is_access } = data?.confirmPharmaciesList || {};

    if (is_access) {
      isNotAccessGranted.updateValue(!is_access);
      localStorage.setItem('access_granted', String(is_access));
      alert.info('Благодарим за актуализацию данных!', 'Статус аптек подтвержден, Вы можете продолжить работу.');

      refetch();
    }
  }, [data]);

  const [activateLoyaltyProgram] = useMutation(activateLoyalty, {
    onCompleted: ({ activatePharmaciesLoyaltyProgram }) => {
      const { success, fail } = activatePharmaciesLoyaltyProgram;

      alert.groupOperations('Включение аптек в программу лояльности.', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/apteka_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [deactivateLoyaltyProgram] = useMutation(deactivateLoyalty, {
    onCompleted: ({ deactivatePharmaciesLoyaltyProgram }: any) => {
      const { success, fail } = deactivatePharmaciesLoyaltyProgram;

      alert.groupOperations('Исключение аптек из программы лояльности.', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/apteka_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [excludePharmaciesFromNetwork] = useMutation(deletePharmacy, {
    onCompleted: ({ excludePharmaciesFromNetwork }) => {
      const { success, fail } = excludePharmaciesFromNetwork;

      alert.groupOperations('Исключение аптек из аптечной сети.', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/apteka_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [confirmPharmaciesData] = useMutation(confirmPharmacies, {
    onCompleted: ({ confirmPharmaciesData }) => {
      const { success, fail } = confirmPharmaciesData;

      alert.groupOperations('Данные аптек подтверждены.', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/apteka_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [noneСonfirmPharmaciesData] = useMutation(confirmPharmacies, {
    onCompleted: ({ confirmPharmaciesData }) => {
      const { success, fail } = confirmPharmaciesData;

      alert.groupOperations('Данные аптек не подтверждены.', '', {
        layoutContent: {
          additionalContentProps: {
            success,
            fail,
            href: '/main/apteka_details/',
            history,
          },
        },
      });
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [createPharmacyFromSyncedDataFn] = useMutation(createPharmacyFromSyncedData, {
    onCompleted: ({ createPharmacyFromSyncedData }) => {
      if (createPharmacyFromSyncedData) alert.success('Успешно.', 'Аптека успешно добавлена.');
      refetch();
    },
    onError: onErrorHandler(alert.error),
  });

  const [showAccessGrantedTableAlert, closeAccessGrantedTableAlert] = useTableAlert({
    severity: 'warning',
    description:
      'Используйте кнопки внизу страницы для актуализации списка аптек. Если список аптек корректный – нажмите кнопку «Подтвердить актуальность списка».  Если список изменился, с помощью кнопок «Изменить участие в ПЛ» и «Изменить принадлежность» можно удалить аптеку из ПЛ и АС. Добавить в список новую аптеку можно через кнопку «Добавить аптеку».',
  });

  useEffect(() => {
    if (isNotAccessGranted.value) showAccessGrantedTableAlert();
    else closeAccessGrantedTableAlert();
  }, [isNotAccessGranted.value]);

  const [showEmptyTableAlert, closeEmptyTableAlert] = useTableAlert({
    severity: 'info',
    variant: 'outlined',
    description: 'Список аптек пуст. Для начала работы загрузите аптеки из CRM-системы.',
  });

  useEffect(() => {
    if (!rows.length) showEmptyTableAlert();
    else closeEmptyTableAlert();
  }, [rows]);

  useEffect(() => {
    if (isNotAccessGranted.value && !isAptekaListInWork.value)
      modalStore.openHandler([
        {
          title: isFirstAuth.value ? 'Добро пожаловать в программу лояльности Renewal!' : 'Проверка списка аптек',
          text: isFirstAuth.value
            ? 'Чтобы начать работу в сервисе, необходимо проверить данные аптек сети, участниц программы лояльности. Для этого заведите аптекам учетные записи (логин/пароль) и установите соответствующие значению статусы: проверки, принадлежности к аптечной сети и участия в программе лояльности.'
            : 'Пожалуйста, подтвердите актуальный список аптек участников программы лояльности, после чего вы сможете продолжить работу в веб-версии.',
          cancelButton: { hidden: true },
          actionButtons: [
            {
              text: 'Продолжить',
              action: (v, m, { close }) => {
                close();
                isAptekaListInWork.updateValue(true);
                if (isFirstAuth.value) {
                  localStorage.setItem(String(role.value), 'false');
                  isFirstAuth.updateValue(false);
                }
              },
            },
          ],
        },
      ])();
  }, [isNotAccessGranted]);

  const [primaryActionPanel, secondaryActionPanel] = useActionPanel(aptekaListTableStore, [
    ...(isNotAccessGranted.value
      ? [
          {
            icon: AssignmentTurnedInIcon,
            // color: 'error',
            text: 'Подтверждение актуальности списка',
            action: () => {
              confirmPharmaciesList();
            },
          },
        ]
      : []),
    {
      icon: AssignmentTurnedInIcon,
      text: 'Изменить статус проверки',
      action: [
        {
          icon: AssignmentTurnedInIcon,
          text: 'Подтверждена',
          action: () => {
            confirmPharmaciesData({ variables: { ids: aptekaListTableStore.checkboxList.checked.map((id) => Number(id)), valid: true } });
            aptekaListTableStore.checkboxList.removeCheck();
          },
        },
        {
          icon: AssignmentLateIcon,
          color: 'error',
          text: 'Не подтверждена',
          action: () => {
            noneСonfirmPharmaciesData({
              variables: { ids: aptekaListTableStore.checkboxList.checked.map((id) => Number(id)), valid: false },
            });
            aptekaListTableStore.checkboxList.removeCheck();
          },
        },
      ],
    },
    {
      icon: AssignmentTurnedInIcon,
      text: 'Изменить принадлежность к АС',
      action: [
        {
          icon: AssignmentLateIcon,
          text: 'Действующая',
          tooltip:
            'Сменить статус аптеки на «Действующая» могут только сотрудники Renewal, просьба создать запрос в разделе «Обратная связь»',
          disabled: true,
          action: () => {},
        },
        {
          icon: AssignmentLateIcon,
          color: 'error',
          text: 'Не действующая',
          action: modalStore.openHandler([
            {
              type: 'ExcludeFromLPDialog',
              title: 'Подтверждение выхода',
              actionButtons: [
                {
                  text: 'Да',
                  action: (v, m, { nextModal }) => nextModal(),
                },
              ],
            },
            {
              isForm: true,
              type: 'AffiliationDialog',
              title: 'Подтверждение выхода',
              actionButtons: [
                {
                  text: 'Подтвердить',
                  action: (values, modalNum, { close }) => {
                    let { exclusion_date } = values;

                    if (exclusion_date && exclusion_date instanceof Date && isValid(exclusion_date)) {
                      exclusion_date = getDateTime(exclusion_date);

                      excludePharmaciesFromNetwork({
                        variables: { ids: aptekaListTableStore.checkboxList.checked.map((id) => Number(id)), exclusion_date },
                      });
                      aptekaListTableStore.checkboxList.removeCheck();
                      close();
                    }
                  },
                },
              ],
            },
          ]),
        },
      ],
    },
    {
      icon: AssignmentTurnedInIcon,
      text: 'Изменить участие в ПЛ',
      action: [
        {
          icon: AssignmentTurnedInIcon,
          text: 'Активна',
          action: () => {
            const [pharmacyDonotMeetLoyaltyProgramList, pharmacyMeetLoyaltyProgramList] = aptekaListTableStore.checkboxList.checked.reduce(
              (result: Array<ReactText[]>, id) => {
                const {
                  pharmacy_name,
                  optionValues: { isPharmacistAuth },
                } = rows.find(({ id: apteka_id }: any) => id == apteka_id);
                let [donotMeet, meet] = result;
                if (!pharmacy_name || !isPharmacistAuth) donotMeet.push(id);
                else meet.push(id);
                return [donotMeet, meet];
              },
              [[], []]
            );

            if (!!pharmacyDonotMeetLoyaltyProgramList[0]) {
              modalStore.openHandler([
                {
                  type: 'AddPharmacistsAuthDataDialog',
                  title: 'Внимание!',
                  values: pharmacyDonotMeetLoyaltyProgramList,
                  actionButtons: [
                    {
                      text: 'Применить к остальным',
                      disabled: () => !pharmacyMeetLoyaltyProgramList.length,
                      action: (v, m, { close }) => {
                        activateLoyaltyProgram({ variables: { ids: pharmacyMeetLoyaltyProgramList.map((id) => Number(id)) } });
                        aptekaListTableStore.checkboxList.removeCheck();
                        close();
                      },
                    },
                  ],
                },
              ])();
            } else {
              activateLoyaltyProgram({ variables: { ids: aptekaListTableStore.checkboxList.checked.map((id) => Number(id)) } });
              aptekaListTableStore.checkboxList.removeCheck();
            }
          },
        },
        {
          icon: AssignmentLateIcon,
          color: 'error',
          text: 'Не активна',
          action: modalStore.openHandler([
            {
              type: 'ExcludeFromLPDialog',
              title: 'Подтверждение выхода',
              actionButtons: [
                {
                  text: 'Да',
                  action: (v, m, { close }) => {
                    deactivateLoyaltyProgram({ variables: { ids: aptekaListTableStore.checkboxList.checked.map((id) => Number(id)) } });
                    aptekaListTableStore.checkboxList.removeCheck();
                    close();
                  },
                },
              ],
            },
          ]),
        },
      ],
    },
  ]);

  const aptekas: ITablePreset = {
    head: {
      id: '№',
      pharmacy_name: 'Аптека',
      address: 'Адрес',
      inn: 'ИНН',
      is_checked_by_manager: 'Статус проверки',
      is_belongs_to_network: 'Принадлежность к АС',
      is_consists_in_loyalty_program: 'Участие в ПЛ',
      updated_at: 'Последнее изменение',
    },
    filters: {
      id: {
        type: 'AutocompleteFilter',
        values: getUniqueValuesFromRows(rows, 'id'),
        size: 'small',
        placeholder: 'Введите номер аптеки',
      },
      pharmacy_name: {
        type: 'AutocompleteFilter',
        values: getUniqueValuesFromRows(rows, 'pharmacy_name'),
        size: 'small',
        placeholder: 'Введите название аптеки',
      },
      address: {
        type: 'AutocompleteFilter',
        values: getUniqueValuesFromRows(rows, 'address'),
        size: 'small',
        placeholder: 'Введите адрес аптеки',
      },
      is_checked_by_manager: {
        type: 'SelectFilter',
        values: [
          { value: 'all', label: 'Все' },
          { value: 'unaccepted', label: 'Не подтверждена' },
          { value: 'accepted', label: 'Подтверждена' },
        ],
        preset: {
          def: 'all',
        },
        size: 'small',
      },
      is_belongs_to_network: {
        type: 'SelectFilter',
        values: [
          { value: 'all', label: 'Все' },
          { value: 'unaccepted', label: 'Не действующая' },
          { value: 'accepted', label: 'Действующая' },
        ],
        preset: {
          def: 'all',
        },
        size: 'small',
      },
      is_consists_in_loyalty_program: {
        type: 'SelectFilter',
        values: [
          { value: 'all', label: 'Все' },
          { value: 'not_loaylty', label: 'Не активна' },
          { value: 'is_consists_in_loyalty_program', label: 'Активна' },
        ],
        preset: {
          def: 'all',
        },
        size: 'small',
      },
      inn: {
        type: 'AutocompleteFilter',
        values: getUniqueValuesFromRows(rows, 'inn'),
        size: 'small',
        placeholder: 'Введите номер ИНН',
      },
      updated_at: {
        type: 'DatePeriodPickerField',
        size: 'small',
      },
    },
    sort: {
      id: 'desc',
      updated_at: false,
    },
    saveTableHandler: (setSVGData, exportToCSV, fileName) => {
      modalStore.openHandler([
        {
          type: 'AptekaListSaveDialog',
          isForm: true,
          title: 'Выгрузка данных',
          fullWidth: true,
          maxWidth: 'md',
          actionButtons: [
            {
              text: 'Сформировать отчет',
              action: (values, m, { nextModal }) => {
                const { main = {}, pharmacy_manager_contacts = {} }: { [x: string]: {} } = values || {};
                const variables = { ...main, ...pharmacy_manager_contacts };
                nextModal({ variables, fileName });
              },
            },
          ],
        },
        {
          type: 'SaveFileDialog',
          title: 'Формирование отчета',
          values: { query: listOfPharmaciesForManager, nesting: ['listOfPharmaciesForManager'] },
          cancelButton: {
            text: 'Закрыть',
          },
        },
      ])();
    },
    options: {
      tableRef: scrolledContent,
      tableScrollHandler: handleContentScroll,
      checkboxDisabled: ({ isNotBelongToNetwork }) => isNotBelongToNetwork,
      columnsWidth: {
        id: 100,
        pharmacy_name: 300,
        address: 400,
        is_checked_by_manager: 150,
        is_belongs_to_network: 180,
        is_consists_in_loyalty_program: 150,
        inn: 150,
        updated_at: 300,
      },
      cellsAlign: {
        all: {
          is_checked_by_manager: 'center',
          is_belongs_to_network: 'center',
          is_consists_in_loyalty_program: 'center',
        },
      },
      cellsRender: {
        row: {
          all: (key, value, { isNotBelongToNetwork }) => (
            <div {...(isNotBelongToNetwork ? { style: { color: theme.palette.grey[500] } } : {})}>{value}</div>
          ),
          is_checked_by_manager: (key, value, { isNotBelongToNetwork }) => {
            switch (value) {
              case 0:
                return (
                  <div>
                    <AssignmentLateIcon
                      style={{
                        color: isNotBelongToNetwork ? theme.palette.grey[500] : theme.palette.error.main,
                      }}
                    />
                  </div>
                );

              case 1:
                return (
                  <div>
                    <AssignmentTurnedInIcon
                      {...(isNotBelongToNetwork ? { style: { color: theme.palette.grey[500] } } : { color: 'primary' })}
                    />
                  </div>
                );

              default:
                break;
            }
          },
          is_consists_in_loyalty_program: (key, value, { is_manageable, isNotBelongToNetwork }) => {
            const title =
              'На текущий момент программа лояльности в данном городе отсутствует, о добавлении города будет уведомление в информационном окне главной страницы';
            switch (value) {
              case 0:
                return (
                  <div>
                    {is_manageable ? (
                      <AssignmentLateIcon
                        style={{
                          color: isNotBelongToNetwork ? theme.palette.grey[500] : theme.palette.error.main,
                        }}
                      />
                    ) : (
                      <TooltipIcon {...{ title, icon: AssignmentLateIcon }} />
                    )}
                  </div>
                );

              case 1:
                return (
                  <div>
                    {is_manageable ? (
                      <AssignmentTurnedInIcon
                        {...(isNotBelongToNetwork ? { style: { color: theme.palette.grey[500] } } : { color: 'primary' })}
                      />
                    ) : (
                      <TooltipIcon {...{ title, icon: AssignmentTurnedInIcon }} />
                    )}
                  </div>
                );

              default:
                break;
            }
          },
          is_belongs_to_network: (key, value, { isNotBelongToNetwork }) => {
            switch (value) {
              case 0:
                return (
                  <div>
                    <AssignmentLateIcon
                      style={{
                        color: isNotBelongToNetwork ? theme.palette.grey[500] : theme.palette.error.main,
                      }}
                    />
                  </div>
                );

              case 1:
                return (
                  <div>
                    <AssignmentTurnedInIcon
                      {...(isNotBelongToNetwork ? { style: { color: theme.palette.grey[500] } } : { color: 'primary' })}
                    />
                  </div>
                );

              default:
                break;
            }
          },
          updated_at: (key, value, { isNotBelongToNetwork }) => (
            <div {...(isNotBelongToNetwork ? { style: { color: theme.palette.grey[500] } } : {})}>{getDateStringWithTime(value)}</div>
          ),
        },
      },
      clickOnRowHandler: (e, { id } = {}) => history.push(`/main/apteka_details/${id}`),
      actionPanel: currentButton.value === null ? primaryActionPanel : secondaryActionPanel,
      headActionPanel: () => {
        return (
          <Button
            variant="outlined"
            aria-label="add"
            size="small"
            color="primary"
            style={{
              borderRadius: '0px 4px 4px 0px',
            }}
            onClick={modalStore.openHandler([
              {
                title: 'Добавить аптеку',
                isForm: true,
                isHightOnContent: false,
                fullWidth: true,
                type: 'AddAptekaFillingFields',
                values: {
                  query: {
                    findListOfAddressesSyncedNewPharmacies,
                    findListOfCityNameSyncedNewPharmacies,
                    findListOfInnSyncedNewPharmacies,
                  },
                },
                actionButtons: [
                  {
                    text: 'Найти аптеку',
                    disabled: (formValues) => {
                      const values = Object.values(formValues);
                      // @ts-ignore
                      return !values || !values.find((value) => value?.length > 2);
                    },
                    action: (values, modal, { nextModal }) => nextModal(values),
                  },
                ],
              },
              {
                title: 'Добавить аптеку',
                fullWidth: true,
                maxWidth: 'md',
                isForm: true,
                type: 'AddAptekaSelect',
                values: { findListOfSyncedNewPharmacies },
                actionButtons: [
                  {
                    text: 'Добавить аптеку',
                    // @ts-ignore
                    disabled: (values) => !values.add_pharmacy_list?.checked?.length,
                    action: (values, m, { close }) => {
                      // @ts-ignore
                      const { checked } = values.add_pharmacy_list;
                      checked.forEach((id: string) => {
                        createPharmacyFromSyncedDataFn({
                          variables: { sync_new_pharmacy_id: Number(id) },
                        });
                      });
                      close();
                    },
                  },
                  {
                    text: 'Назад',
                    action: (v, m, { setModal }) => setModal(0),
                  },
                ],
              },
            ])}
          >
            Добавить аптеку
          </Button>
        );
      },
    },
  };

  return (
    <>
      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        Список аптек
      </Typography>
      <AptekasListDataFilters tableStore={aptekaListTableStore} rows={rows} tablePreset={aptekas} />

      <Footer children={OutsideScrollbar} />
    </>
  );
};

export default AptekaListTable;
