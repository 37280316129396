import React from 'react';
import { SnackbarContent } from 'notistack';

// Types
import { TCustomSnackbarProps } from './types';

const CustomSnackbar = React.forwardRef<HTMLDivElement, TCustomSnackbarProps>(({ id, layout: Layout, ...other }, ref) => {
  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Layout {...{ id, ...other }} />
    </SnackbarContent>
  );
});

export default CustomSnackbar;
