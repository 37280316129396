import React from 'react';

// Components
import { Grid, Typography } from '@mui/material';
import AutocompleteQueryField from '../../../FormElements/AutocompleteQueryField';

// Types
import { FC } from 'react';
import { IContentProps } from '../../types';

const AddAptekaFillingFields: FC<IContentProps> = ({ modalStore, values }) => {
  const { findListOfAddressesSyncedNewPharmacies, findListOfCityNameSyncedNewPharmacies, findListOfInnSyncedNewPharmacies } = values.query;
  const { formElementsList } = modalStore;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteQueryField
          formStore={formElementsList}
          query={findListOfInnSyncedNewPharmacies}
          name="inn"
          label="Укажите ИНН *"
          selfRemoved={true}
          variant="outlined"
          size="small"
          numLetterForQuery={6}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteQueryField
          formStore={formElementsList}
          query={findListOfCityNameSyncedNewPharmacies}
          name="city_name"
          label="Укажите город *"
          selfRemoved={true}
          variant="outlined"
          size="small"
          numLetterForQuery={3}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteQueryField
          formStore={formElementsList}
          query={findListOfAddressesSyncedNewPharmacies}
          name="address"
          label="Укажите адрес (улицу) *"
          selfRemoved={true}
          variant="outlined"
          size="small"
          numLetterForQuery={3}
        />
      </Grid>
      <Typography style={{ margin: 8 }}>* Поля обязательные для заполнения.</Typography>
    </Grid>
  );
};

export default AddAptekaFillingFields;
