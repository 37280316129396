import { makeObservable } from 'mobx';

import { TStoreClassesName } from '../types';
import { FormElementsListStore } from './mapped';
import ToggleStore from './components/ToggleStore';
import TableStore from './components/TableStore';
import PaginationStore from './components/PaginationStore';

export type TCreateParam = (param: 'filtersList', name: string, type: TStoreClassesName, preset?: any) => any;

class StatisticsStore extends TableStore {
  filtersPanelList: FormElementsListStore = new FormElementsListStore();
  isExtendedSelection: ToggleStore = new ToggleStore();

  isShownTable: ToggleStore = new ToggleStore();
  isUpdate: ToggleStore = new ToggleStore();

  constructor() {
    super();
    makeObservable(this, {});
  }

  push = () => {
    if (!this.isShownTable.value) this.isShownTable.updateValue(true);
    this.isUpdate.updateValue(true);
  };

  // Прокси на обновление запроса
  _requeryProxy = (target: PaginationStore) => {
    const context = this;

    const fProxy = (f: any) =>
      new Proxy(f, {
        apply: (target, thisArg, args) => {
          target.apply(thisArg, args);

          context.isUpdate.updateValue(true);
        },
      });

    target.page.updateValue = fProxy(target.page.updateValue);
    target.perPage.updateValue = fProxy(target.perPage.updateValue);

    return target;
  };

  pagination = this._requeryProxy(this.pagination);
}

export default StatisticsStore;
