import React from 'react';

// Components
import { Typography } from '@mui/material';
import { Redirect } from 'react-router';
import FiltersQuery from './StatisticsFilterQuery-Fake';
import TableQuery from './StatisticsTableQueryContainer';
import ButtonBack from '../../common/components/ButtonBack';
import LoadingBlock from '../../common/components/LoadingBlock';

// Types
import { FC } from 'react';
import { IStatisticsProps } from './types';

const Statistics: FC<IStatisticsProps> = ({ statisticsStore, authStore }) => {
  const { isShownTable } = statisticsStore;

  const { role, getAccess, isNotAccessGranted } = authStore;
  // См. MainRoot
  if (!role.value) return <LoadingBlock />;
  if (!getAccess('statisticsPg')) return <Redirect to="/main/order_list/1" />;
  if (isNotAccessGranted.value) return <Redirect to="/main/apteka_list/1" />;

  return (
    <div>
      <div
        style={{
          margin: '0 16px',
          display: 'flex',
        }}
      >
        <ButtonBack route="/main/" />
      </div>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        Взаиморасчеты
      </Typography>

      <FiltersQuery />
      {isShownTable.value && <TableQuery />}
    </div>
  );
};

export default Statistics;
