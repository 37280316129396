import React from 'react';

// Components
import { TextField, IconButton } from '@mui/material';

// Icons & Images
import { Visibility, VisibilityOff, VpnKey } from '@mui/icons-material';

// Hooks
import { useEffect, useState } from 'react';

// Utils
import { observer } from 'mobx-react';
// @ts-ignore
import otpGenerator from 'otp-generator';

// Types
import { FC } from 'react';
import { TFieldProps } from './types';
import WithFormElementsStore from './WithFormElementsStore';

const Field: FC<TFieldProps> = ({
  store,
  name,
  rows,
  isShowPass,
  generator,
  visBtnRef,
  keyBtnRef,
  clName: { formControl } = {},
  ...props
}) => {
  const { value, onChangeHandler, isError } = store;

  const { type } = props;

  const [showPass, setShowPass] = useState(isShowPass);

  // Очистка ошибки, если значение присутсвует
  useEffect(() => {
    if (value && isError.value) isError.updateValue(false);
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const valueGenerate = () => {
    const value = generator
      ? typeof generator === 'function'
        ? generator(isError)
        : otpGenerator.generate(6, { upperCase: false, specialChars: false })
      : '';
    if (!!value) store.updateValue(value);
    setShowPass(!!value);
  };

  const KeyIcon =
    type === 'password' || generator ? (
      <IconButton style={{ marginRight: '-10px' }} size="small" onClick={valueGenerate} ref={keyBtnRef}>
        <VpnKey />
      </IconButton>
    ) : null;

  const VisibilIcon =
    type === 'password' ? (
      <IconButton
        aria-label="toggle password visibility"
        size="small"
        onClick={handleClickShowPassword}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        ref={visBtnRef}
      >
        {showPass ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ) : null;

  return (
    <TextField
      id={name}
      className={formControl}
      value={value}
      onChange={onChangeHandler}
      error={!!isError.value}
      helperText={typeof isError.value === 'string' ? isError.value : ''}
      multiline={!!rows}
      rows={rows}
      fullWidth
      InputProps={
        KeyIcon || VisibilIcon
          ? {
              endAdornment: (
                <>
                  {VisibilIcon}
                  {KeyIcon}
                </>
              ),
            }
          : {}
      }
      {...props}
      {...(type === 'password' ? { type: showPass ? 'text' : 'password' } : null)}
    />
  );
};

export default WithFormElementsStore(observer(Field), 'FieldStore');
