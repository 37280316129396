import React from 'react';

// Icons & Images
import { ReportProblemOutlined } from '@mui/icons-material';

// Utils
import { makeStyles } from '@mui/styles';

// Types
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

export default function ReportIcon() {
  const { icon } = useStyles();
  return <ReportProblemOutlined className={icon} />;
}
