import { makeObservable } from 'mobx';

import FormElementsListStore from './mapped/FormElementsListStore';
import ValueStore from './components/ValueStore';

class OrderDetailsStore {
  orderRefuseDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);
  orderRefuseFinishedDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);
  notifyReadyConfirmationDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);
  notifyReadyFinishedDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);
  issuedDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);
  errorDialogOpen: ValueStore<boolean> = new ValueStore<boolean>(false);

  giveOutOrderCard: FormElementsListStore = new FormElementsListStore();

  code: ValueStore<string> = new ValueStore('');
  errorMessage: ValueStore<string> = new ValueStore('');

  constructor() {
    makeObservable(this, {});
  }

  clearFileds = () => {
    this.orderRefuseDialogOpen.updateValue(false);
    this.orderRefuseFinishedDialogOpen.updateValue(false);
    this.notifyReadyConfirmationDialogOpen.updateValue(false);
    this.notifyReadyFinishedDialogOpen.updateValue(false);
    this.issuedDialogOpen.updateValue(false);
    this.errorDialogOpen.updateValue(false);
    this.code.updateValue('');
    this.errorMessage.updateValue('');
  };
}

export default OrderDetailsStore;
