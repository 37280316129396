import React from 'react';

// Types
import { FC } from 'react';

const NotAccessGrantedDialog: FC = () => {
  return (
    <>
      Пожалуйста, подтвердите актуальный список аптек участников программы лояльности, после чего вы сможете продолжить работу в веб-версии.
    </>
  );
};

export default NotAccessGrantedDialog;
