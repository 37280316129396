import React from 'react';

// Components
import OrderDetailsUIContainer from './OrderDetailsUIContainer';

// Types
import { FC } from 'react';
import { TOrderDetailsQueryDataHandlerProps } from './types';

const OrderDetailsQueryDataHandler: FC<TOrderDetailsQueryDataHandlerProps> = ({ data, loading, refetch }) => {
  const resultData = {
    order: data ? data.pharmacy_order_details : null,
  };
  const queryData = { loading, refetch };

  return <OrderDetailsUIContainer {...resultData} {...queryData} />;
};

export default OrderDetailsQueryDataHandler;
