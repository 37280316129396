import React from 'react';

// Components
import { Redirect } from 'react-router';
import AptekaDetailsQuery from './AptekaDetailsQuery';
import AptekaDetailsQueryDataHandler from './AptekaDetailsQueryDataHandler';
import LoadingBlock from '../../common/components/LoadingBlock';

// Hooks
import { useParams } from 'react-router';

// Types
import { FC } from 'react';
import { TAptekaDetailsProps } from './types';

const AptekaDetails: FC<TAptekaDetailsProps> = ({ authStore }) => {
  const { id } = useParams();

  const { role, getAccess } = authStore;
  // Нужно возвращать "круговой прогресс" на случай, если пользователь является фармацевт,
  // и пытается пройти по прямой ссылке на "main", чтобы ему на мгновение не отображалась
  // содержимое "main" из-за того, что не пришла его роль с бэка при обновлении страницы.
  if (!role.value) return <LoadingBlock />;
  if (!getAccess('aptekaDetailsPg')) return <Redirect to="/main/order_list/1" />;

  if (id === 'addition') {
    return <AptekaDetailsQueryDataHandler />;
  }

  return <AptekaDetailsQuery id={id} />;
};

export default AptekaDetails;
