import React from 'react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const OrderNotReadyToIssueDialog: FC<IContentProps> = ({ yarnValues }) => {
  const { id, phone, status, searchType, searchValue } = yarnValues;
  const ResultStatusName: { [x: string]: string } = {
    accepted: 'Не обработан аптекой',
    cancelledByUser: 'Отменён пользователем',
    cancelledByApteka: 'Отменён аптекой',
    readyToIssue: 'Готов к выдаче',
    issued: 'Выдан',
    dontKnow: 'Неизвестно',
    unpacked: 'Разобран аптекой',
  };

  return (
    <>
      {`Заказ с номером ${searchType === 'phones' ? `телефона ${phone}` : id} не готов к выдаче. Текущий статус "${
        ResultStatusName[status]
      }".`}
      {searchType === 'phones'
        ? phone != searchValue
        : id != searchValue &&
          ` Если результаты поиска не соответствуют запросу, попробуйте еще раз. Вы вводили поиск со значением ${searchValue} в номере ${
            searchType === 'phones' ? 'телефона' : 'заказа'
          }.`}
    </>
  );
};

export default OrderNotReadyToIssueDialog;
