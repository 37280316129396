import { makeObservable, action } from 'mobx';

import ValueStore from '../components/ValueStore';
import { TDate } from '../../types';

class DatePeriodPickerFieldStore extends ValueStore<[TDate, TDate]> {
  constructor(def: [TDate, TDate]) {
    super(def);
    makeObservable(this, { clear: action });
  }

  clear = () => this.updateValue([null, null]);

  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default DatePeriodPickerFieldStore;
