import React from 'react';

// Components
import { Tooltip } from '@mui/material';

// Utils
import { withStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { ITooltipIcon, EColor } from './types';

const TooltipBigFSize = withStyles({
  tooltip: {
    fontSize: 14,
  },
})(Tooltip);

const HelpIcon: FC<ITooltipIcon> = ({ title, icon: Icon, color = 'disabled', size }) => {
  const isPresets = color in EColor;

  return (
    <TooltipBigFSize title={title}>
      <Icon color={isPresets ? (color as EColor) : undefined} style={isPresets ? { fill: color } : undefined} fontSize={size} />
    </TooltipBigFSize>
  );
};

export default HelpIcon;
