export const stringVerification = (search: string, str: string, flag: string = 'g') => {
  const searchArr = search.split(' ');
  let lastIndex = 0;
  for (const searchEl of searchArr) {
    const regExp = new RegExp(searchEl, flag);
    regExp.lastIndex = lastIndex;

    const result = regExp.test(str);
    if (result) {
      lastIndex = regExp.lastIndex;
    } else return false;
  }
  return true;
};

export const passValidationError = (value: string, { length = 6, upperCase = true }) => {
  const r = upperCase ? /[^A-Z-a-z-0-9]/g : /[^a-z-0-9]/g;
  if (r.test(value)) {
    return `Введены недопустимые символы. Разрешены латинские буквы${!upperCase && ' в нижнем регистре'} и цифры`;
  }
  const w = [/[^a-z]/g, /[^A-Z-a-z]/g][Number(upperCase)].test(value);
  const n = /[^0-9]/g.test(value);
  if (!w || !n) return 'Пароль не должен содержать одни буквы или одни цифры';
  if (value.length < length) {
    return 'Слишком короткий пароль';
  }
  return false;
};

export const steriliseNumber = (value: string) => value.split(/\D/).join('').toString();
