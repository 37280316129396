import React from 'react';

// Components
import { Typography } from '@mui/material';

// Utils
import { makeStyles, createStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IPaginationRowsLabelProps } from './types';

// Utils
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2),
      textAlign: 'left',
    },
  })
);

const PaginationRowsLabel: FC<IPaginationRowsLabelProps> = ({ rowStart, rowEnd, rowsCount }) => {
  const { root } = useStyles();

  return <Typography variant="body2" className={root}>{`Отображается ${rowStart}-${rowEnd} из ${rowsCount}.`}</Typography>;
};

export default PaginationRowsLabel;
