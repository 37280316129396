import { ApolloClient, from } from '@apollo/client';
import QueueLink from 'apollo-link-queue';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { history } from '../App';
import { authStore } from '../stores/Store';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache();
const queueLink = new QueueLink();

const logout = () => {
  localStorage.setItem('token', '');
  localStorage.setItem('websocket_data', '');
  authStore?.role.updateValue(null);
  history.push('/login');
};

// // ${window.location.hostname}
export const uriCore = 'https://api.renewal.ru';
const httpLink = from([
  new RetryLink(),
  queueLink,
  createUploadLink({
    credentials: 'same-origin',
    uri: uriCore + '/m_graphql/',
  }),
]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  if (!token && history.location.pathname !== '/login') logout();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorHandlerLink = onError(({ response, graphQLErrors, networkError, operation }) => {
  if (graphQLErrors || networkError) {
    console.log('operation', operation.operationName);
    console.log('Error', graphQLErrors || networkError);
    if (!!networkError) logout();
  }
});

const Client = new ApolloClient({
  cache,
  link: errorHandlerLink.concat(authLink).concat(httpLink),
});

export default Client;
