import React from 'react';

// Components
import SelectField from './SelectField';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { TSelectFieldProps } from './types';

const SelectMultipleField: FC<TSelectFieldProps> = (props) => <SelectField {...props} multiple />;

export default observer(SelectMultipleField);
