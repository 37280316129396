import React from 'react';

// Components
import { Container, Card, CardActionArea, CardContent, IconButton } from '@mui/material';
import ImageSlider from './ImageSlider';

// Hooks
import { useState } from 'react';

// Icons & Images
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';

// Utils
import { observer } from 'mobx-react';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IImageSliderCardProps } from './types';

// Styles
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    width: 'auto',
  },
  card: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  panelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
}));

const ImageSliderCard: FC<IImageSliderCardProps> = ({ images, aspectRatio }) => {
  const { root, card, panelWrapper } = useStyle();

  const [asRatio, setAspectRatio] = useState(aspectRatio);
  const [imageSliderFull, setImageSliderFull] = useState<Element | null>(null);
  const [imageSlider, setImageSlider] = useState<ImageSlider | null>(null);
  const imageSliderRef = (ref: ImageSlider) => setImageSlider(ref);
  const [currentSlide, setCurrentSlide] = useState(0);

  const toNextSlide = () => {
    if (imageSlider) {
      imageSlider.toNextSlide();
      setCurrentSlide(imageSlider.nexSlide);
    }
  };

  const toPreviousSlide = () => {
    if (imageSlider) {
      imageSlider.toPreviousSlide();
      setCurrentSlide(imageSlider.previousSlide);
    }
  };

  const fullscreenHandler = () => {
    if (screenfull.isEnabled) {
      if (!screenfull.isFullscreen) {
        screenfull.request(findDOMNode(imageSliderFull) as Element);
        const { width, height } = window.screen;
        // 80 - средняя ширина панели действий
        setAspectRatio((width - 80) / height);
      } else {
        screenfull.exit();
        setAspectRatio(aspectRatio);
      }
    }
  };

  return (
    <Container className={root} maxWidth={false} ref={setImageSliderFull}>
      <Card className={card}>
        <CardActionArea onClick={fullscreenHandler}>
          <ImageSlider ref={imageSliderRef} {...{ images, aspectRatio: asRatio }} />
        </CardActionArea>
        <CardContent className={panelWrapper}>
          <IconButton onClick={toNextSlide} disabled={currentSlide === images.length - 1}>
            <NavigateNextIcon />
          </IconButton>
          <IconButton onClick={toPreviousSlide} disabled={!currentSlide}>
            <NavigateBeforeIcon />
          </IconButton>
        </CardContent>
      </Card>
    </Container>
  );
};

export default observer(ImageSliderCard);
