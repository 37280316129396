import { makeObservable } from 'mobx';
import { FormElementsListStore } from './mapped';
import ToggleStore from './components/ToggleStore';

class FeedbackDetailsStore {
  addMessForm: FormElementsListStore = new FormElementsListStore();
  showAddMessForm: ToggleStore = new ToggleStore();

  constructor() {
    makeObservable(this, {});
  }
}

export default FeedbackDetailsStore;
