import React from 'react';

// Components
import { Grid } from '@mui/material';

// Types
import { FC } from 'react';
interface IStatusProps {
  color: string;
  text: string;
}

const Status: FC<IStatusProps> = ({ color, text }) => {
  return (
    <Grid alignItems="center" container>
      <div
        style={{
          display: 'inline-block',
          backgroundColor: color,
          width: '14px',
          height: '14px',
          borderRadius: '50%',
        }}
      />
      <div style={{ display: 'inline-block', marginLeft: '8px' }}>{text}</div>
    </Grid>
  );
};

export default Status;
