import React from 'react';

// Componets
import { Button } from '@mui/material';
import { StyledTooltip } from './styled';

// Utils
import { styled } from '@mui/material/styles';

// Types
import { FC } from 'react';
import { ButtonProps } from '@mui/material';
import { IActionPrimaryButtonProps } from '../types';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  fontWeight: 700,
}));

const PrimaryButton: FC<IActionPrimaryButtonProps> = ({
  action,
  text,
  tooltip,
  className,
  disabled,
  color = 'primary',
  variant = 'contained',
  sx,
}) => {
  const button = (
    <StyledButton onClick={action} classes={className} color={color} sx={sx} disabled={disabled} variant={variant} size="large">
      {text}
    </StyledButton>
  );

  return tooltip ? <StyledTooltip title={tooltip}>{button}</StyledTooltip> : button;
};

export default PrimaryButton;
