import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

export enum EColor {
  inherit = 'inherit',
  disabled = 'disabled',
  primary = 'primary',
  secondary = 'secondary',
}

export interface ITooltipIcon {
  title: string;
  icon: FC<SvgIconProps>;
  color?: keyof typeof EColor | string;
  size?: 'small' | 'inherit' | 'default' | 'large';
}

export type THelpIconProps = {
  title: string;
};
