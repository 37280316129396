import React from 'react';

// Components
import { Grid, Typography } from '@mui/material';
import ScheduleDay from './ScheduleDay';

// Types
import { FC } from 'react';
import { IScheduleWorkProps } from '../types';
import { FormElementsListStore } from '../../../../stores/classes/mapped';

const ScheduleWork: FC<IScheduleWorkProps> = ({ name, formStore, preset }) => {
  const scheduleWorkStore = formStore.set(name, FormElementsListStore) as FormElementsListStore;
  console.log('ScheduleWork preset', preset);
  const { def = [] } = preset || {};

  const dayaList = [...def];
  if (!dayaList.length) {
    let i = 1;
    while (i < 8) {
      dayaList.push({ day: i });
      i++;
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography>День недели</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography style={{ textAlign: 'center' }}>Время работы</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Круглосуточно</Typography>
        </Grid>
      </Grid>
      {dayaList.map((def) => (
        <ScheduleDay key={def.id} formStore={scheduleWorkStore} preset={{ def }} />
      ))}
    </div>
  );
};

export default ScheduleWork;

export const dayName = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
