import { makeObservable } from 'mobx';

import PlayerStore from './components/PlayerStore';

class TrainingDetailsStore {
  player: PlayerStore = new PlayerStore();

  constructor() {
    makeObservable(this, {});
  }
}

export default TrainingDetailsStore;
