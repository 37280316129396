import React from 'react';

// Components
import StatisticsTableQueryDataHandler from './StatisticsTableQueryDataHandlerContainer';

// Hooks
import { useCallback } from 'react';
import { useParams } from 'react-router';

// Queries
import { FC } from 'react';
import { productStatistic, couponStatistic, orderStatistic } from './fakeDB/queries';
import { IStatisticsTableQueryProps } from './fakeDB/type';

const StatisticsTableQuery: FC<IStatisticsTableQueryProps> = ({ DB, statisticsStore }) => {
  const { type: tableType = 'medicine' } = useParams();

  const queries: { [x: string]: (input: any, DB: any) => any } = {
    medicine: productStatistic,
    coupon: couponStatistic,
    orders: orderStatistic,
  };

  const { filtersPanelList: filtersList, isExtendedSelection, isUpdate, pagination } = statisticsStore;
  const query = useCallback(() => {
    // ИМИТАЦИЯ ЗАПРОСА НА СЕРВЕР ДЛЯ ПОЛУЧЕНИЯ СПИСКА АПТЕК
    const { cities, entities, name_part, address_part, apteka_id_list, period, type, orderStatus } = filtersList.formValues as {
      cities: number;
      entities: number;
      name_part: string;
      address_part: string;
      apteka_id_list: Array<string | number>;
      period: { from: Date; to: Date };
      type: string;
      orderStatus: number[];
    };
    const { page, perPage } = pagination;

    const aptekasParam = isExtendedSelection.value
      ? { apteka_id_list }
      : {
          cities: cities >= 0 ? [cities] : undefined,
          entities: entities >= 0 ? [entities] : undefined,
        };

    const data = queries[tableType](
      {
        page: page.value,
        per_page: perPage.value,
        ...aptekasParam,
        period: {
          from: period.from ? JSON.stringify(period.from) : undefined,
          to: period.to ? JSON.stringify(period.to) : undefined,
        },
        type,
        orderStatus: orderStatus,
      },
      DB
    );
    localStorage.setItem('statistics', JSON.stringify(data));
    isUpdate.updateValue(false);
    return data;
  }, [filtersList, pagination, tableType, isUpdate]);

  let data: string | any = localStorage.getItem('statistics');
  if (data) data = JSON.parse(data);
  if (isUpdate.value) data = query();

  return <StatisticsTableQueryDataHandler data={data} />;
};

export default StatisticsTableQuery;
