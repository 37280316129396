import { makeObservable } from 'mobx';

import TriggerStore from './TriggerStore';

// Types
import { ChangeEvent, ReactText } from 'react';
import { TFieldStorePreset } from '../../types';

class FieldStore<T> extends TriggerStore<T> {
  private type: 'value' | 'checked';
  private min?: number;
  private max?: number;
  constructor({ type = 'value', def = type === 'checked' ? false : '', min, max }: TFieldStorePreset = {}) {
    super(def);
    this.type = type;
    this.min = min;
    this.max = max;

    makeObservable(this, {});
  }

  onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target[this.type];
    // @ts-ignore
    if ((this.min === undefined || this.min <= value) && (this.max === undefined || this.max >= value)) this.updateValue(value);
  };
}

export default FieldStore;
