import React from 'react';

// Components
import { Box, Alert, AlertTitle } from '@mui/material';

// Utils
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { BoxProps } from '@mui/material';
import { IAlertPanelProps } from './types';

const StyledAlertPanel = styled(Box, {
  name: 'MuiAlertPanel',
  slot: 'wrapper',
})<BoxProps>(({ theme, children }) => ({
  display: 'flex',
  flexDirection: 'column',
  // @ts-ignore
  padding: theme.spacing(children.length ? 1 : 0),
  gap: theme.spacing(1),
}));

const AlertPanel: FC<IAlertPanelProps> = ({ tableStore }) => {
  const { getAlertList } = tableStore;
  const alertList = getAlertList();

  return (
    <StyledAlertPanel>
      {alertList.map(({ title, description, ...other }, i) => {
        const Content = typeof description === 'string' ? () => <>{description}</> : description;

        return (
          <Alert key={i} {...other}>
            {title && <AlertTitle>{title}</AlertTitle>}
            <Content />
          </Alert>
        );
      })}
    </StyledAlertPanel>
  );
};

export default observer(AlertPanel);
