import React from 'react';

// Components
import { Button, ButtonProps } from '@mui/material';

// Icons & Images
import { AttachFile as AttachFileIcon } from '@mui/icons-material';

// Types
import { FC } from 'react';
import { IAddFileButton } from './types';

const DefaultAddBtn: FC<ButtonProps> = (props) => <Button variant="text" {...props} startIcon={<AttachFileIcon />} />;

const AddFileButton: FC<IAddFileButton> = ({ label = 'Прикрепить файл', onChange, disabled }) => {
  const Container = DefaultAddBtn;

  return (
    <Container style={{ position: 'relative', fontSize: '14px' }} {...{ disabled }}>
      {label}
      <input
        style={{ opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        type="file"
        // multiple
        onChange={onChange}
      />
    </Container>
  );
};

export default AddFileButton;
