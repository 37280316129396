import React from 'react';

// Components
import { Paper, Typography } from '@mui/material';
import VideoPlayerCard from '../../common/components/VideoPlayerCard';
import ButtonBack from '../../common/components/ButtonBack';
import ImageSliderCard from '../../common/components/ImageSliderCard';
import LoadingBlock from '../../common/components/LoadingBlock';

// Utils
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ITrainingDetailsUIProps } from './types';

// Styles
const useStyle = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: theme.spacing(2),
    },
    headTitle: {
      textAlign: 'left',
      padding: theme.spacing(2),
      textTransform: 'uppercase',
    },
  };
});

const TrainingDetailsUI: FC<ITrainingDetailsUIProps> = ({ trainingDetailsStore, instruction, content_type, loading }) => {
  // на слуйчай если придется вернуться на логику одна страница - три представления в виде
  // текста, видео и презентации
  // const history = useHistory();
  // const { id, content_type = 'content' } = useParams();
  // const type = ['content', 'video', 'slider'];
  // const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   history.push(`/main/training_details/${id}/${type[newValue]}`);
  // };

  const { root, headTitle } = useStyle();

  const { player } = trainingDetailsStore;

  const { title, content, video, presentation_images, tags } = instruction || {};

  if (loading) return <LoadingBlock />;

  // на слуйчай если придется вернуться на логику одна страница - три представления в виде
  // текста, видео и презентации
  // const screenWidth = window.screen.width;
  // const screenHeight = window.screen.height;

  return (
    <>
      <div style={{ margin: '0 16px', display: 'flex', justifyContent: 'left' }}>
        <ButtonBack />
      </div>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        {tags.map(({ name }: any) => name).join(', ')}
      </Typography>

      {/*  на слуйчай если придется вернуться на логику одна страница - три представления в виде
        текста, видео и презентации */}
      {/* <Paper className={root}>
        <Typography className={headTitle} variant="body1">
          {title}
        </Typography>
        <Divider />
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Tabs
            value={type.indexOf(content_type)}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            <Tab icon={<DescriptionIcon />} label="Текст" />
            <Tab icon={<MovieIcon />} disabled={!video_source} label="Видео" />
            <Tab icon={<SlideshowIcon />} disabled={!presentation_images} label="Презентация" />
          </Tabs>
        </Grid>
      </Paper> */}
      <div style={{ padding: 16 }}>
        {content_type === 'content' && content && <Paper className={root} dangerouslySetInnerHTML={{ __html: content }} />}
        {content_type === 'video' && video && <VideoPlayerCard playerStore={player} url={video} />}
        {content_type === 'slides' && presentation_images && (
          <div style={{ width: 800, height: 600 }}>
            <ImageSliderCard aspectRatio={800 / 600} images={presentation_images} />
          </div>
        )}
      </div>
    </>
  );
};

export default TrainingDetailsUI;
