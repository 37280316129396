import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import OrderDetailsQueryDataHandler from './OrderDetailsQueryDataHandler';

// Types
import { FC } from 'react';
import { TOrderDetailsQueryProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const getOrderData = loader('./gqlQueries/getOrderData.gql');

const OrderListQuery: FC<TOrderDetailsQueryProps> = ({ id }) => {
  return <Query query={getOrderData} variables={{ id: +id! }} children={LoginErrorRedirector(OrderDetailsQueryDataHandler)} />;
};

export default OrderListQuery;
