import React from 'react';

// Components
import { Button } from '@mui/material';

// Utils
import FileSaver from 'file-saver';
import { utils, write } from 'xlsx';

// Types
import { FC } from 'react';
interface IExportCSVProps {
  fileName: string;
  csvData?: Array<{ [x: string]: string | number }>;
  fileExtension?: '.xlsx';
  children?: FC<any>;
  onClick?: any;
}

const ExportCSV: FC<IExportCSVProps> = ({ csvData, fileName, fileExtension = '.xlsx', children, onClick }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const DefaultButton = (props: any) => <Button {...props}>Export</Button>;

  const RenderButton = children || DefaultButton;

  const onClickHandler = onClick ? onClick(exportToCSV, fileName) : () => exportToCSV(csvData, fileName);

  return <RenderButton onClick={onClickHandler} />;
};

export default ExportCSV;
