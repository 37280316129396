import React from 'react';

// Types
import { FC } from 'react';

const NotifyReadyConfirmationDialog: FC = () => {
  return <>Отправить уведомление о готовности?</>;
};

export default NotifyReadyConfirmationDialog;
