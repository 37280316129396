import React from 'react';

// Components
import { DatePicker } from '@mui/x-date-pickers';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { IDateFieldProps } from './types';
import { DateFieldStore } from '../../../stores/classes/triggers';

const DateField: FC<IDateFieldProps> = ({
  formStore,
  name,
  label,
  variant,
  preset,
  size,
  selfRemoved = false,
  clName: { formControl } = {},
}) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const { value, onChangeHandler, isError } = formStore.set(name, 'DateFieldStore', preset) as DateFieldStore;

  return (
    <DatePicker
      sx={{ margin: 0 }}
      className={formControl}
      slotProps={{
        textField: { variant, placeholder: 'Введите дату', helperText: typeof isError.value !== 'boolean' && isError.value, size },
      }}
      label={label}
      format="dd/MM/yyyy"
      value={value}
      onChange={onChangeHandler}
      // Для валидации заполненности форм.
      onError={(error) => {
        switch (error) {
          case 'invalidDate':
            isError.updateValue('Неправильный формат даты.');
            break;

          default:
            isError.updateValue(true);
            break;
        }
      }}
      onAccept={() => {
        isError.updateValue(false);
      }}
    />
  );
};

export default observer(DateField);
