import React from 'react';

// Components
import StatisticsFilterQueryAdditional from './StatisticsFilterQueryAdditionalContainer-Fake';

// Queries
import DBInjector from './fakeDB/DBInjector';
import { getFilterData } from './fakeDB/queries';

// Types
import { FC } from 'react';
import { IStatisticsFilterQueryProps } from './fakeDB/type';

const StatisticsFilterQuery: FC<IStatisticsFilterQueryProps> = ({ DB }) => {
  const data = getFilterData(DB);
  return <StatisticsFilterQueryAdditional data={data} />;
};

export default DBInjector(StatisticsFilterQuery);
