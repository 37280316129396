import { makeObservable, observable, action } from 'mobx';

export interface IValueStore<T> {
  value: T;
  updateValue: (value: T) => void;
}

class ValueStore<T> implements IValueStore<T> {
  value: T;

  updateValue(value: T) {
    console.log(this, value);
    this.value = value;
  }

  constructor(def: T) {
    this.value = def;

    makeObservable(this, {
      value: observable,
      updateValue: action,
    });
  }
}

export default ValueStore;
