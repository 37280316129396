import { makeObservable } from 'mobx';

import ValueStore from './ValueStore';

class ToggleStore extends ValueStore<boolean> {
  constructor(preset?: boolean) {
    super(preset === undefined ? false : preset);

    makeObservable(this, {});
  }

  onOpen = () => this.updateValue(true);
  onClose = () => this.updateValue(false);
  onToggle = () => this.updateValue(!this.value);
}

export default ToggleStore;
