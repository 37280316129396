import { ApolloError } from '@apollo/client';
import { IAlert } from '../Notifier/types';

interface IParseErrorMessageReturn {
  header?: string;
  message: string;
}

export const parseJSONMessage = (message: string): IParseErrorMessageReturn => {
  try {
    return JSON.parse(message);
  } catch (error) {
    return { message };
  }
};

export const onErrorHandler = (alert: IAlert) => (error: ApolloError) => {
  const { header, message } = parseJSONMessage(error.message);
  alert(header || 'Ошибка', message);
};
