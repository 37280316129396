import React from 'react';

// Components
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, IconButton, Grid } from '@mui/material';
import Filters from './filters';
import TableSort from '../../components/FormElements/TableSort';

// Icons & Images
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import clsx from 'clsx';

// Types
import { FC, MouseEvent, ReactText } from 'react';
import { Theme } from '@mui/material';
import { IDrawedContentProps, TDrawRow, TSetContent } from './types';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    table: {
      tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.primary.light,
    },
    mainColumn: {
      width: 200,
    },
    evenRow: {
      backgroundColor: theme.palette.grey[100],
    },
    itemRow: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    totalRow: {
      backgroundColor: theme.palette.grey[300],
    },
    chbCell: {
      width: 38,
    },
  })
);

const DrawedContent: FC<IDrawedContentProps> = ({
  tableStore,
  head,
  filters,
  sort,
  rows,
  isLastPage,
  totalOnEachPage,
  columnsOrder,
  total,
  columnsWidth,
  cellsAlign,
  cellsRender,
  checkboxDisabled,
  clickOnRowHandler,
  tableRef,
  tableScrollHandler,
}) => {
  const { root, table, header, evenRow, itemRow, totalRow, chbCell } = useStyles();
  const { visibilityCheckbox, checkboxList, visibilitySpoiler, sortList } = tableStore;

  const drawRow: TDrawRow = (
    row,
    columnsOrder,
    { columnsWidth, columnsAlign, columnsRender, clickOnRowHandler, variant, color, sort } = {}
  ) => {
    const { optionValues } = row;

    const getSuitableValue = (option: any, key: ReactText) => {
      const { [key]: a, all: b } = option || {};
      return a || b;
    };

    const setContent: TSetContent = (key, value, columnsRender, optionValues) => {
      const render = getSuitableValue(columnsRender, key);

      if (render) return render(key, value, optionValues);
      return value;
    };

    return columnsOrder.map((key: ReactText, i: number) => {
      const value = row[key] as ReactText;

      const width = getSuitableValue(columnsWidth, key);
      const align = getSuitableValue(columnsAlign, key);
      const content = setContent(key, value, columnsRender, optionValues);

      const onClick = (event: MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>) => {
        if (clickOnRowHandler) clickOnRowHandler(event, optionValues || {});
      };

      const isSort = sort?.[key] !== undefined;

      return (
        <TableCell
          key={i}
          onClick={onClick}
          color="primary"
          variant={variant}
          {...(variant !== 'head' && { align })}
          style={{ width, fontSize: '1rem', color }}
        >
          {isSort ? <TableSort formStore={sortList} name={key} preset={{ def: sort?.[key] }} content={content} /> : content}
        </TableCell>
      );
    });
  };

  const drawCheckbox = (id: ReactText, optionValues: any) => {
    const { value, onChangeHandler, isDisabled } = checkboxList.set(String(id), {
      def: false,
    });

    if (checkboxDisabled && checkboxDisabled(optionValues)) isDisabled.updateValue(checkboxDisabled(optionValues));
    return <Checkbox size="small" checked={value} onChange={onChangeHandler} disabled={isDisabled.value} />;
  };

  const drawSpoilerButton = (id: ReactText) => {
    return (
      <IconButton size="small" style={{ padding: 6 }} color="primary">
        <ArrowDropDownIcon color="action" />
      </IconButton>
    );
  };

  const showActionColumn = visibilityCheckbox.value || visibilitySpoiler.value;

  const showTotal = totalOnEachPage ? true : isLastPage;

  return (
    <TableContainer className={root} ref={tableRef} onScroll={tableScrollHandler}>
      <Table className={table}>
        <TableHead>
          <TableRow className={header}>
            {showActionColumn && (
              <TableCell className={chbCell}>
                <Checkbox
                  size="small"
                  checked={checkboxList.isCheckedAll}
                  onChange={checkboxList.toggleCheckAllState}
                  indeterminate={checkboxList.isIndeterminate}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                />
              </TableCell>
            )}
            {drawRow(head, columnsOrder, {
              columnsWidth,
              columnsAlign: cellsAlign?.head || cellsAlign?.all,
              columnsRender: cellsRender?.head || cellsRender?.all,
              variant: 'head',
              color: '#ffffff',
              sort,
            })}
          </TableRow>
          <TableRow>
            {showActionColumn && <TableCell className={chbCell}></TableCell>}
            {filters &&
              columnsOrder.map((key: ReactText, i: number) => {
                if (filters[key]) {
                  const { type, values, preset, size, placeholder } = filters[key];
                  const DrawedFilter = Filters[type];

                  return (
                    <TableCell key={i}>
                      <DrawedFilter
                        formStore={tableStore.filtersList}
                        name={key}
                        values={values}
                        preset={preset}
                        size={size}
                        variant="outlined"
                        placeholder={placeholder}
                      />
                    </TableCell>
                  );
                } else return <TableCell key={i} />;
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, i: number) => {
            const { optionValues } = row;

            const { id } = optionValues;

            return (
              <TableRow key={i} className={clsx(itemRow, i % 2 ? evenRow : '')}>
                {showActionColumn && (
                  <TableCell className={chbCell} align="center">
                    <Grid container wrap="nowrap">
                      {visibilityCheckbox.value && drawCheckbox(id, optionValues)}
                      {visibilitySpoiler.value && drawSpoilerButton(id)}
                    </Grid>
                  </TableCell>
                )}
                {drawRow(row, columnsOrder, {
                  columnsWidth,
                  columnsAlign: cellsAlign?.row || cellsAlign?.all,
                  columnsRender: cellsRender?.row || cellsRender?.all,
                  clickOnRowHandler,
                })}
              </TableRow>
            );
          })}
          {showTotal && total && (
            <TableRow className={totalRow}>
              {visibilityCheckbox.value && <TableCell></TableCell>}
              {drawRow(total, columnsOrder, {
                columnsWidth,
                columnsAlign: cellsAlign?.total || cellsAlign?.all,
                columnsRender: cellsRender?.total || cellsRender?.all,
                variant: 'head',
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default observer(DrawedContent);
