import React from 'react';

// Components
import { Grid, Button, Typography } from '@mui/material';
import FeedbackListDataFilters from './FeedbackListDataFilters';
import Status from '../../common/components/Status';
import Footer from '../../common/components/Footer';
import ButtonBack from '../../common/components/ButtonBack';

// Hooks
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { useAlert } from '../../Notifier';
import { useTheme } from '@mui/styles';
import useOutsideScrollbar from '../../common/hooks/useOutsideScrollbar';

// Utils
import { getDateString } from '../../utils/dateTimeUtils';
import { onErrorHandler } from '../../utils/responseHandler';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IFeedbackListTableProps } from './types';
import { ITablePreset } from '../../common/components/tables/types';

// Queries
import { loader } from 'graphql.macro';
const addFeedback = loader('./gqlQueries/addFeedback.gql');

const FeedbackListTable: FC<IFeedbackListTableProps> = ({ feedbackListTableStore, modalStore, rows, feedbackTypes, refetch }) => {
  const history = useHistory();
  const alert = useAlert();
  const theme: Theme = useTheme();

  const [scrolledContent, handleContentScroll, OutsideScrollbar] = useOutsideScrollbar();

  const color = [theme.palette.grey[400], theme.palette.secondary.main, '#1995AD', '#FFC107', '#4A555A', '#EBCDD1', '#DC3545'];

  const [addFeedbackFn] = useMutation(addFeedback, {
    onCompleted: ({ pharmacyAddFeedback }) => {
      if (pharmacyAddFeedback) {
        alert.success(`Создано обращение № ${pharmacyAddFeedback.id}`, 'Статус открытого обращения  - "Отправлено".');
        refetch();
      }
    },
    onError: onErrorHandler(alert.error),
  });

  const aptekas: ITablePreset = {
    head: {
      id: 'Номер',
      type_name: 'Причина',
      subject: 'Тема',
      updated_at: 'Дата',
      status_name: 'Статус',
    },
    sort: {
      id: 'desc',
      updated_at: false,
    },
    options: {
      tableRef: scrolledContent,
      tableScrollHandler: handleContentScroll,
      isBeSaved: false,
      columnsWidth: {
        id: 100,
        type_name: 400,
        subject: 250,
        updated_at: 200,
        status_name: 270,
      },
      cellsRender: {
        row: {
          updated_at: (key, value, optionValues) => {
            return <>{getDateString(value)}</>;
          },
          status_name: (key, value, optionValues) => {
            const { status } = optionValues || {};

            return <Status color={color[status]} text={value as string} />;
          },
        },
      },
      clickOnRowHandler: (e, { id } = {}) => history.push(`/main/feedback_details/${id}`),
      headActionPanel: () => {
        return (
          <Button
            aria-label="add"
            variant="outlined"
            size="small"
            color="primary"
            onClick={modalStore.openHandler([
              {
                isForm: true,
                isAddedFile: true,
                type: 'AddFeedbackDialog',
                title: 'Обращение в техподдержку',
                values: feedbackTypes,
                actionButtons: [
                  {
                    text: 'Отправить',
                    action: (values, modal, { close, pinnedFiles }) => {
                      for (const key in values) {
                        if (values.hasOwnProperty(key)) {
                          const value = values[key];
                          modalStore.formElementsList.get(key)?.isError.updateValue(value === 0 ? false : !value);
                        }
                      }
                      if (!modalStore.formElementsList.withErrors) {
                        addFeedbackFn({
                          variables: { ...values, files: pinnedFiles },
                        });
                        close();
                        refetch();
                      }
                    },
                  },
                ],
              },
            ])}
          >
            Создать обращение
          </Button>
        );
      },
    },
  };

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ padding: '0 16px' }}>
        <ButtonBack route="/main/" />
      </Grid>

      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
        }}
      >
        Обратная связь
      </Typography>

      <FeedbackListDataFilters tableStore={feedbackListTableStore} rows={rows} tablePreset={aptekas} />

      <Footer children={OutsideScrollbar} />
    </>
  );
};

export default FeedbackListTable;
