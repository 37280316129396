import React from 'react';

// Components
import { Typography, Grid } from '@mui/material';
import Field from '../Field';
import ToggleCheckbox from '../ToggleCheckbox';

// Utils
import { dayName } from '.';

// Types
import { FC } from 'react';
import { IScheduleDayProps } from '../types';
import { FormElementsListStore } from '../../../../stores/classes/mapped';
import ValueStore from '../../../../stores/classes/components/ValueStore';
import { FieldStore } from '../../../../stores/classes/triggers';

const ScheduleDay: FC<IScheduleDayProps> = ({ formStore, preset }) => {
  const { id, day = 1, opens_at, closes_at, is_24hours } = preset?.def || {};

  const scheduleDayStore = formStore.set(String(day), FormElementsListStore) as FormElementsListStore;
  scheduleDayStore.set(
    'id',
    class New extends ValueStore<number> {
      constructor({ def }: { def: number }) {
        super(def);
      }
    },
    { def: id }
  );
  scheduleDayStore.set('day', FieldStore, { def: day });

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption">{dayName[day - 1]}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Grid container style={{ gap: '8px', alignItems: 'center' }}>
          <Typography variant="caption">с:</Typography>
          <Field
            formStore={scheduleDayStore}
            preset={{ def: opens_at }}
            name="opens_at"
            type="time"
            variant="outlined"
            size="small"
            fullWidth={false}
          />
          <Typography variant="caption">до:</Typography>
          <Field
            formStore={scheduleDayStore}
            preset={{ def: closes_at }}
            name="closes_at"
            type="time"
            variant="outlined"
            size="small"
            fullWidth={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <ToggleCheckbox formStore={scheduleDayStore} preset={{ def: is_24hours }} name="is_24hours" />
      </Grid>
    </Grid>
  );
};

export default ScheduleDay;
