import { makeObservable, observable, computed } from 'mobx';
import { differenceInCalendarDays, lastDayOfMonth, startOfMonth } from 'date-fns';

import * as StoreClasses from '.';
import { TStoreClassesName, TFormStoreClasses } from '../../types';
import ValueStore from '../components/ValueStore';
import { FieldStore, DateFieldStore } from '.';
import FormElementsListStore from '../mapped/FormElementsListStore';

type TArgs = [string, TStoreClassesName | object | TFormStoreClasses, any];

class DatePeriodFieldStore {
  constructor() {
    makeObservable(this, {
      fieldsList: observable,
      value: computed,
      period: computed,
      fromDateDisabled: computed,
    });
  }

  setProxy = (target: FormElementsListStore) => {
    const context = this;
    target.set = new Proxy(target.set, {
      apply(target, thisArg, args) {
        const [name, type, preset] = args as TArgs;

        let obj;
        switch (typeof type) {
          case 'string':
            obj = new StoreClasses[type](preset);
            break;

          case 'function':
            // @ts-ignore
            obj = new type(preset);
            break;

          case 'object':
            obj = type;
            break;

          default:
            break;
        }

        return target.apply(thisArg, [
          name,
          // @ts-ignore
          context[`${name}Proxy`](obj),
        ]);
      },
    });
    return target;
  };

  fieldsList: FormElementsListStore = this.setProxy(new FormElementsListStore());

  fromProxy = (target: DateFieldStore) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply(target, thisArg, args) {
        const [value, isProxy] = args;

        target.apply(thisArg, args);

        if (!isProxy && value && JSON.stringify(value) !== 'null') {
          if (context.fieldsList.get('periodType')?.value !== 'select') {
            let toDate: Date = context.presetPeriod(value, context.fieldsList.get('periodType')?.value as string, true);
            // @ts-ignore
            context.fieldsList.get('to')?.updateValue(toDate, true);
          } else if (
            context.fieldsList.get('to')?.value instanceof Date &&
            differenceInCalendarDays(context.fieldsList.get('to')?.value as number | Date, value) < 0
          ) {
            // @ts-ignore
            context.fieldsList.get('to')?.updateValue(value, true);
          }
        }
      },
    });
    return target;
  };

  toProxy = (target: DateFieldStore) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply(target, thisArg, args) {
        const [value, isProxy] = args;

        target.apply(thisArg, args);

        if (!isProxy && value && JSON.stringify(value) !== 'null') {
          if (context.fieldsList.get('periodType')?.value !== 'select') {
            let fromDate: Date = context.presetPeriod(value, context.fieldsList.get('periodType')?.value as string);
            // @ts-ignore
            context.fieldsList.get('from')?.updateValue(fromDate, true);
          } else if (
            context.fieldsList.get('from')?.value instanceof Date &&
            differenceInCalendarDays(value, context.fieldsList.get('from')?.value as number | Date) < 0
          ) {
            // @ts-ignore
            context.fieldsList.get('from')?.updateValue(value, true);
          }
        }
      },
    });
    return target;
  };

  periodTypeProxy = (target: FieldStore<string>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply(target, thisArg, args) {
        const [value] = args;
        if (value !== 'select') {
          if (!context.fieldsList.get('to')?.value)
            // @ts-ignore
            context.fieldsList.get('to')?.updateValue(new Date());
          let fromDate: Date = context.presetPeriod(context.fieldsList.get('to')?.value as Date, value);
          // @ts-ignore
          context.fieldsList.get('from')?.updateValue(fromDate, true);
        }
        target.apply(thisArg, args);
      },
    });
    return target;
  };

  presetPeriod = (maxDate: Date, preset: string, sign: boolean = false) => {
    let date: Date = new Date(maxDate);

    switch (preset) {
      case 'day':
        break;
      case 'week':
        date.setDate(date.getDate() - 6 * (sign ? -1 : 1));
        break;
      case 'month':
        // Все эти условия чтобы обойти проблему, вызванну тем что JS по умолчани при вычете месяца
        // отнимает количество дней не текущего месяца, а предыдущего, а т.к. в феврале и марте
        // разбежка получается аж до 3 дней (31 - 28), то при вычете из марта, когда текущее число
        // больше 28(29), то начальная дата становится не начало месяца (1-е), а 2-е и более число.
        if (!sign && date.getDate() === lastDayOfMonth(date).getDate()) date = startOfMonth(date);
        else {
          date.setMonth(date.getMonth() - 1 * (sign ? -1 : 1));
          if (maxDate.getMonth() !== date.getMonth()) date.setDate(date.getDate() + 1 * (sign ? -1 : 1));
        }
        break;
      case '6month':
        date.setMonth(date.getMonth() - 6 * (sign ? -1 : 1));
        date.setDate(date.getDate() + 1 * (sign ? -1 : 1));
        break;
      case 'year':
        date.setFullYear(date.getFullYear() - 1 * (sign ? -1 : 1));
        date.setDate(date.getDate() + 1 * (sign ? -1 : 1));
        break;
    }

    return date;
  };

  get fromDateDisabled() {
    return this.fieldsList.get('periodType') ? this.fieldsList.get('periodType')?.value !== 'select' : false;
  }

  get period() {
    return {
      from: this.fieldsList.get('from')?.value,
      to: this.fieldsList.get('to')?.value,
    };
  }

  get value() {
    return this.fieldsList.formValues;
  }

  // Пока существует для унификации элементов формы
  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
  updateValue = () => {};
}

export default DatePeriodFieldStore;
