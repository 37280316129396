import React from 'react';

// Components
import StatisticsTableUI from './StatisticsTableUIContainer';

// Utils
import { getDateString } from '../../utils/dateTimeUtils';

// Types
import { FC } from 'react';
import { IStatisticsTableQueryDataHandlerProps } from './types';
import FieldStore from '../../stores/classes/triggers/FieldStore';

const StatisticsTableQueryDataHandler: FC<IStatisticsTableQueryDataHandlerProps> = ({ data, statisticsStore }) => {
  const { filtersPanelList: filtersList } = statisticsStore;
  const type = filtersList.get('type') as FieldStore<string | number>;

  const { product_statistic, order_statistic, coupon_statistic, total, columnsMap, rowsMap } = data;
  let rows = product_statistic || order_statistic || coupon_statistic;

  if (type.value === 0)
    rows = rows.map((row: any) => ({
      ...row,
      optionValues: { label: rowsMap?.find(({ id }: any) => id === row.main).name },
    }));
  else if (type.value === 1)
    rows = rows.map((row: any) => ({
      ...row,
      optionValues: { label: getDateString(row.main, 'LLLL yy').toLocaleUpperCase() },
    }));

  return <StatisticsTableUI rows={rows} totalRowsCount={total} head={columnsMap} />;
};

export default StatisticsTableQueryDataHandler;
