import React from 'react';

// Components
import { Typography, Link } from '@mui/material';

// Types
import { FC, ReactText } from 'react';
import { IContentProps } from '../../../types';

const GroupOperationsMessageOnTable: FC<IContentProps<'GroupOperationsMessageOnTable'>> = ({ message, additional }) => {
  const { success, fail, href, history } = additional;

  const mapWithDivider = (array: any[], elConvert: (el: any) => any, divider?: string) =>
    array.reduce((result, el, i, { length }) => {
      result.push(elConvert(el));
      if (divider && i < length - 1) result.push(divider);
      return result;
    }, []);

  const link = (id: ReactText) => (
    <Link href="#" onClick={() => history.push(`${href}${id}`)}>
      {id}
    </Link>
  );

  return (
    <>
      {!!success.length && <Typography variant="body2">Успешно: {mapWithDivider(success, link, ', ')}</Typography>}
      {!!fail.length && <Typography variant="body2">Не успешно: {mapWithDivider(fail, link, ', ')}</Typography>}
    </>
  );
};

export default GroupOperationsMessageOnTable;
