import React from 'react';

// Components
import TrainingQueryDataHandler from './TrainingQueryDataHandler';

// Hooks
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

// Utils
import { getObjFromURI } from '../../utils/uriUtils';

// Types
import { FC } from 'react';
import { ITrainingQueryProps } from './types';

// Queries
import { loader } from 'graphql.macro';
const getInstructionsList = loader('./gqlQueries/getInstructionsList.gql');
const getTagsList = loader('./gqlQueries/getTagsList.gql');

const TrainingQuery: FC<ITrainingQueryProps> = () => {
  const { tags = '[]' } = useParams();

  const { data, loading } = useQuery(getInstructionsList, {
    variables: {
      tagIds: getObjFromURI(tags),
    },
  });
  const { data: tagsData, loading: tagsLoading } = useQuery(getTagsList);

  const instructions_list = data?.instructionList || [];
  const tags_list = tagsData?.tagList || [];

  return <TrainingQueryDataHandler data={{ instructions_list, tags_list }} loading={loading || tagsLoading} />;
};

export default TrainingQuery;
