import React from 'react';

// Hooks
import { useRef, useEffect } from 'react';

// Utils
import { makeStyles, createStyles } from '@mui/styles';
import { roundHalf } from '../../utils/other';

// Types
import { Ref, UIEventHandler } from 'react';
import { Theme } from '@mui/material';
export type TTableRef = Ref<any>;
export type TOnScrollEventHandler = UIEventHandler<HTMLDivElement>;

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 10,
      },
      '&::-webkit-scrollbar-thumb': {
        height: '10px',
        background: theme.palette.secondary.main,
        outline: '1px solid slategrey',
        borderRadius: '2px',
      },
    },
  })
);

const useOutsideScrollbar = (): [React.RefObject<HTMLDivElement>, TOnScrollEventHandler, JSX.Element] => {
  const { root } = useStyles();

  const scrolledContent = useRef<HTMLDivElement>(null);
  const outsideScrollbar = useRef<HTMLDivElement>(null);
  const outsideScrollbarNested = useRef<HTMLDivElement>(null);

  const handleScroll: TOnScrollEventHandler = (event) => {
    if (scrolledContent.current && outsideScrollbar.current)
      scrolledContent.current.scrollLeft = roundHalf(
        (scrolledContent.current.offsetWidth / outsideScrollbar.current.offsetWidth) * event.currentTarget.scrollLeft
      );
  };

  const handleContentScroll: TOnScrollEventHandler = (event) => {
    if (scrolledContent.current && outsideScrollbar.current)
      outsideScrollbar.current.scrollLeft = roundHalf(
        (outsideScrollbar.current.offsetWidth / scrolledContent.current.offsetWidth) * event.currentTarget.scrollLeft
      );
  };

  useEffect(() => {
    if (outsideScrollbarNested.current && scrolledContent.current && outsideScrollbar.current) {
      outsideScrollbarNested.current.style.width = `${
        (scrolledContent.current.scrollWidth / scrolledContent.current.offsetWidth) * outsideScrollbar.current.offsetWidth
      }px`;
    }
  }, [scrolledContent, outsideScrollbar, outsideScrollbarNested]);

  const OutsideScrollbar = (
    <div ref={outsideScrollbar} onScroll={handleScroll} className={root}>
      <div ref={outsideScrollbarNested} style={{ height: '1px' }} />
    </div>
  );

  return [scrolledContent, handleContentScroll, OutsideScrollbar];
};

export default useOutsideScrollbar;
