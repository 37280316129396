import StoreInjector from '../../stores/StoreInjector';
import OrderListQueryDataHandler from './OrderListQueryDataHandler';

const mapInjectedStore = ['authStore'];

const OrderListQueryDataHandlerContainer = StoreInjector(
  OrderListQueryDataHandler,
  mapInjectedStore
);

export default OrderListQueryDataHandlerContainer;
