import React from 'react';

// Components
import TableController from '../../common/components/tables/TableController';
import Footer from '../../common/components/Footer';

// Hooks
import { useParams } from 'react-router';
import useOutsideScrollbar from '../../common/hooks/useOutsideScrollbar';

// Types
import { FC } from 'react';
import { IStatisticsTableProps } from './types';
import { ITablePreset, ITablePresetOptions } from '../../common/components/tables/types';

const StatisticsTable: FC<IStatisticsTableProps> = ({ statisticsStore, rows, totalRowsCount, head }) => {
  const { type: tableType = 'medicine' } = useParams();

  const [scrolledContent, handleContentScroll, OutsideScrollbar] = useOutsideScrollbar();

  const titles: { [x: string]: string } = {
    medicine: 'Отчет по препаратам',
    orders: 'Отчет по заказам',
    coupon: 'Отчет по промокодам',
  };

  const options: { [x: string]: ITablePresetOptions } = {
    medicine: {
      totalCountColumns: 'all',
      columnsWidth: {
        main: 200,
        all: 100,
      },
      cellsAlign: {
        all: {
          main: 'left',
          all: 'right',
        },
      },
      cellsRender: {
        row: {
          main: (key, value, { label } = {}) => label,
        },
      },
    },
    orders: {
      totalCountColumns: 'all',
      columnsWidth: {
        main: 200,
      },
      cellsRender: {
        row: {
          main: (key, value, { label } = {}) => label,
        },
      },
    },
    coupon: {
      totalCountColumns: 'all',
      columnsWidth: {
        main: 200,
      },
      cellsRender: {
        row: {
          main: (key, value, { label } = {}) => label,
        },
      },
    },
  };

  const preset: ITablePreset = {
    title: titles[tableType],
    head,
    options: {
      tableRef: scrolledContent,
      tableScrollHandler: handleContentScroll,
      ...options[tableType],
    },
  };

  return (
    <>
      <TableController tableStore={statisticsStore} isPaginInBackend totalRowsCount={totalRowsCount} rows={rows} {...preset} />

      <Footer children={OutsideScrollbar} />
    </>
  );
};

export default StatisticsTable;
