import { makeObservable } from 'mobx';

import ValueStore from '../components/ValueStore';
import { TriggerStoreClasses, TriggerStoreClassNames } from '../triggers';
const StoreClasses = { ...TriggerStoreClasses };

interface IProxyPreset {
  proxy?: (target: TriggerStoreClasses) => TriggerStoreClasses;
}

class MapStore<V, K = string | number> extends ValueStore<Map<K, V>> {
  constructor() {
    super(new Map<K, V>());

    makeObservable(this, {});
  }

  set(
    name: K,
    type: TriggerStoreClasses | TriggerStoreClassNames | MapStore<TriggerStoreClasses>,
    { proxy, ...preset }: IProxyPreset | undefined = {}
  ): V {
    if (!this.has(name)) {
      let obj;
      switch (typeof type) {
        case 'string':
          obj = new StoreClasses[type](preset as any);
          break;

        case 'function':
          // @ts-ignore
          obj = new type(preset);
          break;

        default:
          break;
      }

      if (proxy) obj = proxy(obj);

      this.value.set(name, obj);
    }
    // по умолчанию метод get может вернуть undefined, но по условию выше при выполнении метода set
    // установит значение с нужным ключом если его нет и вернет его, либо он уже имеет значение
    // с таким ключем, поэтому принудительно установлен тип возвращаемого элемента
    return this.get(name) as V;
  }

  update(name: K, value: V) {
    return this.value.set(name, value).get(name);
  }

  get = (name: K) => this.value.get(name);

  has = (name: K) => this.value.has(name);

  delete = (name: K) => this.value.delete(name);

  clear = () => this.value.clear();

  size = () => this.value.size;
}

export default MapStore;
