export const extFromType = (type: string) => type.match(/[^\/]+$/g)?.[0];

export const extFileValidateToArr = (extFileValidate: any) => {
  let arr: string[] = [];
  for (const type in extFileValidate) {
    arr = [...arr, ...extFileValidate[type]];
  }
  return arr;
};

export const getType = (type: string) => type.match(/[^\/]+/)?.[0];
