import React from 'react';

// Components
import { Grid, Typography } from '@mui/material';
import CloseButton from '../Buttons/CloseButton';

// Hooks
import { useState } from 'react';

// Utils
import { makeStyles } from '@mui/styles';
import { getType } from './utils';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IUploadFileList } from './types';
interface IPrev {
  type: string;
  src: string;
  name: string;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  errorTxt: { textAlign: 'left', color: theme.palette.error.main },
}));

const UploadFileList: FC<IUploadFileList> = ({ itemList, removeItemFromList, addItemInListError }) => {
  const { errorTxt } = useStyles();

  const [prev, setPrev] = useState<IPrev | null>(null);
  const { type, src } = prev || {};
  return (
    <Grid container direction="column" style={{ padding: 8 }}>
      {prev && (
        <Grid item style={{ position: 'relative', width: '100%', margin: '8px 0 4px' }}>
          {getType(type || '') === 'video' ? (
            <video controls key={src} style={{ width: '100%' }} {...{ src }} />
          ) : (
            <img key={src} style={{ width: '100%' }} {...{ src }} />
          )}
        </Grid>
      )}

      {!!itemList.length && (
        <Grid container spacing={1}>
          {itemList.map((item: File, i) => {
            const { type, name } = item;
            const src = URL.createObjectURL(item);
            const isNameMatch = prev && name === prev.name;
            return (
              <Grid
                key={name + i}
                item
                style={{
                  position: 'relative',
                }}
              >
                <button
                  type="button"
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 0,
                    borderWidth: 0,
                    outline: 'unset',
                    cursor: 'pointer',
                  }}
                  onClick={() => setPrev(isNameMatch ? null : { type, src, name })}
                >
                  {getType(type || '') === 'video' ? (
                    <video key={src} style={{ height: '100%' }} {...{ src }} />
                  ) : (
                    <img key={src} style={{ width: '100%' }} {...{ src }} />
                  )}
                </button>
                <div style={{ position: 'absolute', right: 8, top: 8 }}>
                  <CloseButton
                    onClick={() => {
                      removeItemFromList(item);
                      if (isNameMatch) setPrev(null);
                    }}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {!!addItemInListError && (
        <Typography variant="caption" component="p" className={errorTxt}>
          {addItemInListError.message}
        </Typography>
      )}
    </Grid>
  );
};

export default UploadFileList;
