import React from 'react';

// Hooks
import { useHistory } from 'react-router';

// Components
import { Card, CardActionArea, CardMedia, Typography, Grid } from '@mui/material';

// Icons & Images
import { DescriptionOutlined as DescriptionIcon, Slideshow as MovieIcon, Filter as SlideshowIcon } from '@mui/icons-material';

// Utils
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

// Constants
import { uriCore } from '../../../client/Client';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { ITrainingCardProps } from '../types';

// Styles
const useStyle = makeStyles((theme: Theme) => ({
  text: {
    padding: theme.spacing(1),
    margin: 0,
    alignItems: 'center',
  },
  tagText: {
    textTransform: 'uppercase',
  },
  descText: {
    fontWeight: 300,
  },
  icon: {
    fontSize: theme.spacing(5),
    margin: theme.spacing(1),
  },
}));

const TrainingCard: FC<ITrainingCardProps> = ({ id, title, preview_image, description, contentType, tags }) => {
  const history = useHistory();

  const { text, tagText, descText, icon } = useStyle();

  const iconType: { [x: string]: any } = {
    content: DescriptionIcon,
    video: MovieIcon,
    slides: SlideshowIcon,
  };

  const Icon = iconType[contentType];

  return (
    <Card>
      <CardActionArea onClick={() => history.push(`/main/training_details/${id}/${contentType}`)} title={title}>
        <CardMedia component="img" image={uriCore + preview_image} title={title} />
        <Grid container spacing={2} wrap="nowrap">
          <Grid item style={{ display: 'flex', flexGrow: 1 }}>
            <Grid container className={text} component="p">
              {tags.map(({ name }, i) => (
                <Typography key={name + i} className={tagText} variant="body2" component="span">
                  {name}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Icon fontSize="large" className={icon} />
          </Grid>
        </Grid>
        <Typography className={clsx(text, descText)} variant="body2">
          {description}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default TrainingCard;
