import React from 'react';

// Components
import { TextField, IconButton, InputAdornment } from '@mui/material';
import DatePicker from 'react-datepicker';
import { DatePeriodPickerFieldStore } from '../../../stores/classes/triggers';

// Icons & Images
import { Backspace as BackspaceIcon, Event as EventIcon } from '@mui/icons-material';

// Hooks
import { useEffect } from 'react';

// Utils
import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import ru from 'date-fns/locale/ru';

// Types
import { FC } from 'react';
import { TDatePeriodPickerFieldProps } from './types';

// Styles
import 'react-datepicker/dist/react-datepicker.css';

const DatePeriodPickerField: FC<TDatePeriodPickerFieldProps> = ({
  formStore,
  name,
  preset = [null, null],
  selfRemoved,

  placeholder,
  ...fieldProps
}) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const store = formStore.set(name, 'DatePeriodPickerFieldStore', preset) as DatePeriodPickerFieldStore;

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <TextField
      value={value}
      onSelect={onClick}
      ref={ref}
      fullWidth
      placeholder={placeholder || 'Введите период дат'}
      {...fieldProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear input"
              size="small"
              onClick={store.clear}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              <BackspaceIcon />
            </IconButton>
            <IconButton
              aria-label="show calendar"
              size="small"
              onClick={onClick}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              <EventIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ));

  return (
    <DatePicker
      selectsRange={true}
      startDate={store.value[0]}
      endDate={store.value[1]}
      onChange={(date) => store.updateValue(date)}
      customInput={<CustomInput />}
      dateFormat="dd/MM/yyyy"
      locale={ru}
      withPortal
    />
  );
};

export default observer(DatePeriodPickerField);
