import React from 'react';

// Components
import TableUI from './TableUI';

// Hooks
import { useTheme } from '@mui/material/styles';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { ITableControllerProps, TCountTotal, TStrNum, TSetCsvData } from './types';

const TableController: FC<ITableControllerProps> = ({
  tableStore,
  title,
  rows,
  isPaginInBackend = false,
  totalRowsCount,
  head,
  filters,
  sort,
  saveTableHandler,
  options,
  maxWidth,
}) => {
  const { settingsIsOpen, totalOnEachPage, saveCurrentPage, pagination } = tableStore;

  const { page, perPage } = pagination;

  const { totalCountColumns, columnsOrder = Object.keys(head) } = options || {};

  // Строки
  const rowsAll = rows;
  const rowsCurrent = isPaginInBackend
    ? rows
    : rowsAll.slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);

  // Подсчет итога
  const countTotal: TCountTotal = (rows, columnsOrder, countColumns) => {
    const total: TStrNum = {};

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];

      for (let i2 = 0; i2 < columnsOrder.length; i2++) {
        const key = columnsOrder[i2];

        if (key === 'main') total[key] = 'Итого:';
        else if (countColumns === 'all' || countColumns.includes(key))
          total[key] = (total[key] ? Number(total[key]) : 0) + Number(row[key]);
        else total[key] = '';
      }
    }
    return total;
  };

  const total: TStrNum | undefined = totalCountColumns
    ? countTotal(totalOnEachPage.value ? rowsCurrent : rowsAll, columnsOrder, totalCountColumns)
    : undefined;

  // Панель настроек
  const theme = useTheme();
  const width = 300;
  const widthPanel = settingsIsOpen.value ? width : 0;

  // Сохранение в Excel
  const setCsvData: TSetCsvData = (rows, head, columnsOrder, total) => {
    const setRow = (row: any) => {
      return columnsOrder.reduce((result: any, key) => {
        // "Русификация" заголовков в сохраненном Excel файле значениями из head.
        const k = head[key];
        if (k) {
          result[k] = row[key];
          return result;
        }
      }, {});
    };

    const result = rows.map((row) => {
      return setRow(row);
    });

    if (total) result.push(setRow(total));

    return result;
  };
  const saveCsvDataHandler = (exportToCSV: any, fileName: string) =>
    saveTableHandler
      ? () => saveTableHandler(setCsvData, exportToCSV, fileName)
      : () => {
          // Заведен в функцию с замыканием чтобы csvData пересчитываласть только при клике на кнопку,
          // а не при каждом действии, включая набор текста в поля ввода.
          const csvData = setCsvData(
            saveCurrentPage.value ? rowsCurrent : rowsAll,
            head,
            columnsOrder,
            // Схема присоединения итога к странице:
            // --Сохр.на-тек.стра--|--Итог-на-тек.стра--|--Стр.только-одна---|--Результат---------------------
            // -------False--------|-------False--------|--Не-имеет-значения-|--rowsAll-(+)-totalAll----------
            // -------True---------|-------False--------|-------False--------|--rowsCurrent-------------------
            // -------True---------|-------False--------|-------True---------|--rowsCurrent-(+)-totalAll------
            // -------False--------|-------True---------|--Не-имеет-значения-|--rowsAll-(+)-count(totalAll)---
            // -------True---------|-------True---------|--Не-имеет-значения-|--rowsCurrent-(+)-totalCurrent--
            !saveCurrentPage.value && totalOnEachPage.value
              ? totalCountColumns && countTotal(rowsAll, columnsOrder, totalCountColumns)
              : saveCurrentPage.value && !totalOnEachPage.value
              ? // Проверка является ли страница единственной
                Math.floor(rowsAll.length / perPage.value) === 0
                ? total
                : undefined
              : total
          );
          exportToCSV(csvData, fileName);
        };

  return (
    <TableUI
      tableStore={tableStore}
      totalRowsCount={isPaginInBackend ? (totalRowsCount as number) : rowsAll.length}
      {...{ head, title, filters, sort, total, saveCsvDataHandler, maxWidth, options }}
      rows={rowsCurrent}
    />
  );
};

export default observer(TableController);
