import React from 'react';
import { DB } from './DBProvider';

export default function storeInjector(Component: any) {
  function getDisplayName(DBInjector: any) {
    return DBInjector.displayName || DBInjector.name || 'Component';
  }

  const DBInjector = (props: any) => {
    return <DB.Consumer>{(DB) => <Component {...props} DB={DB} />}</DB.Consumer>;
  };

  DBInjector.displayName = `DBInjector(${getDisplayName(DBInjector)})`;

  return DBInjector;
}
