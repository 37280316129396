import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import FeedbackQueryDataHandler from './FeedbackQueryDataHandler';

// Hooks
import { useParams } from 'react-router';

// Types
import { FC } from 'react';

// Queries
import { loader } from 'graphql.macro';
const loadFeedbackData = loader('./gqlQueries/loadFeedbackData.gql');

const FeedbackQuery: FC = () => {
  const { id } = useParams();

  return <Query query={loadFeedbackData} variables={{ id: +id! }} children={LoginErrorRedirector(FeedbackQueryDataHandler)} />;
};

export default FeedbackQuery;
