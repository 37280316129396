import React from 'react';

// Components
import AptekaListTable from './AptekaListTableContainer';

// Types
import { FC } from 'react';
import { IAptekaListQueryDataHandlerProps } from './types';

const AptekaListQueryDataHandler: FC<IAptekaListQueryDataHandlerProps> = ({ data, refetch }) => {
  const rows =
    data?.pharmaciesListW?.map(
      ({ id, name: pharmacy_name, location, management, pharmacists, pharmacy_additional_contacts, legal_entity, ...row }) => {
        const { address, city } = location || {};
        const { is_consists_in_loyalty_program, is_checked_by_manager, is_belongs_to_network, is_manageable } = management || {};

        return {
          ...row,
          pharmacy_name,
          city,
          address: `г. ${city?.name}, ${address}`,
          is_consists_in_loyalty_program: Number(is_consists_in_loyalty_program),
          is_checked_by_manager: Number(is_checked_by_manager),
          is_belongs_to_network: Number(is_belongs_to_network),
          ...pharmacists[0],
          ...pharmacy_additional_contacts[0],
          id,
          inn: legal_entity?.number,
          optionValues: {
            id,
            is_manageable,
            isPharmacistAuth: !!pharmacists.length,
            isNotBelongToNetwork: !is_belongs_to_network,
          },
        };
      }
    ) || [];

  return <AptekaListTable rows={rows} refetch={refetch} />;
};

export default AptekaListQueryDataHandler;
