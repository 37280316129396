import React from 'react';
import { enqueueSnackbar } from 'notistack';
// Components
import { CloseSnackbarButton } from '..';
import AlertCard from '../layouts/AlertCard/AlertCard';
// Types
import { IAlert } from '../types';

const useAlert = () => {
  const enqueueAlert: IAlert = (title, message, options) =>
    enqueueSnackbar(message, {
      layout: AlertCard,
      title,
      action: (id) => <CloseSnackbarButton id={id} />,
      ...options,
      variant: 'customSnackbar',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      hideIconVariant: true,
    });

  const success: IAlert = (title, message, options) =>
    enqueueAlert(title, message, {
      ...options,
      type: 'success',
      style: {
        backgroundColor: '#45a047',
      },
    });

  const error: IAlert = (title, message, options) =>
    enqueueAlert(title, message, {
      ...options,
      type: 'error',
    });

  const warning: IAlert = (title, message, options) =>
    enqueueAlert(title, message, {
      ...options,
      type: 'warning',
    });

  const info: IAlert = (title, message, options) =>
    enqueueAlert(title, message, {
      ...options,
      type: 'info',
    });

  const groupOperations: IAlert<'GroupOperationsMessageOnTable'> = (title, message, options) => {
    const { success = [] } = options?.layoutContent?.additionalContentProps || {};
    return enqueueAlert<'GroupOperationsMessageOnTable'>(title, message, {
      ...options,
      type: success.length ? 'success' : 'error',
      layoutContent: {
        contentComponent: 'GroupOperationsMessageOnTable',
      },
    });
  };

  return {
    success,
    error,
    warning,
    info,
    groupOperations,
  };
};

export default useAlert;
