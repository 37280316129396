import React from "react";
import { useQuery } from "@apollo/client";

type Props = { [x: string]: any };

export default function Query({ children: Children, query, ...options }: Props) {
  const { error, ...result } = useQuery(query, { ...options, errorPolicy: "all" });

  return Children && result ? <Children {...result} error={error} /> : null;
}
