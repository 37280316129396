import React from 'react';

// Components
import { Button } from '@mui/material';

// Icons & Images
import { ArrowBack } from '@mui/icons-material';

// Hooks
import { useHistory } from 'react-router';

// Types
import { FC } from 'react';
interface IButtonBackProps {
  route?: string;
  text?: string;
}

const ButtonBack: FC<IButtonBackProps> = ({ route, text }) => {
  const history = useHistory();

  return (
    <Button variant="contained" onClick={() => (route ? history.push(route) : history.goBack())} startIcon={<ArrowBack />}>
      {text || 'Назад'}
    </Button>
  );
};

export default ButtonBack;
