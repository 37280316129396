import React from 'react';

// Components
import FeedbackListTable from './FeedbackListTableContainer';

// Types
import { FC } from 'react';
import { TFeedbackQueryDataHandlerProps, IFeedBackParam } from './types';

const FeedbackQueryDataHandler: FC<TFeedbackQueryDataHandlerProps> = ({ data, loading, refetch }) => {
  const feedbackTypes =
    data?.feedback_types.map((el: { [x: string]: string | number }) => {
      return {
        value: el.id,
        label: el.name,
      };
    }) || [];

  const rows =
    data?.pharmacyFeedbackList
      .map((el: IFeedBackParam): any => {
        const { id, type_name, subject, updated_at, status_name, ...other } = el;
        return { id, type_name, subject, updated_at, status_name, optionValues: { id, ...other } };
      })
      .reverse() || [];

  const resultData = {
    rows,
    feedbackTypes,
  };
  const queryData = { loading, refetch };

  return <FeedbackListTable {...resultData} {...queryData} />;
};

export default FeedbackQueryDataHandler;
