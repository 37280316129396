import React from 'react';

// Components
import LandingUIContainer from './LandingUIContainer';

// Types
import { FC } from 'react';

const Landing: FC = () => {
  return <LandingUIContainer />;
};

export default Landing;
