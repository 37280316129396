import React from 'react';

// Components
import Query from '../../client/Query';
import LoginErrorRedirector from '../../common/components/LoginErrorRedirector';
import OrderListQueryDataHandler from './OrderListQueryDataHandlerContainer';

// Types
import { FC } from 'react';
import { loader } from 'graphql.macro';
const getOrderlist = loader('./gqlQueries/getOrderList.gql');

const OrderListQuery: FC = () => {
  return <Query query={getOrderlist} pollInterval={60000} children={LoginErrorRedirector(OrderListQueryDataHandler)} />;
};

export default OrderListQuery;
