import React from 'react';

// Components
import { Fab, Typography, Tooltip } from '@mui/material';

// Utils
import { makeStyles } from '@mui/styles';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IActionSecondaryButton } from '../types';

// Styles
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 300,
    textAlign: 'left',
  },
  fab: {
    marginRight: theme.spacing(2),
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    maxWidth: 247,
    padding: '16px',
  },
}));

const SecondaryButton: FC<IActionSecondaryButton> = ({ action, icon: Icon, text, tooltip, disabled, color = 'primary' }) => {
  const { root, fab, ...tooltipClasses } = useStyle();

  const button = (
    <Typography className={root}>
      <Fab onClick={action} size="medium" className={fab} color={color} disabled={disabled}>
        <Icon />
      </Fab>
      {text}
    </Typography>
  );

  return tooltip ? (
    <Tooltip title={tooltip} classes={tooltipClasses}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default SecondaryButton;
