import React from 'react';

// Components
import { FormControlLabel, Grid } from '@mui/material';
import ToggleCheckbox from '../../FormElements/ToggleCheckbox';

// Utils
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IContentProps } from '../types';
import CheckboxListStore from '../../../../stores/classes/mapped/CheckboxListStore';

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'block',
  },
}));

const AptekaListSaveDialog: FC<IContentProps> = ({ modalStore }) => {
  const { label } = useStyles();
  const { formElementsList } = modalStore;
  const main = formElementsList.set('main', CheckboxListStore) as CheckboxListStore;
  const contacts = formElementsList.set('pharmacy_manager_contacts', CheckboxListStore) as CheckboxListStore;

  return (
    <Grid container>
      <Grid item xs={6} direction="column">
        <FormControlLabel className={label} control={<ToggleCheckbox formStore={main} name="pharmacy_name" />} label="Название аптеки" />
        <FormControlLabel className={label} control={<ToggleCheckbox formStore={main} name="address" />} label="Адрес аптеки" />
        <FormControlLabel className={label} control={<ToggleCheckbox formStore={main} name="inn" />} label="ИНН" />
        <FormControlLabel
          className={label}
          control={<ToggleCheckbox formStore={main} name="is_checked_by_manager" />}
          label="Статус проверки"
        />
        <FormControlLabel
          className={label}
          control={<ToggleCheckbox formStore={main} name="is_belongs_to_network" />}
          label="Статус Принадлежность к АС"
        />
      </Grid>
      <Grid item xs={6} direction="column">
        <FormControlLabel
          className={label}
          control={<ToggleCheckbox formStore={main} name="is_consists_in_loyalty_program" />}
          label="Статус Участие в ПЛ"
        />
        <FormControlLabel className={label} control={<ToggleCheckbox formStore={main} name="auth_data" />} label="Авторизационные данные" />
        <FormControlLabel
          className={label}
          control={<ToggleCheckbox formStore={main} name="updated_at" />}
          label="Дата последнего изменения"
        />
        <FormControlLabel
          className={label}
          control={<ToggleCheckbox formStore={contacts} name="contacts" />}
          label="Контакты заведующего аптекой"
        />
      </Grid>
    </Grid>
  );
};

export default observer(AptekaListSaveDialog);
