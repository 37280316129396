import React, { FC } from 'react';
// MobX
import { observer } from 'mobx-react';

// ТИПИЗАЦИЯ
import { ILegendProps } from './types';

const Legend: FC<ILegendProps> = ({ tableStore, legendElements }) => {
  const { legendList } = tableStore;
  return (
    <div style={{ padding: '16px' }}>
      {legendList.map((el: string, i: number) => {
        const LegendElement = legendElements[el];
        return <LegendElement key={i} />;
      })}
    </div>
  );
};

export default observer(Legend);
