import React from 'react';

// Components
import TrainingDetailsQuery from './TrainingDetailsQuery';
import LoadingBlock from '../../common/components/LoadingBlock';

// Types
import { FC } from 'react';
import { ITrainingDetailsProps } from './types';

const TrainingDetails: FC<ITrainingDetailsProps> = ({ authStore }) => {
  const { role } = authStore;
  if (!role.value) return <LoadingBlock />;

  return <TrainingDetailsQuery />;
};

export default TrainingDetails;
