import React from 'react';
import { SnackbarProvider } from 'notistack';

import CustomSnackbar from './CustomSnackbar';
// Types
import { FC } from 'react';
import { TSnackbarType, IContent, TContentVariantNames } from './types';
declare module 'notistack' {
  interface VariantOverrides {
    customSnackbar: {
      /**
       * Шаблон контента, который рендерится как обертка над содержимым поля message если содержание
       * сообщения сложнее чем то, что предоставляет поле message.
       */
      layout: FC<any>;
      /**
       * Вариант снэкбара.
       */
      type?: TSnackbarType;
      /**
       * Заголовок в шаблоне снэкбара.
       */
      title: string;
      /**
       * Вариант снэкбара.
       */
      layoutContent?: IContent<TContentVariantNames>;
    };
  }
}

const NotifierProvider = ({ children }: any) => {
  return (
    <SnackbarProvider
      Components={{
        customSnackbar: CustomSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotifierProvider;
