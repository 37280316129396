import React from 'react';
import { observer } from 'mobx-react';
import Store from './Store';

export default function storeInjector(
  Component: any,
  mapInjectedStore: Array<string | Array<string>>
) {
  let ObserverComponent = observer<any>(Component);

  let injectedStores = mapInjectedStore.reduce(
    (result: { [x: string]: any }, prop: string | Array<string>) => {
      if (Array.isArray(prop)) {
        // @ts-ignore
        result[prop[1]] = Store[prop[0]];
      } else {
        // @ts-ignore
        result[prop] = Store[prop];
      }
      return result;
    },
    {}
  );

  function getDisplayName(StoreInjector: any) {
    return StoreInjector.displayName || StoreInjector.name || 'Component';
  }

  const StoreInjector = (props: any) => {
    return <ObserverComponent {...props} {...injectedStores} />;
  };

  StoreInjector.displayName = `StoreInjector(${getDisplayName(StoreInjector)})`;

  return StoreInjector;
}
