import React from 'react';

// Components
import FeedbackQuery from './FeedbackQueryContainer';

// Types
import { FC } from 'react';

const FeedbackList: FC = () => {
  return <FeedbackQuery />;
};

export default FeedbackList;
