import { makeObservable } from 'mobx';

import MapStore from './MapStore';
import SortStore from '../triggers/SortStore';

// Types
import { ReactText } from 'react';
import { SortDirection } from '@mui/material';
interface ISortValues {
  [x: string]: SortDirection;
  [y: number]: SortDirection;
}

class SortStoreList extends MapStore<SortStore> {
  constructor() {
    super();
    makeObservable(this, {});
  }

  _updateSortStoreValueProxy = (target: SortStore) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply: (target, thisArg, args) => {
        const [value] = args;
        if (value && !thisArg.value) context.value.forEach((sort) => sort.updateValue(false));

        target.apply(thisArg, args);
      },
    });

    return target;
  };

  set = (name: ReactText, type: any, preset?: any) => super.set(name, SortStore, { proxy: this._updateSortStoreValueProxy, ...preset });

  get values(): ISortValues {
    const values: ISortValues = {};
    for (const key in Object.fromEntries(this.value)) {
      const el = this.get(key);

      if (el) values[key] = el.value;
    }

    return values;
  }
}

export default SortStoreList;
