import ChangePasswordDialog from "./ChangePasswordDialog";
import MarkAsUnpackedDialog from "./MarkAsUnpackedDialog";
import AffiliationDialog from "./AffiliationDialog";
import AddFeedbackDialog from "./AddFeedbackDialog";
import NotifyReadyConfirmationDialog from "./NotifyReadyConfirmationDialog";
import OrderRefuseDialog from "./OrderRefuseDialog";
import SelectEpsondProductsDialog from "./SelectEpsondProductsDialog";
// Выдача заказа
import IssueAnOrderDialog from "./IssueAnOrderDialog";
import OrderSelectDialog from "./OrderSelectDialog";
import OrderNotFoundDialog from "./OrderNotFoundDialog";
import OrderNotReadyToIssueDialog from "./OrderNotReadyToIssueDialog";
import OrdersFoundButNotReady from "./OrdersFoundButNotReady";
// Добавление аптеки
import AddAptekaFillingFields from "./AddAptekaDialog/AddAptekaFillingFields";
import AddAptekaSelect from "./AddAptekaDialog/AddAptekaSelect";
// Не Актуален список аптек
import NotAccessGrantedDialog from "./NotAccessGrantedDialog";
// Нет авторизационных данных для аптеки, входящей в ПЛ
import AddPharmacistAuthDataDialog from "./AddPharmacistAuthDataDialog";
import AddPharmacistsAuthDataDialog from "./AddPharmacistsAuthDataDialog";
import AptekaListSaveDialog from "./AptekaListSaveDialog";
import ExcludeFromLPDialog from "./ExcludeFromLPDialog";
import SaveFileDialog from "./SaveFileDialog";

const Modals = {
  ChangePasswordDialog,
  MarkAsUnpackedDialog,
  AffiliationDialog,
  AddFeedbackDialog,
  NotifyReadyConfirmationDialog,
  OrderRefuseDialog,
  SelectEpsondProductsDialog,
  // Выдача заказа
  IssueAnOrderDialog,
  OrderSelectDialog,
  OrderNotFoundDialog,
  OrderNotReadyToIssueDialog,
  OrdersFoundButNotReady,
  // Добавление аптеки
  AddAptekaFillingFields,
  AddAptekaSelect,
  // Не актуале список апте
  NotAccessGrantedDialog,
  // Нет авторизационных данных для аптеки, входящей в ПЛ
  AddPharmacistAuthDataDialog,
  AddPharmacistsAuthDataDialog,
  AptekaListSaveDialog,
  ExcludeFromLPDialog,
  SaveFileDialog,
};

export type TModals = keyof typeof Modals;

export default Modals;
