import { SortDirection } from '@mui/material';
// types
import { IScheduleInterval } from '../common/components/FormElements/types';

type TMask = { indKeyName?: string; elKeyName?: string };

export const arrToArrObjConverter = (arr: any[], { indKeyName = 'id', elKeyName = 'name' }: TMask = {}) => {
  return arr.map((el, ind) => ({
    [indKeyName]: ind,
    [elKeyName]: el,
  }));
};

export const sortElements = (elements: any[], { reverse = false } = {}) =>
  elements.sort((a: any, b: any) => {
    if (Number(a) < Number(b)) {
      return reverse ? 1 : -1;
    }
    if (Number(a) > Number(b)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

export const sortObjs = (objArr: any[], { reverse = false, fieldName = 'id' } = {}) =>
  [...objArr].sort(({ [fieldName]: a }: any, { [fieldName]: b }: any) => {
    if (Number(a) < Number(b)) {
      return reverse ? 1 : -1;
    }
    if (Number(a) > Number(b)) {
      return reverse ? -1 : 1;
    }
    return 0;
  });

interface IObj {
  [x: string]: any;
}

type TCompareTwoObjects = (a: IObj, b: IObj) => boolean;

export const compareTwoObjects: TCompareTwoObjects = (a, b) => {
  for (const key in a) {
    if (Object.prototype.hasOwnProperty.call(a, key)) {
      const verif = b[key] !== a[key];
      if (verif) return false;
    }
  }
  return true;
};

export const sortBy = (order: SortDirection, arr: any[], sortFn: (reverse: boolean) => any) => {
  switch (order) {
    case 'asc':
      return arr.sort(sortFn(false));

    case 'desc':
      return arr.sort(sortFn(true));

    default:
      return arr;
  }
};

interface IGroupeScheduleIntervals {
  period: number[];
  is_24hours?: boolean;
  opens_at?: string;
  closes_at?: string;
}

export const groupingScheduleIntervals = (inervals: IScheduleInterval[]) =>
  inervals.reduce((result, inerval) => {
    const { day, is_24hours, opens_at, closes_at } = inerval;
    if (result.length) {
      const lastIndex = result.length - 1;
      const last = result[lastIndex];
      const { period, is_24hours: is_24hours_r, opens_at: opens_at_r, closes_at: closes_at_r } = last;
      if (
        (is_24hours_r && is_24hours === is_24hours_r) ||
        (is_24hours === is_24hours_r && opens_at === opens_at_r && closes_at === closes_at_r)
      )
        result[lastIndex] = { ...last, period: [period[0], day] };
      else result.push({ period: [day], is_24hours, opens_at, closes_at });
    } else result.push({ period: [day], is_24hours, opens_at, closes_at });
    return result;
  }, [] as IGroupeScheduleIntervals[]);

export const roundHalf = (num: number) => Math.round(num * 2) / 2;
