import React from 'react';

// Components
import { Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, Divider } from '@mui/material';
import { AddFileButton, UploadFileList, useUploadFile } from '../AddFile';
import Modals from './modals';

// Hooks
import { useTheme } from '@mui/material/styles';

// Utils
import { makeStyles } from '@mui/styles';
import StoreInjector from '../../../stores/StoreInjector';

// Types
import { FC, FormEvent } from 'react';
import { Theme } from '@mui/material';
import { IModalWindowProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  hightOnContent: { overflow: 'unset' },
}));

const ModalWindow: FC<IModalWindowProps> = ({ modalStore }) => {
  const { hightOnContent } = useStyles();
  const theme: Theme = useTheme();

  const [itemList, onChange, clearItemList, removeItemFromList, addItemInListError] = useUploadFile();

  const { value, onClose, onExited, modalSequence, currentModal, nextModal, previousModal, setModal, formElementsList } = modalStore;

  const {
    variant = 'info',
    type = 'InfoDialog',
    title = 'Подтвердите действие',
    text = '',
    values = undefined,
    yarnValues = undefined,
    _internalValues = undefined,
    isForm = false,
    isHightOnContent = true,
    isAddedFile = false,
    actionButtons = [],
    cancelButton = {},
    ...dialogProps
  } = modalSequence.value.length ? modalSequence.value[currentModal.value] : {};

  // Modals[type] нельзя обернуть в observer, иначе попадаем в бесконечный цикл
  const Content = type !== 'InfoDialog' ? Modals[type] : null;

  const Tag = isForm ? 'form' : Fragment;
  const tagProps =
    isForm && actionButtons?.length
      ? {
          onSubmit: (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            clearItemList();
            actionButtons[0].action(formElementsList.formValues, currentModal.value, {
              close: onClose,
              nextModal,
              previousModal,
              setModal,
              pinnedFiles: itemList,
            });
          },
        }
      : {};

  const hToC = isHightOnContent ? undefined : hightOnContent;

  // стилизация кнопки отмены
  const { color: cbColor = theme.palette.error.main, text: cbText = 'Отмена', hidden: cbHidden = false } = cancelButton;

  return (
    <Dialog
      open={value}
      onClose={onClose}
      TransitionProps={{
        onExited,
      }}
      classes={{ paper: hToC }}
      PaperProps={{
        style: { backgroundColor: theme.palette[variant].main, color: theme.palette.getContrastText(theme.palette[variant].main) },
      }}
      {...dialogProps}
    >
      {/* Tag может быть фрагментом, который не принимает пропс className */}
      <Tag {...tagProps} {...(isHightOnContent ? {} : { className: hightOnContent })}>
        <DialogTitle>{title}</DialogTitle>
        <Paper style={{ margin: '1px' }}>
          <DialogContent className={hToC}>
            {Content ? <Content modalStore={modalStore} values={values} yarnValues={yarnValues} /> : text}
            {isAddedFile && <UploadFileList {...{ itemList, removeItemFromList, addItemInListError }} />}
          </DialogContent>
          <Divider />
          <DialogActions style={{ justifyContent: 'center' }}>
            {isAddedFile && (
              <>
                <AddFileButton {...{ onChange }} />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            {!!actionButtons?.length &&
              actionButtons?.map((button: any, i: number, { length }) => {
                const { text, action, disabled } = button;

                return (
                  <Fragment key={text + i}>
                    <Button
                      variant="text"
                      type={isForm && !i ? 'submit' : undefined}
                      disabled={disabled ? disabled(isForm ? formElementsList.formValues : _internalValues) : false}
                      key={i}
                      onClick={
                        !isForm || i
                          ? () => {
                              action(isForm ? formElementsList.formValues : _internalValues, currentModal.value, {
                                close: onClose,
                                nextModal,
                                previousModal,
                                setModal,
                                pinnedFiles: itemList,
                              });
                            }
                          : undefined
                      }
                    >
                      {text}
                    </Button>
                    {i < length - 1 && <Divider orientation="vertical" flexItem />}
                  </Fragment>
                );
              })}
            {!cbHidden && (
              <>
                {!!actionButtons?.length && <Divider orientation="vertical" flexItem />}
                <Button style={{ color: cbColor }} variant="text" onClick={onClose}>
                  {cbText}
                </Button>
              </>
            )}
          </DialogActions>
        </Paper>
      </Tag>
    </Dialog>
  );
};

export default StoreInjector(ModalWindow, ['modalStore']);
