import React from 'react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const MarkAsUnpackedDialog: FC<IContentProps<string[]>> = ({ values }) => {
  const ending = values.length ? 'ы' : '';

  return (
    <>
      {values.length
        ? `Вы уверены, что хотите разобрать заказ${ending}? Вы не сможете выдать покупателю заказ${ending} в статусе
      "Разобран аптекой".`
        : 'Вы не выбрали ни одного заказа.'}
    </>
  );
};

export default MarkAsUnpackedDialog;
