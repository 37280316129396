import React from 'react';

// Components
import { Redirect } from 'react-router-dom';
import LoadingBlock from '../../common/components/LoadingBlock';

// Icons & Images
import main_img from '../../static/images/main.png';

// Hooks
// import { useHistory } from 'react-router';

// Utils
import StoreInjector from '../../stores/StoreInjector';

// Types
import { FC } from 'react';
import { IMainRootProps } from './types';

const MainRoot: FC<IMainRootProps> = ({ authStore }) => {
  // const history = useHistory();
  // const onClick = (route: string) => () => history.push(route);
  const { role, isRole, getAccess, isNotAccessGranted } = authStore;
  // Нужно возвращать "круговой прогресс" на случай, если пользователь является фармацевт,
  // и пытается пройти по прямой ссылке на "main", чтобы ему на мгновение не отображалась
  // содержимое "main" из-за того, что не пришла его роль с бэка при обновлении страницы.
  if (!role.value) return <LoadingBlock />;
  if (!getAccess('mainPg')) {
    if (isRole('ROLE_PHARMACIST')) return <Redirect to="/main/order_list/1" />;
    else if (isRole('ROLE_NETWORK_MANAGER')) return <Redirect to="/main/apteka_list/1" />;
  }
  if (isNotAccessGranted.value)
    return (
      <Redirect
        to={{
          pathname: '/main/apteka_list/1',
        }}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {/* <div
        style={{
          display: 'grid',
          // flexDirection: 'row',
          // justifyContent: 'space-around',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: 96,
          padding: 32,
          margin: '0 auto',
          minHeight: 68,
        }}
      >
        <Button onClick={onClick('/main/apteka_list/1')}>Список аптек</Button>
        <Button onClick={onClick('/main/statistics')}>Взаиморасчеты</Button>
        <Button onClick={onClick('/main/order_list/1')}>Список заказов</Button>
        <ToTrainingQuickLink placement="right-start" />
      </div> */}
      <div
        style={{
          display: 'grid',
          // height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 2,
          padding: '16px',
        }}
      >
        <img alt="main" style={{ height: 400 }} src={main_img} />
      </div>
    </div>
  );
};

export default StoreInjector(MainRoot, ['authStore']);
