import React from 'react';

// Components
import FilterContainer from './StatisticsFilterContainer';

// Types
import { FC } from 'react';
import { IStatisticsFilterQueryDataHandlerProps } from './types';

const StatisticsFilterQueryDataHandler: FC<IStatisticsFilterQueryDataHandlerProps> = ({ data, aptekaListData }) => {
  const { apteka_list = [], total: apteka_list_total = 0 } = aptekaListData || {};

  return <FilterContainer {...{ apteka_list, apteka_list_total }} {...data} />;
};

export default StatisticsFilterQueryDataHandler;
