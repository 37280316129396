import StoreInjector from '../../stores/StoreInjector';
import StatisticsTableQueryDataHandler from './StatisticsTableQueryDataHandler';

const mapInjectedStore = ['statisticsStore'];

const StatisticsTableQueryDataHandlerContainer = StoreInjector(
  StatisticsTableQueryDataHandler,
  mapInjectedStore
);

export default StatisticsTableQueryDataHandlerContainer;
