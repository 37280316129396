import DBInjector from './fakeDB/DBInjector';
import StoreInjector from '../../stores/StoreInjector';
import StatisticsFilterQueryAdditional from './StatisticsFilterQueryAdditional-Fake';

const mapInjectedStore = ['statisticsStore'];

const StatisticsFilterQueryAdditionalContainer = DBInjector(
  StoreInjector(StatisticsFilterQueryAdditional, mapInjectedStore)
);

export default StatisticsFilterQueryAdditionalContainer;
