import React from 'react';

// Components
import { Redirect } from 'react-router';
import OrderListQuery from './OrderListQuery';

// Types
import { FC } from 'react';
import { IOrderListProps } from './types';

const OrderList: FC<IOrderListProps> = ({ authStore }) => {
  // const history = useHistory();
  // const { page, filters } = useParams();

  // useEffect(() => {
  //   orderListTableStore.setHistory(history, '/main/order_list/');

  //   orderListTableStore.setFiltersValueFromURI(page, filters);
  //   // Сброс значений при уходе со страницы.
  //   return orderListTableStore.resetFilters;
  // }, [orderListTableStore, history, page, filters]);

  const { isNotAccessGranted } = authStore;
  if (isNotAccessGranted.value) return <Redirect to="/main/apteka_list/1" />;

  return <OrderListQuery />;
};

export default OrderList;
