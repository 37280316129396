import { observable, action } from 'mobx';

import ValueStore from './components/ValueStore';

import { main_red, main_green, grey_300, grey_400 } from '../../constants/colors';

export interface OrderStatus {
  id: number | string;
  name: string;
}

export enum ResultStatusName {
  accepted = 'Не обработан аптекой',
  cancelledByUser = 'Отменён пользователем',
  cancelledByApteka = 'Отменён аптекой',
  readyToIssue = 'Готов к выдаче',
  issued = 'Выдан',
  dontKnow = 'Неизвестно',
  preset = 'Заказы в работе',
  unpacked = 'Разобран аптекой',
  all = 'Все',
}

export type statusInnerName = keyof typeof ResultStatusName;

export interface ResultStatus {
  name: string;
  numeric: number;
  innerName: statusInnerName;
  color: string;
}

const setStatusColor = (status: ResultStatusName) => {
  switch (status) {
    case ResultStatusName.accepted:
      return main_red;
    case ResultStatusName.readyToIssue:
      return main_green;
    case ResultStatusName.issued:
      return grey_300;
    case ResultStatusName.cancelledByUser:
      return grey_400;
    case ResultStatusName.cancelledByApteka:
      return grey_400;
    case ResultStatusName.unpacked:
      return grey_400;
    case ResultStatusName.dontKnow:
      return 'grey';
    default:
      return 'yellow';
  }
};

type TAccessElenebts =
  | 'mainPg'
  | 'statisticsPg'
  | 'aptekaListPg'
  | 'aptekaDetailsPg'
  | 'orderListPg'
  | 'trainingPg'
  | 'feedbackPg'
  | 'logoutBtn';

export type TRole = 'ROLE_NETWORK_MANAGER' | 'ROLE_PHARMACIST';

class AuthStore {
  @observable user?: any;

  @action
  setUser = (user: any) => {
    this.user = user;
  };

  @observable token?: string;

  @action
  setToken = (token: string) => {
    this.token = token;
  };

  login: ValueStore<string> = new ValueStore<string>('');

  _updateRoleProxy = (target: ValueStore<TRole | null>) => {
    const context = this;
    target.updateValue = new Proxy(target.updateValue, {
      apply(target, thisArg, args) {
        const [value] = args;

        target.apply(thisArg, args);

        localStorage.setItem('ROLE', value);
      },
    });
    return target;
  };

  role: ValueStore<TRole | null> = this._updateRoleProxy(new ValueStore<TRole | null>(null));

  access: {
    [x: string]: {
      ROLE_NETWORK_MANAGER: boolean;
      ROLE_PHARMACIST: boolean;
      [x: string]: boolean;
    };
  } = {
    mainPg: {
      ROLE_NETWORK_MANAGER: false,
      ROLE_PHARMACIST: false,
    },
    statisticsPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: false,
    },
    aptekaListPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: false,
    },
    aptekaDetailsPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: false,
    },
    orderListPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: true,
    },
    trainingPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: true,
    },
    feedbackPg: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: true,
    },
    logoutBtn: {
      ROLE_NETWORK_MANAGER: true,
      ROLE_PHARMACIST: true,
    },
  };

  getAccess = (element: TAccessElenebts) => this.role.value && this.access[element][this.role.value];

  isRole = (role: TRole) => this.role.value === role || localStorage.getItem('ROLE') === role;

  isNotAccessGranted: ValueStore<boolean> = new ValueStore<boolean>(false);
  isAptekaListInWork: ValueStore<boolean> = new ValueStore<boolean>(false);
  isFirstAuth: ValueStore<boolean> = new ValueStore<boolean>(true);

  // Работа со статусами заказов
  @observable orderStatusesList?: OrderStatus[] | null;
  @observable getStatus = (id: number): ResultStatus => {
    let resultName: ResultStatusName;
    let innerName: statusInnerName;
    switch (id) {
      case 6:
        resultName = ResultStatusName.issued;
        innerName = 'issued';
        break;
      case 5:
        resultName = ResultStatusName.readyToIssue;
        innerName = 'readyToIssue';
        break;
      case 1:
      case 0:
      case 4:
        resultName = ResultStatusName.accepted;
        innerName = 'accepted';
        break;
      case 3:
        resultName = ResultStatusName.cancelledByApteka;
        innerName = 'cancelledByApteka';
        break;
      case 8:
        resultName = ResultStatusName.unpacked;
        innerName = 'unpacked';
        break;
      case 2:
      case 7:
        resultName = ResultStatusName.cancelledByUser;
        innerName = 'cancelledByUser';
        break;
      default:
        resultName = ResultStatusName.dontKnow;
        innerName = 'dontKnow';
    }

    return {
      numeric: +id,
      name: resultName,
      innerName,
      color: setStatusColor(resultName),
    };
  };

  @action setOrderStatusList = (statuses: OrderStatus[]) => {
    this.orderStatusesList = statuses;
  };

  @action getOrderStatusString = (status: number) => {
    let result: OrderStatus | undefined = undefined;
    if (this.orderStatusesList) {
      result = this.orderStatusesList.find(({ id }) => id === status);
    }
    if (result && result.name) {
      return result.name;
    }
    console.warn(`>>> getOrderStatusString got an error with status ${status}`);
    return status;
  };

  @observable refuseStatusesList?: OrderStatus[] | null;

  @action setRefuseStatusList = (statuses: any) => {
    this.refuseStatusesList = statuses;
  };

  @action getRefuseStatusString = (status: number) => {
    let result: OrderStatus | undefined = undefined;
    if (this.refuseStatusesList) {
      result = this.refuseStatusesList.find(({ id }) => id === status);
    }
    if (result && result.name) {
      return result.name;
    }
    console.warn(`>>> getRefuseStatusString got an error with status ${status}`);
    return status;
  };
}

export default AuthStore;
