import { makeObservable } from 'mobx';

import ValueStore from '../components/ValueStore';

class DateFieldStore extends ValueStore<Date | null> {
  constructor(preset?: Date | null) {
    super(preset || null);

    makeObservable(this, {});
  }

  clear = () => this.updateValue(null);

  onChangeHandler = (date: Date | null) => {
    this.updateValue(date);
  };

  isError: ValueStore<boolean | string> = new ValueStore<boolean | string>(false);
}

export default DateFieldStore;
