import React from 'react';

// Components
import AptekaDetails from './AptekaDetailsUIContainer';

// Hooks
import { useParams } from 'react-router';

// Types
import { FC } from 'react';
import { TAptekaDetailsQueryDataHandlerProps } from './types';

const AptekaDetailsQueryDataHandler: FC<TAptekaDetailsQueryDataHandlerProps> = ({ data, loading, refetch }) => {
  const { id } = useParams();
  const { pharmaciesDetailsW, pharmacyScheduleIntervals } = data || {};
  const {
    name: pharmacy_name,
    location,
    management,
    contact,
    pharmacists = [],
    pharmacy_additional_contacts = [],
    legal_entity,
    ...apteka
  } = pharmaciesDetailsW || {};
  const { address = '', city = { name: '' } } = location || {};
  const { is_consists_in_loyalty_program = false, is_checked_by_manager = false, is_belongs_to_network = false } = management || {};

  const resultData = {
    ...apteka,
    pharmacy_name,
    address,
    city,
    contact,
    pharmacist: pharmacists[0],
    pharmacy_additional_contact: pharmacy_additional_contacts[0],
    is_consists_in_loyalty_program: Number(is_consists_in_loyalty_program),
    is_checked_by_manager: Number(is_checked_by_manager),
    is_belongs_to_network: Number(is_belongs_to_network),
    inn: legal_entity?.number,
    pharmacyScheduleIntervals,
  };

  const queryData = {
    loading,
    refetch,
  };

  return <AptekaDetails {...resultData} {...queryData} isNewId={id === 'addition'} />;
};

export default AptekaDetailsQueryDataHandler;
