import React from 'react';

// Components
import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { TRadioSelectProps, IValueParam } from './types';
import RadioSelectStore from '../../../stores/classes/triggers/RadioSelectStore';

const RadioSelect: FC<TRadioSelectProps> = ({
  formStore,
  name,
  title,
  values,
  valuesMap = {
    value: 'value',
    label: 'label',
  },
  preset,
  selfRemoved = false,
  onClick,
  clName: { formControl } = {},
}) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const { value, onChangeHandler, isError } = formStore.set(name, 'RadioSelectStore', {
    ...preset,
    isNum: typeof values[0].value === 'number',
    isBool: typeof values[0].value === 'boolean',
  }) as RadioSelectStore;

  return (
    <>
      <FormControl error={!!isError.value}>
        <FormLabel>{title}</FormLabel>
        <RadioGroup value={value} onChange={onChangeHandler} onClick={onClick}>
          {values.map((el: IValueParam, i) => {
            const { [valuesMap.value]: value, [valuesMap.label]: label } = el;
            return <FormControlLabel key={i} value={value} label={label} control={<Radio color="primary" />} />;
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default observer(RadioSelect);
