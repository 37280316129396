import React from 'react';

// Components
import OrderDetailsQuery from './OrderDetailsQuery';

// Hooks
import { useEffect } from 'react';
import { useParams } from 'react-router';

// Types
import { FC } from 'react';
import { TOrderDetailsProps } from './types';

const OrderDetails: FC<TOrderDetailsProps> = ({ orderDetailsStore }) => {
  useEffect(() => orderDetailsStore.clearFileds);

  const { id } = useParams();

  return <OrderDetailsQuery id={id} />;
};

export default OrderDetails;
