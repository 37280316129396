import React from 'react';

// Components
import { Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Field from '../../FormElements/Field';
import CheckboxToggle from '../../FormElements/ToggleCheckbox';

// Hooks
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

// Utils
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';

// Types
import { FC } from 'react';
import { Theme } from '@mui/material';
import { IContentProps } from '../types';
import { ProductQuantityType, IProductQuantityEdit } from '../../../../pages/OrderDetails/types';
import { FormElementsListStore } from '../../../../stores/classes/mapped';

const useStyles = makeStyles((theme: Theme) => ({
  headerRow: {
    backgroundColor: theme.palette.grey[200],
  },
  inputField: {
    textAlign: 'center',
  },
}));

const SelectEpsondProductsDialog: FC<IContentProps<ProductQuantityType[]>> = ({ modalStore, values }) => {
  const { headerRow, inputField } = useStyles();
  const theme = useTheme();
  const [productQuantity, setProductQuantity] = useState<IProductQuantityEdit[]>(
    values.map(({ product, quantity }) => ({
      product,
      quantity,
      actualQuantity: quantity,
    }))
  );

  const { formElementsList } = modalStore;
  const checkboxes = formElementsList.set('checkboxes', FormElementsListStore) as FormElementsListStore;
  const inputs = formElementsList.set('inputs', FormElementsListStore) as FormElementsListStore;

  useEffect(() => {
    for (const key in Object.fromEntries(checkboxes.value)) {
      const value = checkboxes.get(key)?.value;
      // @ts-ignore
      if (value) inputs.get(key)?.updateValue(0);
    }
  });

  return (
    <>
      <Typography
        style={{
          marginBottom: 20,
          // @ts-ignore
          color: formElementsList.get('inputs')?.withAllErrors ? 'red' : 'grey',
        }}
      >
        Укажите актуальные остатки в аптеке. Покупатель может скорректировать свой заказ с учетом наличия товара!
      </Typography>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={headerRow}>
            <TableCell>Название товара</TableCell>
            <TableCell style={{ width: 142, textAlign: 'center' }}>Количество в заказе</TableCell>
            <TableCell style={{ width: 100, textAlign: 'center' }}>Отсутствуют</TableCell>
            <TableCell style={{ width: 100, textAlign: 'center' }}>В наличии</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productQuantity.map(({ product, quantity, actualQuantity }: IProductQuantityEdit, index) => (
            <TableRow key={product.id} style={index % 2 ? { backgroundColor: theme.palette.grey[100] } : {}}>
              <TableCell>{product.name}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{quantity}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <CheckboxToggle formStore={checkboxes} name={String(product.id)} />
              </TableCell>
              <TableCell>
                <Field
                  formStore={inputs}
                  name={String(product.id)}
                  type="number"
                  preset={{ def: actualQuantity, min: 0 }}
                  disabled={(checkboxes.get(String(product.id))?.value as boolean) || false}
                  inputProps={{
                    className: inputField,
                  }}
                  size="small"
                  variant="standard"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default observer(SelectEpsondProductsDialog);
