import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ru as locale } from 'date-fns/locale';
import { type } from 'os';

const SafariSupportDate = (date: number | string) =>
  typeof date === 'number' ? date : date.replace(/-/gi, '/');

export const getTime = (date: number | string) => {
  const D = new Date(SafariSupportDate(date));
  const withHH = !!D.getUTCHours();
  return format(D, withHH ? 'HH:mm:ss' : 'mm:ss');
};

export const getDateString = (date: number | string, mask: string = 'dd.MM.yyyy') => {
  return format(new Date(SafariSupportDate(date)), mask, {
    locale,
  });
};

export const getDateStringWithTime = (date: number | string) => {
  return format(new Date(SafariSupportDate(date)), 'dd.MM.yyyy HH:mm', {
    locale,
  });
};

export const daysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getDateTime = (date: Date) => {
  return format(utcToZonedTime(date, ''), 'yyyy-MM-dd HH:mm:ss');
};
