import { findDOMNode } from 'react-dom';
import { makeObservable } from 'mobx';

import ValueStore from './ValueStore';
import ToggleStore from './ToggleStore';
import SliderStore from '../triggers/SliderStore';
import ReactPlayer from 'react-player';

import screenfull from 'screenfull';

interface IProgressState {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

class PlayerStore {
  private _reactPlayerRef: ReactPlayer | null = null;
  private _playerRef: Element | null = null;
  volume: SliderStore = new SliderStore(0.5);
  private _volumeCash: SliderStore = new SliderStore(0.5);

  constructor() {
    makeObservable(this, {});
  }

  volumeMuteHandler = () => {
    if (this.volume.value) {
      this._volumeCash.updateValue(this.volume.value);
      this.volume.updateValue(0);
    } else {
      this.volume.updateValue(this._volumeCash.value);
    }
  };

  playing: ToggleStore = new ToggleStore();

  playPauseHandler = () => {
    if (this.duration.value === this.playedSeconds.value) {
      this.playedSeconds.updateValue(0);
      this.played.updateValue(0);
    }
    if (this._reactPlayerRef?.state.showPreview) this._reactPlayerRef?.setState({ showPreview: false });
    this.playing.onToggle();
  };

  private _playedProxy = (target: SliderStore) => {
    const context = this;

    target.onChangeHandler = new Proxy(target.onChangeHandler, {
      apply: (target, thisArg, args) => {
        let [e, value] = args;
        target.apply(thisArg, args);
        context._reactPlayerRef?.seekTo(value);
      },
    });

    return target;
  };

  played: SliderStore = this._playedProxy(new SliderStore(0));
  playedSeconds: ValueStore<number> = new ValueStore(0);
  duration: ValueStore<number> = new ValueStore(0);

  setReactPlayerRef = (player: ReactPlayer) => {
    this._reactPlayerRef = player;
  };

  setPlayerRef = (player: Element | null) => {
    this._playerRef = player;
  };

  onEnded = () => this.playing.updateValue(false);

  onDuration = (duration: number) => {
    this.duration.updateValue(duration);
  };

  onProgress = (state: IProgressState) => {
    const { played, playedSeconds } = state;
    this.played.updateValue(played);
    this.playedSeconds.updateValue(playedSeconds);
  };

  fullscreenHandler = () => {
    if (screenfull.isEnabled) {
      if (!screenfull.isFullscreen) screenfull.request(findDOMNode(this._playerRef) as Element);
      else screenfull.exit();
    }
  };
}

export default PlayerStore;
