import React from 'react';

// Components
import TableController from '../../common/components/tables/TableController';

// Utils
import { observer } from 'mobx-react';
import { differenceInCalendarDays } from 'date-fns';
import { stringVerification } from '../../utils/stringParse';
import { sortBy } from '../../utils/other';

// Types
import { FC } from 'react';
import { IAptekaListDataFiltersProps } from './types';
import { IApteka } from './types';

const AptekaListDataFilters: FC<IAptekaListDataFiltersProps> = ({ tableStore, rows, tablePreset }) => {
  const { filtersList, sortList } = tableStore;

  const getIsLoyaltyString = (status: number) => {
    if (status) {
      return 'is_consists_in_loyalty_program';
    }
    return 'not_loaylty';
  };

  const getCheckedByManagerValue = (status: number) => {
    if (status) {
      return 'accepted';
    }
    return 'unaccepted';
  };

  let resultArray: any[] = [];
  console.log('rows', rows);
  if (rows.length) {
    let aptekaList: IApteka[] = [...rows];

    const { id, pharmacy_name, address, is_checked_by_manager, is_consists_in_loyalty_program, is_belongs_to_network, inn, updated_at } =
      filtersList.formValues;
    // применяем фильтры

    // по id
    if (id) {
      const filterRegexp = new RegExp(id as string);
      aptekaList = aptekaList.filter(({ id }) => id && filterRegexp.test(id.toString()));
    }

    // по названию
    if (pharmacy_name) {
      const search = String(pharmacy_name).replace(/\\n/g, ' ').toLowerCase();
      aptekaList = aptekaList.filter(({ pharmacy_name }) => {
        const str = JSON.stringify(pharmacy_name).replace(/\\n/g, ' ').toLowerCase();
        return stringVerification(search, str);
      });
    }

    // по адресу
    if (address) {
      const search = String(address).replace(/\\n/g, ' ').toLowerCase();
      aptekaList = aptekaList.filter(({ address }) => {
        const str = String(JSON.stringify(address)).replace(/\\n/g, ' ').toLowerCase();
        return stringVerification(search, str);
      });
    }

    // по полю "подтверждена менеджером"
    if (is_checked_by_manager !== 'all') {
      aptekaList = aptekaList.filter(
        ({ is_checked_by_manager: compared = 0 }) => getCheckedByManagerValue(compared) === is_checked_by_manager
      );
    }

    // по полю "участвует в ПЛ"
    if (is_consists_in_loyalty_program !== 'all') {
      aptekaList = aptekaList.filter(
        ({ is_consists_in_loyalty_program: compared = 0 }) => getIsLoyaltyString(compared) === is_consists_in_loyalty_program
      );
    }

    // по полю "принадлежность к АС"
    if (is_belongs_to_network !== 'all') {
      aptekaList = aptekaList.filter(
        ({ is_belongs_to_network: compared = 0 }) => getCheckedByManagerValue(Number(compared)) === is_belongs_to_network
      );
    }

    // по ИНН
    if (inn) {
      const filterRegexp = new RegExp(inn as string);
      aptekaList = aptekaList.filter(({ inn }) => inn && filterRegexp.test(inn.toString()));
    }

    // фильтр по дате
    // if (updated_at) {
    //   aptekaList = aptekaList.filter(
    //     ({ updated_at: compared }: { updated_at: any }) =>
    //       !differenceInCalendarDays(
    //         typeof updated_at === 'string' ? new Date(updated_at!) : (updated_at! as Date),
    //         new Date(compared!)
    //       )
    //   );
    // }
    // @ts-ignore
    if (updated_at?.[0] && updated_at?.[1]) {
      // @ts-ignore
      const [startDate, endDate] = updated_at;
      aptekaList = aptekaList.filter(
        ({ updated_at: compared }: { updated_at: any }) =>
          differenceInCalendarDays(new Date(compared!), new Date(startDate!)) >= 0 &&
          differenceInCalendarDays(new Date(compared!), new Date(endDate!)) <= 0
      );
    }

    const sortUpdateAtFn =
      (reverse: boolean = false) =>
      ({ updated_at: a }: IApteka, { updated_at: b }: IApteka) =>
        (new Date(a).getTime() - new Date(b).getTime()) * (reverse ? -1 : 1);

    aptekaList = sortBy(sortList.values['updated_at'], aptekaList, sortUpdateAtFn);

    const sortIdFn =
      (reverse: boolean = false) =>
      ({ id: a }: IApteka, { id: b }: IApteka) =>
        (a - b) * (reverse ? -1 : 1);

    aptekaList = sortBy(sortList.values['id'], aptekaList, sortIdFn);

    resultArray = aptekaList;
  }

  return <TableController tableStore={tableStore} rows={[...resultArray]} {...tablePreset} />;
};

export default observer(AptekaListDataFilters);
