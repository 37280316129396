import React, { ComponentType, useEffect } from 'react';

import { Box, Checkbox, CheckboxProps } from '@mui/material';

import { observer } from 'mobx-react';

import { FC } from 'react';

import { ToggleStore } from '../../stores/classes/triggers';
import WithFormElementsStore from '../../common/components/FormElements/WithFormElementsStore';
import { FormElementsListStore } from '../../stores/classes/mapped';

type TChBox = { store: ToggleStore } & CheckboxProps;

// START ChBox Component
const ChBox: FC<TChBox> = ({ store }) => {
  return <Checkbox checked={store.value} onChange={(e) => store.updateValue(e.target.checked)}></Checkbox>;
};

const ChBoxO = observer(ChBox);
// END ChBox Component

const ChBoxS = WithFormElementsStore<TChBox, boolean>(ChBoxO, 'SliderStore');

const ZeroTest: FC = () => {
  const zeroTestForm = new FormElementsListStore();

  return (
    <Box>
      <ChBoxS formStore={zeroTestForm} name="test" preset={true} />
      {/* <Table>
        <TableHead>
          <TableCell>
            <TableSort formStore={sortStore} name="test" content="test" />
          </TableCell>
        </TableHead>
      </Table> */}
    </Box>
  );
};

export default observer(ZeroTest);
