import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useHistory } from 'react-router';

// Icons & Images
import logo_1 from '../../static/images/logo_1.png';

// Utils
import { getImageHeightByWidth } from '../../utils/imageUtils';

// Types
import { FC } from 'react';
import { TLandingUIProps } from './types';

const LandingUI: FC<TLandingUIProps> = ({ authStore }) => {
  const history = useHistory();

  let resultWidth = (window.screen.width * 2) / 3;

  useEffect(() => {
    const { role } = authStore;
    if (!role) history.push('/login');
    history.push('/main');
  }, [authStore]);

  return <img alt="logo" src={logo_1} style={{ width: resultWidth, height: getImageHeightByWidth(logo_1, resultWidth) }} />;
};

export default LandingUI;
