import React from 'react';
import { enqueueSnackbar } from 'notistack';
// Components
import { CloseSnackbarButton } from '..';
import AlertCard from '../layouts/AlertCard/AlertCard';
// Types
import { IAlert } from '../types';

const usePush = () => {
  const enqueuePush: IAlert = (title, message, options) =>
    enqueueSnackbar(message, {
      layout: AlertCard,
      title,
      action: (id) => <CloseSnackbarButton id={id} />,
      ...options,
      variant: 'customSnackbar',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      hideIconVariant: true,
    });

  const success: IAlert = (title, message, options) =>
    enqueuePush(title, message, {
      ...options,
      type: 'success',
      style: {
        backgroundColor: '#45a047',
      },
    });

  const error: IAlert = (title, message, options) =>
    enqueuePush(title, message, {
      ...options,
      type: 'error',
    });

  const warning: IAlert = (title, message, options) =>
    enqueuePush(title, message, {
      ...options,
      type: 'warning',
    });

  const info: IAlert = (title, message, options) =>
    enqueuePush(title, message, {
      ...options,
      type: 'info',
    });

  return {
    success,
    error,
    warning,
    info,
  };
};

export default usePush;
