import React from 'react';

// Components
import { TextField, MenuItem, Divider, Checkbox } from '@mui/material';

// Hooks
import { useEffect } from 'react';

// Utils
import { observer } from 'mobx-react';

// Types
import { FC, ReactText } from 'react';
import { TSelectFieldProps, IValueParam } from './types';
import SelectStore from '../../../stores/classes/triggers/SelectStore';

const SelectField: FC<TSelectFieldProps> = ({
  formStore,
  name,
  values,
  valuesMap = {
    value: 'value',
    label: 'label',
  },
  multiple = false,
  preset,
  defaultSelector,
  selfRemoved = false,
  clName: { formControl } = {},
  ...props
}) => {
  // Если самоудаляющийся, то вернуть функцию удаления, срабатывающую при демонтаже элемента из DOM.
  useEffect(() => {
    if (selfRemoved)
      return () => {
        formStore.delete(name);
      };
  }, [selfRemoved, formStore, name]);

  const { value, onChangeHandler, isError } = formStore.set(name, 'SelectStore', {
    ...preset,
    ...(multiple ? { def: preset?.def || [] } : {}),
  }) as SelectStore;

  useEffect(() => {
    // Очистка ошибки, если значение присутсвует
    if (value && isError.value) isError.updateValue(false);
  }, [value]);

  return (
    <TextField
      id={name}
      className={formControl}
      value={value}
      onChange={onChangeHandler}
      error={!!isError.value}
      helperText={typeof isError.value === 'string' ? isError.value : ''}
      select
      SelectProps={{
        multiple,
        ...(multiple && {
          renderValue: (selected) => {
            return (selected as ReactText[])
              .map((select) => {
                const param = values.find(({ [valuesMap.value]: value }) => value === select);
                if (param) return param[valuesMap.label];
              })
              .join(', ');
          },
        }),
      }}
      fullWidth
      {...props}
    >
      {multiple && (
        <div>
          <Checkbox
            onChange={() =>
              onChangeHandler({
                target: {
                  // @ts-ignore
                  value: value.length === values.length ? [] : values.map(({ value }) => value),
                },
              })
            }
            // @ts-ignore
            checked={value.length === values.length}
            // @ts-ignore
            indeterminate={value.length && values.length > value.length}
          />
          Выбрать Все
          <Divider />
        </div>
      )}
      {defaultSelector && <MenuItem value={typeof values[0][valuesMap.value] === 'number' ? -1 : 'undefined'}>{defaultSelector}</MenuItem>}
      {values.map((el: IValueParam, i) => {
        const { [valuesMap.value]: val, [valuesMap.label]: label } = el;
        return (
          <MenuItem key={i} value={val}>
            {multiple && <Checkbox checked={(value as ReactText[]).includes(val as ReactText)} />}
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default observer(SelectField);
