import React from 'react';

// Components
import { Paper, Typography, Grid } from '@mui/material';

// Hooks
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

// Utils
import { getDateStringWithTime } from '../../../utils/dateTimeUtils';
import { ext } from '../../../utils/uriUtils';

// Constants
import { uriCore } from '../../../client/Client';

// Types
import { FC } from 'react';
import { IFeedbackResponseProps } from '../types';

const FeedbackResponse: FC<IFeedbackResponseProps> = ({ type_id, text, created_at, files }) => {
  const theme = useTheme();

  const [prev, setPrev] = useState<string | null>(null);

  const isUser = [0, 2].includes(type_id);
  const color = isUser ? theme.palette.primary.main : theme.palette.secondary.main;
  const head = isUser ? 'Вы' : 'Служба поддержки';

  return (
    <Paper
      style={{
        textAlign: 'left',
        background: color,
        border: '1px solid',
        borderColor: color,
        margin: theme.spacing(2, isUser ? 2 : 10, 2, isUser ? 10 : 2),
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: theme.spacing(1), color: theme.palette.common.white }}
      >
        <Typography variant="button" style={{ fontWeight: 'bold' }}>
          {head}
        </Typography>
        <Typography variant="button" style={{ fontWeight: 'bold' }}>
          {getDateStringWithTime(created_at)}
        </Typography>
      </Grid>
      <Paper
        style={{
          textAlign: 'left',
          padding: theme.spacing(1),
        }}
      >
        <Typography component="div">{text}</Typography>
        <Grid container direction="column" style={{ padding: 8 }}>
          <Grid container spacing={1}>
            {files?.map(({ filename }: any) => {
              const src = uriCore + filename;
              return (
                <Grid key={filename} item>
                  <button
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 10,
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 0,
                      borderWidth: 0,
                      outline: 'unset',
                      cursor: 'pointer',
                    }}
                    onClick={() => setPrev(prev === src ? null : src)}
                  >
                    {ext(filename) === 'mp4' ? (
                      <video style={{ height: '100%' }} {...{ src }} />
                    ) : (
                      <img style={{ width: '100%' }} {...{ src }} />
                    )}
                  </button>
                </Grid>
              );
            })}
          </Grid>

          {prev && (
            <Grid item style={{ position: 'relative', width: '100%', margin: '8px 0 4px' }}>
              {ext(prev) === 'mp4' ? (
                <video controls key={prev} style={{ width: '100%' }} {...{ src: prev }} />
              ) : (
                <img key={prev} style={{ width: '100%' }} {...{ src: prev }} />
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
    </Paper>
    // <Grid style={{ margin: theme.spacing(2, 0) }}>
    //   <Typography align={isUser ? 'left' : 'right'}>{isUser ? 'Вы' : 'Ответ'}</Typography>
    //   <Paper
    //     style={
    //       isUser
    //         ? { marginRight: theme.spacing(3), padding: theme.spacing(2) }
    //         : { marginLeft: theme.spacing(3), padding: theme.spacing(2) }
    //     }
    //   >
    //     <Typography align="left">{text}</Typography>
    //   </Paper>
    // </Grid>
  );
};

export default FeedbackResponse;
