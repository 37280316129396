import React from 'react';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const OrdersFoundButNotReady: FC<IContentProps> = ({ modalStore, yarnValues }) => {
  const { searchType, searchValue } = yarnValues;

  return (
    <>{`Нет готовых к выдаче заказов из найденых. Возможно Вы указали не правильные условия поиска, вы ищите ${searchValue} в номере ${
      searchType === 'phones' ? 'телефона' : 'заказа'
    }, попробуйте еще раз.`}</>
  );
};

export default OrdersFoundButNotReady;
