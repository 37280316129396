import { makeObservable } from 'mobx';

import ValueStore from '../components/ValueStore';

class SliderStore extends ValueStore<number | number[]> {
  constructor(preset: number | number[]) {
    super(preset);

    makeObservable(this, {});
  }

  onChangeHandler = (e: Event, value: number | number[]) => {
    this.updateValue(value);
  };
}

export default SliderStore;
