import React from 'react';

// Components
import Field from '../../FormElements/Field';

// Types
import { FC } from 'react';
import { IContentProps } from '../types';

const ChangePasswordDialog: FC<IContentProps> = ({ modalStore }) => {
  return (
    <>
      <Field
        formStore={modalStore.formElementsList}
        name="password"
        selfRemoved={true}
        type="password"
        variant="outlined"
        placeholder="Введите новый пароль"
      />
    </>
  );
};

export default ChangePasswordDialog;
