import React from 'react';

// Components
import { TableSortLabel } from '@mui/material';
import WithFormElementsStore from './WithFormElementsStore';

// Types
import { FC } from 'react';
import { TTableSortProps } from './types';
import SortStore from '../../../stores/classes/triggers/SortStore';

const TableSort: FC<TTableSortProps> = ({ store, content }) => {
  return (
    <TableSortLabel
      active={!!store.value}
      direction={store.value || 'asc'}
      onClick={() => {
        store.updateValue(store.value === 'asc' ? 'desc' : 'asc');
      }}
      sx={
        {
          // color: 'white',
          // '&:hover': {
          //   color: 'white',
          // },
          // '&$active': {
          //   color: 'white',
          // },
        }
      }
    >
      {content}
    </TableSortLabel>
  );
};

export default WithFormElementsStore(TableSort, SortStore);
