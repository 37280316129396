import { makeObservable } from 'mobx';
import FormElementsListStore from './mapped/FormElementsListStore';

class AptekaDetailsStore {
  aptekaCardForm: FormElementsListStore = new FormElementsListStore();
  juridicalCardForm: FormElementsListStore = new FormElementsListStore();
  accountSettingsCardForm: FormElementsListStore = new FormElementsListStore();
  contactsCardForm: FormElementsListStore = new FormElementsListStore();

  constructor() {
    makeObservable(this, {});
  }
}

export default AptekaDetailsStore;
